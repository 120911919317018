import { useSnackBar } from "contexts/snackBarContext";
import { endOfDay, startOfDay } from "date-fns";
import { Store } from "pullstate";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import Request from "../components/universalModal/pages/request";
import { useSessionContext } from "../contexts/sessionContext";
import { getCountries } from "../services/countryService";
import {
  getRequest,
  getServices,
  getServicesAccepted,
  getServicesCancelled,
  getServicesDraft,
} from "../services/requestService";
import { availableServices, caseTypesFilter, providersAvailable } from "../utils/globalConfig";
import notify from "../utils/notify";
import { showNotification } from "../utils/showNotification";
import { updateERosterService, updateERosterTimeBlock } from "./eRosterStore";
import { updateProviderCoordinates, updateProviderIsGeolocalized } from "./geoTrackingStore";
import { requestStore } from "./modalManager";
import { updatePatientCases } from "./patientCaseStore";
import { updateTask } from "./taskStore";

let timeOut;
const today = new Date();

const specialty = providersAvailable.map(provider => provider.value);

const defaultFilter = {
  caseType: ["all", ...caseTypesFilter],
  serviceType: ["all", ...availableServices],
  serviceSpecialty: ["all", ...specialty],
  startDate: today,
  endDate: today,
  pickerEnabled: false,
};

export const store = new Store({
  isLoadingCountry: true,
  country: { alpha2: localStorage.getItem("country") || "SG", services: [] },
  service: { showLoading: true, searchTerm: "", services: [], filter: defaultFilter },
  language: "en",
  websocket: {
    connect: true,
    isOnline: true,
    lastMessage: "",
    ready: false,
    heartbeat: onTimeOut => {
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        if (onTimeOut) onTimeOut();
      }, 30000);
    },
  },
});

export const setFilter = filter => {
  store.update(s => {
    const currFilter = { ...s.service.filter, ...filter };
    s.service.filter = currFilter;
    window.localStorage.setItem("dispatcher-f", JSON.stringify(currFilter));
  });
};

export const fetchServicesSequentially = async (options, abortController) => {
  let latestCursor = {};
  let limitPerQuery = 10;

  do {
    const businessRef = options.businessRef?.length > 0 ? options.businessRef.filter(id => id !== "all") : undefined;

    const query = {
      country: options.country,
      "serviceType[]": options.serviceType,
      "serviceSpecialty[]": options.serviceSpecialty,
      "caseType[]": options.caseType,
      "businessRef[]": businessRef,
      serviceStatus: options.serviceStatus,
      serviceConfirmed: !!options.serviceConfirmed,
      bookingType: options.bookingType,
      cursorSchedule: latestCursor.cursorSchedule,
      cursorCreatedAt: latestCursor.cursorCreatedAt,
      cursorId: latestCursor.cursorId,
      limitPerQuery,
    };

    if (options.pickerEnabled) {
      query.scheduleFrom = startOfDay(new Date(options.startDate));
      query.scheduleTo = endOfDay(new Date(options.endDate));
    }

    const mapServices = {
      accepted: getServicesAccepted,
      cancelled: getServicesCancelled,
      draft: getServicesDraft,
    };

    const fetchServices =
      Array.isArray(options.serviceStatus) || !mapServices[options.serviceStatus]
        ? getServices
        : mapServices[options.serviceStatus];

    let res = await fetchServices(query, abortController);
    let services = res.data?.services;
    latestCursor.count = res.data?.count;

    if (options.serviceStatus === "accepted" && !options.serviceConfirmed && !latestCursor.count) {
      options.serviceConfirmed = true;
      res = await getServicesAccepted(
        {
          ...query,
          serviceConfirmed: true,
          cursorSchedule: undefined,
          cursorCreatedAt: undefined,
          cursorId: undefined,
        },
        abortController
      );
      services = res.data?.services;
      latestCursor.count = res.data?.count;
    }

    latestCursor.cursorSchedule = res.data?.cursorSchedule;
    latestCursor.cursorCreatedAt = res.data?.cursorCreatedAt;
    latestCursor.cursorId = res.data?.cursorId;

    store.update(s => {
      const newServiceIds = services.map(service => service._id);
      const filteredServices = s.service.services.filter(service => !newServiceIds.includes(service._id));
      s.service.services = [...filteredServices, ...services];
    });

    limitPerQuery *= 2;
    if (limitPerQuery > 500) limitPerQuery = 500;
  } while (latestCursor.count);
};

const StoreManager = () => {
  const { session } = useSessionContext();
  const { i18n } = useTranslation();
  const { country, service, websocket } = store.useState(s => s);
  const currentRequest = requestStore.useState(s => s.request);
  const location = useLocation();
  const snackBar = useSnackBar();

  useEffect(() => {
    i18n.changeLanguage(country.alpha2.toLowerCase());
  }, [i18n, country]);

  const onWSOpen = useRef();
  const { sendMessage, readyState } = useWebSocket(
    `${process.env.REACT_APP_API_WS_ENDPOINT}?authorization=${session.jwt}`,
    {
      reconnectAttempts: 20,
      reconnectInterval: 5000,
      retryOnError: true,
      shouldReconnect: () => true,
      onOpen: () => {
        if (onWSOpen.current) onWSOpen.current();
      },
      onMessage: message => {
        if (message.data === "pong") websocket.heartbeat();
        else onWSMessage(message);
      },
    },
    websocket.connect
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { countries },
        } = await getCountries();
        const countryCode = localStorage.getItem("country");
        let country = countries.find(c => c.alpha2 === countryCode);
        if (!country) country = countries.find(c => c.alpha2 === "SG");
        localStorage.setItem("country", country.alpha2);
        store.update(c => {
          c.availableCountries = countries;
          c.isLoadingCountry = false;
          c.language = "en";
          c.country = country;
        });
      } catch (e) {
        notify(e.data, "error");
        console.log(e);
      }
    };
    fetchData();
  }, [i18n]);

  useEffect(() => {
    websocket.heartbeat(() =>
      store.update(s => {
        s.websocket.connect = false;
      })
    );
    const interval = setInterval(
      () => sendMessage(JSON.stringify({ action: "ping", body: "This is a ping request" })),
      20000
    );
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (readyState === ReadyState.CLOSED && websocket.isOnline) {
      store.update(s => {
        s.websocket.connect = true;
      });
    }
  }, [readyState, websocket.isOnline]);

  const onOnlineRef = useRef(() => {
    store.update(s => {
      s.websocket.isOnline = true;
      s.websocket.connect = true;
    });
  });
  const onOfflineRef = useRef(() =>
    store.update(s => {
      s.websocket.isOnline = false;
      s.websocket.connect = false;
      s.service.showLoading = true;
      s.service.services = [];
    })
  );

  useEffect(() => {
    const onOnline = onOnlineRef.current;
    const onOffline = onOfflineRef.current;
    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);
    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
    };
  }, []);

  useEffect(() => {
    store.update(s => {
      s.service.showLoading = true;
      s.service.services = [];
    });

    sendMessage(JSON.stringify({ action: "SetConnectionCountry", body: country.alpha2 }));
  }, [country.alpha2, sendMessage]);

  const updateService = useCallback(
    async ({ service: updatedService, operationType }, isDraft) => {
      if (!service.services) return;

      // Check if it matches the current filter
      const filter = service.filter;
      if (updatedService) {
        if (updatedService.country !== country.alpha2) return;
        if (!filter.serviceType?.includes(updatedService.type)) return;
        if (!filter.serviceSpecialty?.includes(updatedService.specialty)) return;
        if (!filter.caseType?.includes(updatedService.case.type)) return;
        if (filter.businessRef?.length > 0 && !filter.businessRef?.includes(updatedService.business?._id)) return;
        if (filter.pickerEnabled && updatedService.schedule.start) {
          const startDate = startOfDay(new Date(filter.startDate));
          const endDate = endOfDay(new Date(filter.endDate));
          const scheduleDate = new Date(updatedService.schedule.start);
          if (scheduleDate < startDate || scheduleDate > endDate) return;
        }
      }

      store.update(store => {
        let storeServices = [...store.service.services];
        let index = storeServices.findIndex(s => s._id === updatedService._id);

        // In case Jarvis somehow missed the insert event
        if (operationType === "update" && index === -1) {
          storeServices.push(updatedService);
        }

        const currentUpdatedAt = storeServices[index]?.updatedAt;
        const isNewUpdate = new Date(currentUpdatedAt) < new Date(updatedService.updatedAt);
        if (!!currentUpdatedAt && !isNewUpdate) return;

        if (operationType === "delete" && index > -1) {
          storeServices = storeServices.filter(s => s._id !== updatedService._id);
        } else if (operationType === "insert" && index === -1) {
          storeServices.push(updatedService);
          if (updatedService?.status === "accepted") {
            showNotification("New service request received", `${process.env.PUBLIC_URL}/bell.mp3`);
          }
        } else if (operationType === "update") {
          if (isDraft && storeServices[index]?.status === "draft" && updatedService?.status === "confirmed") {
            storeServices = storeServices.filter(s => s._id !== updatedService._id);
          } else if (storeServices[index]?.status !== "cancelled" && updatedService?.status === "cancelled") {
            if (isDraft) {
              // There is no column to display Cancelled Draft, so remove it.
              storeServices = storeServices.filter(s => s._id !== updatedService._id);
            } else {
              storeServices[index] = updatedService;
            }

            if (updatedService.cancelled?.origin === "patient") {
              showNotification("New service cancellation", `${process.env.PUBLIC_URL}/bomb.mp3`);
            }
          } else if (storeServices[index]?.status === "cancelled" && updatedService.cancelled.confirmed) {
            storeServices = storeServices.filter(s => s._id !== updatedService._id);
          } else {
            storeServices[index] = updatedService;
          }
        }

        store.service.services = storeServices;
      });

      if (
        operationType === "update" &&
        updatedService._id === currentRequest?._id &&
        new Date(updatedService.updatedAt) >= new Date(currentRequest.updatedAt) &&
        !updatedService.cancelled.confirmed
      ) {
        try {
          const { data } = await getRequest(updatedService._id);

          requestStore.update(s => {
            s.request = data.service;
            s.unsavedChanges = {
              billing: { ...s.request.billing },
              schedule: { ...s.request.schedule },
              providers: [...s.request.providers],
              isDirty: {},
            };
            s.componentsToRender = <Request />;
          });
        } catch (error) {
          snackBar.pop({ content: "Empty service data from websocket", alertProps: { severity: "error" } });
        }
      }
    },
    [country.alpha2, currentRequest?._id, currentRequest?.updatedAt, service.filter, service.services, snackBar]
  );

  const onPage = useCallback(path => path === location.pathname, [location.pathname]);

  const onWSMessage = async message => {
    if (!message?.data) return;

    store.update(s => {
      s.websocket.lastMessage = message.data;
    });

    const handler = {
      PatientRequestTrigger: async data => {
        await updateService(data.data, false);
        if (onPage("/e-roster")) updateERosterService(data.data, country.alpha2);
        if (onPage(`/patient/${data.data.service.owner?._id ?? data.data.service.user?._id}`)) {
          updatePatientCases(data.data, country.alpha2);
        }
      },
      TimeBlockTrigger: data => {
        if (onPage("/e-roster")) updateERosterTimeBlock(data.data, country.alpha2);
      },
      DraftTrigger: async data => {
        await updateService(data.data, true);
        if (onPage(`/patient/${data.data.service.owner?._id ?? data.data.service.user?._id}`)) {
          updatePatientCases(data.data, country.alpha2, "draft");
        }
      },
      TaskTrigger: data => {
        updateTask(data?.data);
      },
      ProviderCoordinatesUpdated: data => {
        updateProviderCoordinates(data?.data);
      },
      ProviderIsGeolocalizedUpdated: data => {
        updateProviderIsGeolocalized(data?.data);
      },
    };

    try {
      const data = JSON.parse(message.data);

      if (handler[data.route]) {
        await handler[data.route](data);
      } else {
        console.log("Ignoring route", data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    store.update(s => {
      s.websocket.readyState = readyState;
      s.websocket.ready = readyState === ReadyState.OPEN;
    });
  }, [readyState]);

  useEffect(() => {
    let events = ["services", "task", "draft"];

    if (onPage("/dispatcher/map")) events.push("coordinates");
    else if (onPage("/e-roster")) events.push("eRoster");

    sendMessage(JSON.stringify({ action: "SetConnectionEvents", body: events }));
  }, [onPage, sendMessage]);

  useEffect(() => {
    const savedFilter = window.localStorage.getItem("dispatcher-f");
    let currFilter = savedFilter ? JSON.parse(savedFilter) : defaultFilter;

    // This is for backward compatibility, since we launch multi-select filters in 2022-SPRINT-11
    // so filters will fallback to defaultFilter. safe to delete if all users has migrated :)
    if (!Array.isArray(currFilter?.serviceType) || !Array.isArray(currFilter?.serviceSpecialty)) {
      currFilter = defaultFilter;
    }

    store.update(s => {
      s.service.filter = { ...s.service.filter, ...currFilter };
      window.localStorage.setItem("dispatcher-f", JSON.stringify(s.service.filter));
    });
  }, []);

  return null;
};

export default StoreManager;
