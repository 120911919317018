import {
  addMinutes,
  endOfDay,
  formatDistanceToNow,
  isWithinInterval,
  parseJSON,
  startOfDay,
  subMinutes,
} from "date-fns";
import { Store } from "pullstate";
import { serviceScheduleOffset } from "utils/globalConfig";
import { deepCloneObject, isValidDate } from "utils/globalUtils";
import { createTimeBlock, deleteTimeBlocks, updateTimeBlock } from "../services/eRosterService";
import { getLogs } from "../services/logService";

export const eRosterStore = new Store({
  latestLog: {},
  timeBlocks: {},
  providers: {},
  services: {},
});

export const getKeyFromDate = date => {
  if (!isValidDate(date)) return "";

  return date.toDateString();
};

export const getLatestLog = async ({ alpha2 }) => {
  const result = await getLogs({ tags: ["eRoster"], perPage: 1, alpha2 }).catch(console.log);

  if (!result) return;

  const log = result.data?.logs?.[0];
  eRosterStore.update(s => {
    s.latestLog = {
      lastEdited: formatDistanceToNow(new Date(log?.createdAt), { addSuffix: true }),
      by: log?.by?.name,
    };
  });
};

export const createOrUpdateTimeBlock = async ({ timeBlocks, timeBlock }) => {
  if (timeBlock._id) {
    const response = await updateTimeBlock(timeBlock._id, timeBlock);
    const updatedTimeBlock = response.data.timeBlock;
    const currentIndex = timeBlocks.findIndex(t => updatedTimeBlock._id === t._id);
    const updatedTimeBlocks = [...timeBlocks];

    updatedTimeBlocks[currentIndex] = updatedTimeBlock;
    return updatedTimeBlocks;
  } else {
    timeBlock.providerRef = timeBlock.user._id;
    const response = await createTimeBlock(timeBlock);
    return [...timeBlocks, response.data.timeBlock];
  }
};

export const deleteTimeBlock = async ({ timeBlocks, timeBlockId, alpha2 }) => {
  const timeBlockRefs = [timeBlockId];
  await deleteTimeBlocks({ timeBlockRefs, alpha2 });
  return timeBlocks.filter(t => timeBlockId !== t._id);
};

const upsertCollection = (collection, data) => {
  const index = collection.findIndex(c => c._id === data._id);
  if (index === -1) {
    collection.push(data);
  } else {
    collection[index] = data;
  }
};

const removeFromCollections = (collections, data) => {
  for (const dateKey in collections) {
    collections[dateKey] = collections[dateKey].filter(c => c._id !== data._id);
  }
};

export const updateERosterTimeBlock = (websocketData, alpha2) => {
  const { timeBlock, operation } = websocketData;

  eRosterStore.update(s => {
    if (operation === "delete") {
      removeFromCollections(s.timeBlocks, timeBlock);
      return;
    }

    if (!timeBlock) return;
    if (timeBlock.alpha2 !== alpha2) return;

    const dateKey = getKeyFromDate(startOfDay(parseJSON(timeBlock.start)));

    if (s.timeBlocks[dateKey]) {
      upsertCollection(s.timeBlocks[dateKey], timeBlock);
      upsertCollection(s.providers[dateKey], timeBlock.user);
    }
  });
};

export const updateERosterService = (data, alpha2) => {
  const { service: incomingService } = data;

  if (!incomingService) return;
  if (incomingService.country !== alpha2) return;
  if (!incomingService.schedule.start) return;

  const service = deepCloneObject(incomingService);

  const offset = serviceScheduleOffset[service.type];
  const start = startOfDay(subMinutes(new Date(service.schedule.start), offset.startOffset));
  const end = endOfDay(addMinutes(new Date(service.schedule.end), offset.endOffset));

  service.createdBy = service.createdBy?.user?.name;

  const lastLog = service.logs.slice(-1);
  service.lastLog = { name: lastLog?.by?.name, at: lastLog?.date };
  delete service.logs;

  eRosterStore.update(s => {
    // remove this service in all dates (timeline) in e-Roster
    removeFromCollections(s.services, service);

    if (service.status === "cancelled") return;

    for (const dateKey in s.services) {
      const date = new Date(dateKey);
      if (!isWithinInterval(date, { start, end })) continue;

      upsertCollection(s.services[dateKey], service);

      for (const provider of service.providers) {
        upsertCollection(s.providers[dateKey], provider.user);
      }
    }
  });
};
