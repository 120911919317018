export default function AttachmentIcon(props) {
  return (
    <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.75065 5.00016V14.5835C8.75065 16.4252 7.25898 17.9168 5.41732 17.9168C3.57565 17.9168 2.08398 16.4252 2.08398 14.5835V4.16683C2.08398 3.01683 3.01732 2.0835 4.16732 2.0835C5.31732 2.0835 6.25065 3.01683 6.25065 4.16683V12.9168C6.25065 13.3752 5.87565 13.7502 5.41732 13.7502C4.95898 13.7502 4.58398 13.3752 4.58398 12.9168V5.00016H3.33398V12.9168C3.33398 14.0668 4.26732 15.0002 5.41732 15.0002C6.56732 15.0002 7.50065 14.0668 7.50065 12.9168V4.16683C7.50065 2.32516 6.00898 0.833496 4.16732 0.833496C2.32565 0.833496 0.833984 2.32516 0.833984 4.16683V14.5835C0.833984 17.1168 2.88398 19.1668 5.41732 19.1668C7.95065 19.1668 10.0007 17.1168 10.0007 14.5835V5.00016H8.75065Z"
        fill="#979797"
      />
    </svg>
  );
}
