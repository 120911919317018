export default function HomeVisitIcon(props) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="34" height="34" rx="8" fill="#E9F4FF" />
      <path d="M26.2084 27.625H7.79175V15.9125L16.8192 8.5L26.2084 15.9125V27.625Z" fill="#EFDEBF" />
      <path
        d="M27.6039 27.6741C27.6039 27.7088 27.6061 27.5903 27.6061 27.625H20.1886C20.1886 27.5903 20.1875 27.7088 20.1875 27.6741C20.1875 27.3804 20.3045 27.0986 20.5126 26.8909C20.7208 26.6832 21.0032 26.5665 21.2976 26.5665C21.3828 26.5675 21.4677 26.5781 21.5506 26.5981C21.6962 26.2722 21.933 25.9952 22.2326 25.8004C22.5322 25.6056 22.8818 25.5013 23.2394 25.5H23.5557C23.9394 25.4999 24.3134 25.6202 24.6247 25.8439C24.8016 25.9721 24.9547 26.1301 25.0769 26.311L25.1307 26.2762C25.164 26.3141 25.1946 26.3541 25.2224 26.3961C25.4424 26.2497 25.6966 26.1624 25.9603 26.1426C26.224 26.1229 26.4884 26.1713 26.7278 26.2833C26.9673 26.3953 27.1737 26.5671 27.3272 26.782C27.4807 26.9969 27.576 27.2476 27.6039 27.51C27.6074 27.5647 27.6074 27.6195 27.6039 27.6741Z"
        fill="#499644"
      />
      <path
        d="M27.6004 27.5447C27.6021 27.5773 27.6021 27.5924 27.6004 27.625H20.6426C20.6409 27.5924 20.6409 27.5773 20.6426 27.5447C20.6435 27.2688 20.7537 27.0044 20.9492 26.8093C21.1448 26.6142 21.4098 26.5043 21.6863 26.5034C21.7664 26.5042 21.846 26.5148 21.9235 26.535C22.0605 26.2299 22.2823 25.9702 22.5625 25.7868C22.8427 25.6034 23.1697 25.5039 23.5048 25.5H23.7863C24.0769 25.4996 24.363 25.5723 24.6181 25.7114C24.8134 25.8211 24.9854 25.968 25.1241 26.1437C25.1574 26.1815 25.188 26.2216 25.2158 26.2636C25.4358 26.1172 25.6899 26.0299 25.9537 26.0101C26.2174 25.9903 26.4818 26.0388 26.7212 26.1508C26.9607 26.2628 27.1671 26.4345 27.3206 26.6494C27.4741 26.8643 27.5693 27.1151 27.5973 27.3775C27.5973 27.4406 27.6004 27.4911 27.6004 27.5447Z"
        fill="#68BC49"
      />
      <path
        d="M22.905 17H18.0567C17.9851 17 17.927 17.0579 17.927 17.1294V21.3829C17.927 21.4544 17.9851 21.5123 18.0567 21.5123H22.905C22.9766 21.5123 23.0347 21.4544 23.0347 21.3829V17.1294C23.0347 17.0579 22.9766 17 22.905 17Z"
        fill="#2D5F8C"
      />
      <path
        d="M18.5411 17.5022H20.2173V21.0079H18.5411C18.5092 21.0079 18.4786 20.9953 18.4561 20.9728C18.4336 20.9503 18.4209 20.9198 18.4209 20.888V17.6221C18.4209 17.5903 18.4336 17.5598 18.4561 17.5373C18.4786 17.5148 18.5092 17.5022 18.5411 17.5022Z"
        fill="#E9F4FF"
      />
      <path
        d="M20.7078 17.5022H22.384C22.4158 17.5022 22.4464 17.5148 22.4689 17.5373C22.4915 17.5598 22.5041 17.5903 22.5041 17.6221V20.888C22.5041 20.9198 22.4915 20.9503 22.4689 20.9728C22.4464 20.9953 22.4158 21.0079 22.384 21.0079H20.7078V17.5022Z"
        fill="#E9F4FF"
      />
      <path d="M23.2461 21.3772H17.7084V21.7748H23.2461V21.3772Z" fill="#2D5F8C" />
      <path d="M23.2461 21.6443H17.7084V21.8336H23.2461V21.6443Z" fill="#1B517A" />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M18.5408 17.5011H18.8096V21.0068H18.5313C18.4994 21.0068 18.4689 20.9942 18.4463 20.9717C18.4238 20.9492 18.4111 20.9187 18.4111 20.8869V17.621C18.4111 17.6044 18.4145 17.588 18.4211 17.5728C18.4277 17.5576 18.4374 17.544 18.4496 17.5327C18.4618 17.5214 18.4762 17.5128 18.4919 17.5074C18.5076 17.5019 18.5242 17.4998 18.5408 17.5011Z"
          fill="#E9F4FF"
        />
      </g>
      <g style={{ mixBlendMode: "multiply" }}>
        <path d="M21.1159 17.5022H20.7174V21.0079H21.1159V17.5022Z" fill="#E9F4FF" />
      </g>
      <path d="M7.79175 17H9.20841V27.625H7.79175V17Z" fill="#D6C29E" />
      <path
        d="M6.49219 16.7185C6.51189 16.71 6.53006 16.6982 6.54595 16.6838L16.7929 6.46013C16.848 6.4056 16.9224 6.375 17 6.375C17.0776 6.375 17.1521 6.4056 17.2072 6.46013L27.4541 16.6838C27.47 16.6982 27.4882 16.71 27.5079 16.7185L28.9564 18.1637C29.011 18.2187 29.0417 18.293 29.0417 18.3704C29.0417 18.4478 29.011 18.5221 28.9564 18.5771L27.8653 19.6657C27.8107 19.72 27.7368 19.7506 27.6597 19.7506C27.5826 19.7506 27.5087 19.72 27.4541 19.6657L17.2072 9.44203C17.1521 9.3875 17.0776 9.3569 17 9.3569C16.9224 9.3569 16.848 9.3875 16.7929 9.44203L6.54595 19.6657C6.4914 19.72 6.41746 19.7506 6.34038 19.7506C6.2633 19.7506 6.18936 19.72 6.13481 19.6657L5.0437 18.5771C4.98904 18.5221 4.95837 18.4478 4.95837 18.3704C4.95837 18.293 4.98904 18.2187 5.0437 18.1637L6.49219 16.7185Z"
        fill="#174889"
      />
      <path
        d="M10.625 19.8333C10.625 19.4421 10.9421 19.125 11.3333 19.125H15.5833C15.9745 19.125 16.2917 19.4421 16.2917 19.8333V27.625H10.625V19.8333Z"
        fill="#614F38"
      />
    </svg>
  );
}
