import { LicenseInfo } from "@mui/x-data-grid-pro";
import { Helmet } from "react-helmet";
import { RouterProvider } from "react-router-dom";
import { router } from "./pages/jarvisRoutes";
import "./styles/index.scss";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_KEY);

function App() {
  return (
    <div id="app">
      <Helmet>
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAP_KEY}&libraries=places`}
        />
      </Helmet>

      <RouterProvider router={router} />
    </div>
  );
}

export default App;
