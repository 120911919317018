import { datadogRum } from "@datadog/browser-rum";
import { createContext, useContext, useEffect, useState } from "react";
import Login from "../pages/login";
import { getSession, login, logout } from "../services/authenticationService";
import { resetNewTaskCount } from "../stores/taskStore";

export const SessionContext = createContext();

export function useSessionContext() {
  return useContext(SessionContext);
}

const SessionContextProvider = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [session, setSession] = useState(undefined);

  useEffect(() => {
    const getSessionData = async jwt => {
      try {
        setIsLoading(true);

        const { data } = await getSession(jwt);
        setSession(data);
      } catch (e) {
        removeJwtAndSession();
      } finally {
        setIsLoading(false);
      }
    };
    const jwt = localStorage.getItem("jwt");
    if (jwt) getSessionData(jwt);
  }, []);

  useEffect(() => {
    if (session) {
      datadogRum.setUser({
        id: session.user.id,
        email: session.user.email,
        name: session.user.name,
        roles: session.user.roles,
      });
    } else {
      datadogRum.clearUser();
    }
  }, [session]);

  const loginHandler = async loginData => {
    localStorage.setItem("key", loginData.key);

    try {
      const { data: session } = await login({
        email: loginData.email,
        password: loginData.password,
      });

      setSession(session);
      localStorage.setItem("jwt", session.jwt);

      resetNewTaskCount(true);
    } catch (e) {
      throw e.data;
    }
  };

  const logoutHandler = async () => {
    try {
      await logout();
    } catch (e) {
      throw e.data;
    } finally {
      removeJwtAndSession();
      setIsLoading(false);
    }
  };

  const removeJwtAndSession = () => {
    localStorage.removeItem("jwt");
    setSession(undefined);
  };

  return (
    <SessionContext.Provider
      value={{
        session,
        login: loginHandler,
        logout: logoutHandler,
        isLoggedIn: !!session,
      }}>
      {!session || isLoading ? <Login isLoading={isLoading} /> : props.children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
