import React from "react";
import { useTranslation } from "react-i18next";
import { store } from "../../stores/storeManager";

export default function Tabs({ tabs, activeIndex, onTabClick, className = "", ...props }) {
  const { t = () => "" } = useTranslation();
  const { language } = store.useState(s => ({
    language: s.language
  }));

  const serviceTitle = t(`${language}.providerService`);

  const formattedTabs = tabs.map(text => serviceTitle[text.toLowerCase()]);

  return (
    <div className={`tabsUM ${className}`} {...props}>
      {formattedTabs.map((text, i) => (
        <div
          key={i}
          onClick={() => {
            if (i !== activeIndex) onTabClick(tabs[i]);
          }}
          className={`tabItem ${i === activeIndex ? "active" : ""}`}>
          {text}
        </div>
      ))}
    </div>
  );
}
