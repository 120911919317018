import URI from "urijs";
import http from "./httpService";

export function getAddOnItems(data) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/dispatcher/items/addon`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function getServiceItems(data) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/dispatcher/items/service`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export async function getGeolocalizedProviders(query) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/dispatcher/map/geolocalized-providers`);
  uri.addSearch(query);
  return await http.get(uri.toString());
}

export async function getRosterStatusProvider(providerId, query) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/dispatcher/roster-status/${providerId}`);
  uri.addSearch(query);
  return await http.get(uri.toString());
}

export function getProviders(query) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/dispatcher/providers`);
  if (query) uri.addSearch(query);
  return http.get(uri.toString());
}

export function getRecommendedProviders(query) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/providers/recommended`);
  if (query) uri.addSearch(query);
  return http.get(uri.toString());
}

export function createAvixoUser(jarvisRef, alpha2) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/user/avixo`, { jarvisRef, alpha2 });
}

export function getSymptoms(data) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/dispatcher/symptoms`);
  uri.addSearch(data);
  return http.get(uri.toString());
}
