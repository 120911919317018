import React from "react";
import Input from "./input";
import Select from "./select";
import { countryList } from "../../utils/globalConfig";
import { store } from "../../stores/storeManager";

const sortedCountries = [...countryList].sort((a, b) => (a.phone > b.phone ? 1 : -1));

const extList = sortedCountries.map(c => {
  return {
    id: c.phone,
    title: `${c.flag} +${c.phone}`
  };
});

export default function InputPhone({ ext, number, placeholder, onChange, showInfo, info, disabled, icon, ...props }) {
  const {
    country: { alpha2 }
  } = store.useState(s => s);
  const defaultCountry = sortedCountries.find(c => c.alpha2 === alpha2);
  const defaultExt = parseInt(ext || defaultCountry.phone);

  return (
    <div className="inputPhoneUM">
      {icon && <div className="icon">{icon}</div>}
      <Select
        className="sm"
        defaultValue={defaultExt}
        options={extList}
        disabled={disabled}
        onChange={ext => onChange({ ext: ext.id, number })}
      />
      <Input
        {...props}
        className="sm"
        type="number"
        value={number}
        disabled={disabled}
        placeholder={placeholder}
        onChange={number => onChange({ ext: defaultExt, number })}
        info={info}
        showInfo={showInfo}
      />
    </div>
  );
}
