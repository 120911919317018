import { Divider, Grid, Button as MUIButton } from "@mui/material";
import { Dollar, Trash } from "components/oldDesignAssets/icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getPaymentIcon } from "utils/globalUtils";
import { validateCode } from "../../../services/codeService";
import { getEstimatedPrice } from "../../../services/requestService";
import { addPayment, deletePayment } from "../../../services/userService";
import { requestStore } from "../../../stores/modalManager";
import { store } from "../../../stores/storeManager";
import { getAvailablePaymentMethods } from "../../../utils/getAvailablePaymentMethods";
import notify from "../../../utils/notify";
import CreateStripeCard from "../../global/createStripeCard";
import Button from "../button";
import Header from "../header";
import { ArrowLeft, Building, FreeDollar, RoundCheck } from "../icons";
import Input from "../input";
import NotificationModal from "../notificationModal";
import { RadioButton, RadioButtonContainer } from "../radioButton";
import Tag from "../tag";
import Tooltip from "../tooltip";
import CreateRequest from "./createRequest";
import EditRequest from "./editRequest";

export default function PaymentMethod() {
  const request = requestStore.useState(s => ({ ...s.request, ...s.unsavedChanges }));
  const country = store.useState(s => s.country);
  const alpha2 = country.alpha2;

  const [showAddCard, setShowAddCard] = useState(false);
  const [isLoadingCode, setIsLoadingCode] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [codeId, setCodeId] = useState(request.billing.code?.id || "");
  const [error, setError] = useState({});

  const selectedPaymentMethod = request.billing.selectedPaymentMethod;
  const billingNote = request.billing.note;
  const free = request.billing.free;
  const payer = request.owner || request.user;
  const businessPaymentMethods = request.availablePaymentMethods?.filter(pm => pm.origin === "business");
  const payerPaymentMethods = request.availablePaymentMethods?.filter(pm => pm.origin !== "business");
  const isMedDelivery = request.type === "Delivery" && request.specialty === "Rider";
  const allowApplyPromoCode =
    !request.business?._id && !isMedDelivery && [undefined, "draft", "accepted"].includes(request.status);

  const updateRequestBilling = updatedFields => {
    updateRequest({
      billing: { ...request.billing, ...updatedFields },
      isDirty: { ...request.isDirty, billing: true },
    });
  };

  const updateRequest = updatedFields => {
    requestStore.update(s => {
      for (const field in updatedFields) {
        s.unsavedChanges[field] = updatedFields[field];
      }
    });
  };

  const validateSelectedPayment = () => {
    const err = {};

    if (selectedPaymentMethod?.type === "nets" && billingNote?.length > 0 && billingNote?.length !== 12) {
      err.nets = "Receipt Number must be 12 digits";
    }

    setError(err);

    return !Object.values(err).find(e => e);
  };

  const showRequest = async e => {
    e.preventDefault();

    if (!validateSelectedPayment()) return;

    if (request._id && request.isDirty.billing && !request.plato?.invoiceId) {
      try {
        const {
          data: { pricing },
        } = await getEstimatedPrice({
          alpha2,
          userRef: (request.owner || request.user)._id,
          serviceRef: request._id,
          serviceType: request.type,
          serviceSpecialty: request.specialty,
          caseType: request.case?.type,
          programRef: request.case?.program?._id,
          date: request.schedule.start,
          businessRef: request.business?._id,
          selectedItems: request.items
            .filter(i => !i.item.tags.includes("service"))
            .map(i => ({ quantity: i.quantity, item: { _id: i.item._id } })),
          code: codeId,
        });

        updateRequest({ items: pricing.items });
        updateRequestBilling({ totals: pricing.totals });
      } catch (error) {
        notify(error.data, "error");
        return;
      }
    }

    requestStore.update(s => {
      s.componentsToRender = request._id ? <EditRequest /> : <CreateRequest />;
    });
  };

  const onCardAdded = async pm => {
    const addPaymentResult = await addPayment(payer._id, { paymentMethodId: pm.id });
    const { user: updatedPayer, paymentMethod } = addPaymentResult.data;

    const updatedFields = {
      owner: updatedPayer,
      billing: { ...request.billing, preferredMethod: paymentMethod._id },
      availablePaymentMethods: getAvailablePaymentMethods({
        country,
        owner: updatedPayer,
        user: request.user,
      }),
      isDirty: { ...request.isDirty, billing: true },
    };

    updateRequest(updatedFields);
    setShowAddCard(false);
  };

  const onClickValidate = async () => {
    try {
      const billing = {};
      if (codeId) {
        setIsLoadingCode(true);
        const { data } = await validateCode({
          id: codeId,
          userRef: payer._id,
          serviceType: request.type,
          serviceSpecialty: request.specialty,
          alpha2,
        });
        billing.code = { ...data.code, ref: data.code._id };
        updateRequestBilling(billing);
        notify(data.message);
        setIsLoadingCode(true);
      }
    } catch (err) {
      notify(err.data, "error");
    } finally {
      setIsLoadingCode(false);
    }
  };

  const onChangeCodeId = value => {
    setCodeId(value);
    if (request.billing.code?.id) updateRequestBilling({ code: {} });
  };

  const onDeletePaymentMethod = async pm => {
    try {
      setIsLoadingDelete(true);
      const { data } = await deletePayment(payer._id, { paymentMethodId: pm._id });
      notify(data.message);
      const updatedFields = {
        owner: data.user,
        availablePaymentMethods: getAvailablePaymentMethods({
          country,
          owner: data.user,
          user: request.user,
        }),
      };
      if (request.billing.preferredMethod === pm._id) {
        const defaultPaymentMethod =
          data.user.paymentMethods.find(pm => pm.isDefault) || data.user.paymentMethods.find(pm => pm.type === "cash");
        updatedFields.billing = { ...request.billing, preferredMethod: defaultPaymentMethod._id };
        updatedFields.isDirty = { ...request.isDirty, billing: true };
      }
      updateRequest(updatedFields);
    } catch (err) {
      notify(err.data, "error");
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const handleDeletePaymentMethodClick = paymentMethod => {
    NotificationModal({
      callback: () => onDeletePaymentMethod(paymentMethod),
      action: "delete",
    });
  };

  const renderCaption = pm => {
    const onClickCaption = event => {
      event.stopPropagation();
    };

    if (pm.type === "cash" && request.type === "Video") return "Unavailable for Video Consult";
    if (pm.type === "other") {
      return (
        <Input
          placeholder="Enter comments here..."
          value={selectedPaymentMethod?.type === "other" ? billingNote : ""}
          onChange={value => updateRequestBilling({ note: value })}
          onClick={onClickCaption}
          disabled={selectedPaymentMethod?.type !== pm.type || free}
        />
      );
    }
    if (pm.type === "nets" && request.status === "visitEnd") {
      return (
        <Input
          placeholder="Enter receipt number..."
          value={selectedPaymentMethod?.type === "nets" ? billingNote : ""}
          onChange={value => updateRequestBilling({ note: value })}
          onClick={onClickCaption}
          maxLength={12}
          info={<Tooltip id="tooltip" text={error.nets} />}
          showInfo={!!error.nets}
          disabled={selectedPaymentMethod?.type !== pm.type || free}
        />
      );
    }

    return pm.description;
  };

  const renderRadioButton = () => {
    const payerPaymentMethodOptions = !isMedDelivery
      ? payerPaymentMethods?.map(pm => {
          const disabled =
            (pm.type === "cash" && request.type === "Video") || (pm.type === "wavPay" && request.origin !== "WavPay");
          return (
            <RadioButton key={pm._id} id={pm._id} disabled={disabled}>
              <div className="paymentName">
                {getPaymentIcon(pm)}
                {pm.name || pm.data?.name}
              </div>

              <div className="caption">{renderCaption(pm)}</div>

              {pm.type === "card" && (
                <Trash
                  onClick={event => {
                    event.stopPropagation();
                    if (isLoadingDelete) return;
                    handleDeletePaymentMethodClick(pm);
                  }}
                  className="delete"
                  data-testid="deleteCard"
                />
              )}
            </RadioButton>
          );
        })
      : [];

    payerPaymentMethodOptions?.push(
      <RadioButton key="free" id="free">
        <div className="paymentName">
          <FreeDollar /> Free of Charge
        </div>
        <div className={`caption free ${free ? "active" : ""}`}>Patient will not be charged</div>
      </RadioButton>,
    );

    return payerPaymentMethodOptions;
  };

  const renderBusinessRadioButton = () => {
    return businessPaymentMethods?.map((pm, i) => (
      <RadioButton key={i} id={pm._id}>
        <div className="corporatePaymentMethod">
          <Tag>{pm.name}</Tag>
          {pm.ownerName}
        </div>
        <div className="corporatePaymentMethodCaption">Do not collect</div>
      </RadioButton>
    ));
  };

  const onChangePaymentMethod = paymentMethodId => {
    if (paymentMethodId === "free") {
      updateRequestBilling({ preferredMethod: undefined, free: true, note: "" });
      return;
    }

    const billing = {
      preferredMethod: paymentMethodId,
      selectedPaymentMethod: request.availablePaymentMethods.find(pm => pm._id === paymentMethodId),
      free: false,
      note: "",
    };

    updateRequestBilling(billing);
  };

  return (
    <div className="modalContent paymentMethodUM">
      <Header>
        <ArrowLeft onClick={showRequest} />
        <div className="title">Payment Methods</div>
      </Header>

      <div className="content">
        {businessPaymentMethods?.length > 0 && (
          <>
            <div className="corporateBilling">
              <Building />
              <div className="text">Corporate Billing</div>
            </div>
            <RadioButtonContainer
              value={request.billing.free ? "free" : selectedPaymentMethod?._id}
              onChange={onChangePaymentMethod}
              className="paymentMethodList">
              {renderBusinessRadioButton()}
            </RadioButtonContainer>
            <hr />
          </>
        )}

        <div className="individualBilling">
          <Dollar />
          <div className="text">Individual Billing</div>
          {!showAddCard ? (
            !isMedDelivery && (
              <Button
                onClick={() => setShowAddCard(true)}
                text="Add Card"
                type="button"
                className="confirm"
                data-testid="addCard"
              />
            )
          ) : (
            <Link to="#" type="button" className="linkRed" onClick={() => setShowAddCard(false)}>
              Cancel
            </Link>
          )}
        </div>

        {showAddCard ? <CreateStripeCard buttonText="Confirm" onCardAdded={onCardAdded} /> : null}

        <RadioButtonContainer
          value={request.billing.free ? "free" : selectedPaymentMethod?._id}
          onChange={onChangePaymentMethod}
          className="paymentMethodList">
          {renderRadioButton()}
        </RadioButtonContainer>
      </div>

      <Divider />

      <Grid container p={2} wrap="nowrap">
        {allowApplyPromoCode && (
          <Grid container className="promoCodeContainer" alignItems="center" gap={2}>
            <Input
              placeholder="Promo Code"
              value={codeId}
              onChange={onChangeCodeId}
              info={<RoundCheck className="codeCheck" />}
              showInfo={request.billing.code?.id}
            />
            <Link to="#" className="linkGreen" onClick={onClickValidate}>
              {isLoadingCode ? "Please wait..." : "Validate"}
            </Link>
          </Grid>
        )}
        <MUIButton
          color="secondary"
          onClick={showRequest}
          data-testid="saveButton"
          sx={{
            height: "34px",
            fontSize: "14px",
            lineHeight: "18px",
            minHeight: "unset",
            marginLeft: "auto",
          }}
          variant="contained">
          Save
        </MUIButton>
      </Grid>
    </div>
  );
}
