import { differenceInMinutes, differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";

const MAX_WAITING_MINUTES = 99;

const TimerText = ({ time }) => (
  <div className="renderTime danger">
    <span>{time}</span>
  </div>
);

export default function Timer({ startTime, showASAP = true }) {
  const [, setElapsedSeconds] = useState(0);

  const now = new Date();
  const minutesDiff = differenceInMinutes(now, new Date(startTime));
  const secondsDiff = differenceInSeconds(now, new Date(startTime));
  const stopTimer = minutesDiff >= MAX_WAITING_MINUTES;

  const minutes = stopTimer ? MAX_WAITING_MINUTES : minutesDiff;
  const seconds = stopTimer ? 0 : secondsDiff % 60;
  const formatTime = `${minutes}:${String(seconds).padStart(2, "0")}`;

  useEffect(() => {
    if (stopTimer) return;

    const interval = setInterval(() => {
      if (stopTimer) clearInterval(interval);
      setElapsedSeconds(prevValue => prevValue + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [stopTimer]);

  return showASAP ? (
    <div className="timer">
      <span className="title">ASAP</span>
      <TimerText time={formatTime} />
    </div>
  ) : (
    <TimerText time={formatTime} />
  );
}
