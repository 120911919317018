import axios from "axios";

axios.interceptors.request.use(config => {
  const jwt = localStorage.getItem("jwt");
  const apikey = localStorage.getItem("key");

  if (jwt) config.headers["Authorization"] = jwt;
  if (apikey) config.headers["x-api-key"] = apikey;

  return config;
});

axios.interceptors.response.use(
  res => {
    // Any status code that lie within the range of 2xx cause this function to trigger

    return res;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    if (
      error.response?.status === 401 &&
      error.response?.config.url !== `${process.env.REACT_APP_API_REST_ENDPOINT}/session/login`
    ) {
      localStorage.removeItem("jwt");
      window.location.reload();
    }

    return Promise.reject(error.response || { ok: 0, data: error.toString() });
  }
);

export default axios;
