import { lazy } from "react";

const lazyWithRetry = componentImport =>
  lazy(async () => {
    const forceRefreshed = JSON.parse(window.localStorage.getItem("forceRefreshed") || "false");

    try {
      const component = await componentImport();
      window.localStorage.setItem("forceRefreshed", "false");

      return component;
    } catch (error) {
      if (!forceRefreshed) {
        window.localStorage.setItem("forceRefreshed", "true");
        return window.location.reload();
      }

      throw error;
    }
  });

export default lazyWithRetry;
