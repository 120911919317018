import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { muiTheme } from "muiTheme";
import { ModalProvider } from "react-modal-hook";
import SnackBarProvider from "./snackBarContext";

export default function ProvidersWrapper({ children }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={muiTheme}>
        <SnackBarProvider>
          <ModalProvider>{children}</ModalProvider>
        </SnackBarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
