import { CheckWhite } from "components/oldDesignAssets/icons";

export default function Checkbox({
  id,
  className = "",
  onChange,
  defaultChecked = false,
  text = "",
  disabled,
  ...props
}) {
  const handleOnChange = e => {
    onChange(e.target.checked);
  };

  return (
    <label className={`containerCheckBoxUM ${defaultChecked ? "checked" : ""} ${className}`} {...props}>
      <div className="text">{text}</div>
      <input
        type="checkbox"
        disabled={disabled}
        onChange={e => !disabled && handleOnChange(e)}
        id={id}
        defaultChecked={defaultChecked}
      />
      <div className="checkmark">{defaultChecked ? <CheckWhite /> : ""}</div>
    </label>
  );
}
