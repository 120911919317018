import toast from "toasted-notes";

const notify = (message = "", className = "default", position = "bottom-right") => {
  const bodyMessage = typeof message === "object" ? JSON.stringify(message, null, 2) : message;

  toast.notify(<div className={className}>{bodyMessage}</div>, {
    position,
  });
};

export default notify;
