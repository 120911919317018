import React from "react";
import { store } from "../../stores/storeManager";

export default function Price({ currency, value, className, prefix = "", postfix = "", string = false }) {
  const storeManager = store.useState(s => s);
  const currencyToUse = currency ? currency : storeManager.country.currencySymbol || "";
  const positionToUse = storeManager.country.currencySymbolPosition || "before";
  let priceToShow = "";
  if (!isNaN(value)) {
    if (positionToUse === "before")
      priceToShow = `${prefix}${currencyToUse}${Number(value).toLocaleString()}${postfix}`;
    else priceToShow = `${prefix}${Number(value).toLocaleString()} ${currencyToUse}${postfix}`;
  }

  return string ? priceToShow : <p className={`price ${className}`}>{priceToShow}</p>;
}
