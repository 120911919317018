import { X } from "components/oldDesignAssets/icons";
import { useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import tryFormatUrl from "utils/tryFormatUrl";
import httpService from "../../services/httpService";
import Loader from "./loader";
import Modal from "./modal";

export default function ImageViewer({ src = "", alt = "", loadedCallback, width, previewHeight, auth = true }) {
  const [imageURL, setImageURL] = useState(!auth ? src : undefined);
  const [showModal, hideModal] = useModal(
    () => (
      <Modal className="previewMediaModal" show={showModal} hide={hideModal} modalClassName="previewModal">
        <div className="content">
          <img src={imageURL} alt={alt} height={previewHeight} />
        </div>
        <span className="cross" onClick={hideModal}>
          <X />
        </span>
      </Modal>
    ),
    [imageURL],
  );

  useEffect(() => {
    if (!src || !auth) return;

    const fetchImage = async () => {
      const imageResponse = await httpService.get(tryFormatUrl(src, process.env.REACT_APP_API_REST_ENDPOINT), {
        responseType: "blob",
      });
      const image = URL.createObjectURL(imageResponse.data);
      setImageURL(image);
      if (loadedCallback) loadedCallback(image);
    };
    fetchImage();
  }, [src, loadedCallback, auth]);

  return imageURL ? <img src={imageURL} alt={alt} onClick={showModal} width={width} /> : <Loader />;
}
