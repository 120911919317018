import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton, Portal, Snackbar } from "@mui/material";
import { createContext, useCallback, useContext, useMemo, useState } from "react";

const contextParams = { pop: _arg => {} };

export const SnackBarContext = createContext(contextParams);

export const useSnackBar = () => useContext(SnackBarContext);

export default function SnackBarProvider(props) {
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(null);
  const [content, setContent] = useState(undefined);
  const [closeCallback, setCloseCallback] = useState(undefined);
  const [alertProps, setAlertProps] = useState(undefined);
  const [snackBarProps, setSnackBarProps] = useState(undefined);

  const pop = useCallback(({ content, onClose, alertProps, snackBarProps }) => {
    setKey(new Date().getTime());
    setContent(content);
    setCloseCallback(onClose);
    setAlertProps(alertProps);
    setSnackBarProps(snackBarProps);
    setOpen(true);
  }, []);

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") return;

    if (closeCallback) closeCallback();

    setOpen(false);
  };

  const exposed = useMemo(() => {
    return { pop };
  }, [pop]);

  return (
    <SnackBarContext.Provider value={exposed}>
      {props.children}

      <Portal>
        <Snackbar
          key={key}
          open={open}
          message={alertProps ? undefined : content}
          action={
            alertProps ? undefined : (
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            )
          }
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={5000}
          {...snackBarProps}>
          {alertProps && (
            <Alert
              iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="inherit" />,
              }}
              {...alertProps}>
              {content}
            </Alert>
          )}
        </Snackbar>
      </Portal>
    </SnackBarContext.Provider>
  );
}
