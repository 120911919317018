import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Typography } from "@mui/material";
import { useMemo } from "react";

interface HoldingAmountLabelProps {
  paymentStatus: string;
}

export default function HoldingAmountLabel({ paymentStatus }: Readonly<HoldingAmountLabelProps>) {
  const data = useMemo(() => getLabelColorMapping(paymentStatus), [paymentStatus]);

  return (
    <Box display="inline-flex" alignItems="center" bgcolor={data.bgColor} px={0.5} mr={1} borderRadius={0.5}>
      <Typography variant="caption" sx={{ color: data.color }}>
        {data.label}
      </Typography>

      {paymentStatus === "succeeded" && <CheckIcon sx={{ color: "newEmerald.500", fontSize: 16 }} />}
      {paymentStatus === "failed" && <ClearIcon sx={{ color: "errorRed.500", fontSize: 16 }} />}
    </Box>
  );
}

const getLabelColorMapping = (status: string) => {
  const data = {
    label: status,
    color: "gray.500",
    bgColor: "gray.50",
  };

  return (
    {
      preAuthorized: {
        label: "Holding Fee",
        color: "#FFB020",
        bgColor: "warningOrange.50",
      },
      cancelled: {
        label: "Reversed",
        color: "purple.500",
        bgColor: "purple.50",
      },
      succeeded: {
        label: "Holding Fee",
        color: "secondary.main",
        bgColor: "newEmerald.50",
      },
      failed: {
        label: "Holding Fee",
        color: "errorRed.500",
        bgColor: "errorRed.50",
      },
    }[status] || data
  );
};
