export const showNotification = async (message, soundUrl) => {
  try {
    new Notification(message, {
      icon: `${process.env.PUBLIC_URL}/favicon.png`
    });
    const audio = new Audio(soundUrl);
    await audio.play();
  } catch (e) {
    console.log("Notification permission is not granted", e);
  }
};
