import { Box, Button, Divider } from "@mui/material";
import { Thermometer, X } from "components/oldDesignAssets/icons";
import { cloneDeep } from "lodash";
import { useState } from "react";
import escape from "regex-escape";
import { requestStore } from "../../../stores/modalManager";
import Header from "../header";
import { ArrowLeft, Plus } from "../icons";
import Input from "../input";
import Tag from "../tag";
import CreateRequest from "./createRequest";

export default function SymptomList() {
  const symptoms = requestStore.useState(s => s.symptoms);
  const request = requestStore.useState(s => s.unsavedChanges);

  const [filter, setFilter] = useState("");

  const symptomRecord = request.records.find(r => r.type === "symptoms") || {};

  const showCreateRequest = e => {
    e.preventDefault();
    requestStore.update(s => {
      s.componentsToRender = <CreateRequest />;
    });
  };

  const onClickSelectedSymptom = symptomToBeRemoved => {
    const newRecords = cloneDeep(request.records);
    const record = newRecords.find(r => r.type === "symptoms");

    record.data.symptoms = record.data.symptoms.filter(s => {
      const left = s.title ?? s;
      const right = symptomToBeRemoved.title ?? symptomToBeRemoved;
      return left !== right;
    });

    requestStore.update(s => {
      s.unsavedChanges.records = newRecords;
      s.unsavedChanges.isDirty.records = true;
    });
  };

  const onClickSymptom = symptomToBeAdded => {
    const newRecords = cloneDeep(request.records);
    const record = newRecords.find(r => r.type === "symptoms");
    if (record) record.data.symptoms.push(symptomToBeAdded);
    else {
      newRecords.push({
        type: "symptoms",
        data: {
          symptoms: [symptomToBeAdded],
        },
      });
    }
    requestStore.update(s => {
      s.unsavedChanges.records = newRecords;
      s.unsavedChanges.isDirty.records = true;
    });
  };

  return (
    <div className="modalContent symptomListUM">
      <Header>
        <ArrowLeft onClick={showCreateRequest} />
        <div className="title">Reasons & Symptom Tags</div>
      </Header>

      <div className="content">
        <Input value={filter} icon={<Thermometer />} onChange={setFilter} placeholder="Search Reason & Symptoms" />

        <div className="selectedSymptomContainer">
          {symptomRecord.data?.symptoms.map(symptom => {
            const title = symptom?.title || symptom;
            return (
              <Tag key={`selected-symptom-${title}`} onClick={() => onClickSelectedSymptom(symptom)}>
                {title} <X className="close" />
              </Tag>
            );
          })}
        </div>

        <div className="symptomListContainer">
          {symptoms
            .filter(
              symptom =>
                !symptomRecord.data?.symptoms.find(s => s.title === symptom.title) &&
                new RegExp(escape(`${filter}`), "gi").test(`${symptom.title}`)
            )
            .map((symptom, i) => (
              <Tag key={i} className="secondary" onClick={() => onClickSymptom(symptom)}>
                {symptom.title} <Plus />
              </Tag>
            ))}
        </div>
      </div>
      <Divider />

      <Box p={2} textAlign="right">
        <Button
          color="secondary"
          onClick={showCreateRequest}
          data-testid="saveButton"
          sx={{
            height: "34px",
            fontSize: "14px",
            lineHeight: "18px",
            minHeight: "unset",
          }}
          variant="contained"
          disabled={!symptomRecord?.data?.symptoms?.length}>
          Save
        </Button>
      </Box>
    </div>
  );
}
