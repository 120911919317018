import http from "./httpService";
import URI from "urijs";

export function getCases(data) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/cases`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function createCase(data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/case`, data);
}

export function updateCase(caseRef, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/case/${caseRef}`, data);
}
