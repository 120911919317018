import Button from "components/oldDesignAssets/button";
import Input from "components/oldDesignAssets/input";
import { useState } from "react";
import LoadingSection from "../components/loadingSection";
import { useSessionContext } from "../contexts/sessionContext";

export default function Login({ isLoading }) {
  const { login } = useSessionContext();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleLogin = async e => {
    e.preventDefault();
    try {
      setIsLoggingIn(true);
      await login({
        password: e.target.password?.value,
        email: e.target.email?.value,
        key: e.target.key?.value,
      });
    } catch (e) {
      setErrorMessage(e);
      setIsLoggingIn(false);
    }
  };

  return (
    <div id="login">
      <div id="left">
        <div className="content">
          <img alt="Speedoc logo" src="https://public.speedoc.com/design/logo/logotype_black.svg" />
          {isLoading || isLoggingIn ? (
            <LoadingSection>Logging you in...</LoadingSection>
          ) : (
            <form onSubmit={handleLogin} className="marginTop60">
              <label>
                Enter your email address
                <Input
                  type="email"
                  required
                  name="email"
                  placeholder="Your email address"
                  autoComplete="off"
                  className="sm"
                  pattern="[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+"
                  onInvalid={e => e.target.setCustomValidity("Must be like xxxx@xxx.xx")}
                  onInput={e => e.target.setCustomValidity("")}
                />
              </label>

              <label>
                Enter your secret Speedoc key
                <Input type="text" required placeholder="Your secret key" name="key" className="sm" />
              </label>
              <label>
                Enter your password
                <Input type="password" required name="password" autoComplete="off" className="sm" />
              </label>
              <Button type="submit" className="primary sm">
                <span>Login</span>
              </Button>
              <a
                className="changePassword"
                href={`${process.env.REACT_APP_WEBSITE_URI}/change-your-password?fromAdmin=true`}>
                Change Password
              </a>
              {errorMessage ? <p className="error">{errorMessage.message || errorMessage}</p> : null}
            </form>
          )}
        </div>
      </div>
      <div id="right" />
    </div>
  );
}
