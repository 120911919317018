export default function LocationListIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.4502 16.3166H20.1668V14.7583H11.6418C11.1835 15.4 10.8168 15.95 10.4502 16.3166Z" fill="#979797" />
      <path d="M13.1085 12.5583H20.1669V11H13.7502C13.5669 11.55 13.2919 12.0083 13.1085 12.5583Z" fill="#979797" />
      <path d="M16.2252 18.6084H7.05852V20.1667H16.2252V18.6084Z" fill="#979797" />
      <path
        d="M7.05834 9.2585C5.775 9.2585 4.76667 8.25016 4.76667 6.96683C4.76667 5.6835 5.775 4.5835 7.05834 4.5835C8.34167 4.5835 9.44167 5.59183 9.44167 6.96683C9.44167 8.1585 8.34167 9.2585 7.05834 9.2585ZM7.05834 1.8335C4.21667 1.8335 1.83334 4.12516 1.83334 6.96683C1.83334 9.62516 4.21667 13.1085 5.775 14.7585C6.41667 15.4002 7.05834 16.2252 7.05834 16.2252C7.05834 16.2252 7.79167 15.4002 8.43334 14.7585C9.99167 13.1085 12.2833 9.90016 12.2833 6.96683C12.2833 4.12516 9.99167 1.8335 7.05834 1.8335Z"
        fill="#979797"
      />
    </svg>
  );
}
