import URI from "urijs";
import http from "./httpService";

export function getCodes(data) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/codes`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function updateCode(data, id) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/code/${id}`, data);
}

export function getCode(id) {
  return http.get(`${process.env.REACT_APP_API_REST_ENDPOINT}/code/${id}`);
}

export function createCode(data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/codes`, data);
}

export function validateCode(data) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/code/validate`);
  uri.setSearch(data);
  return http.get(uri.toString());
}
