import { addHours, format, isSameDay, set } from "date-fns";
import { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { ChevronDown } from "./icons";

export default function DateTimePicker({
  selected,
  onChange,
  minTime,
  maxTime,
  disabled,
  className = "",
  showTimeSelect = true,
  showTimeSelectOnly = false,
  placeholderDate,
  excludeDates,
  placeholderTime,
  timeIntervals,
  filterTime,
  minDate = new Date(),
  maxDate,
  showMonthDropdown,
  showYearDropdown,
  dropdownMode,
  dateFormat = "eee dd MMM, yyyy",
  onChangeRaw = e => e.preventDefault(),
}) {
  const dateRef = useRef();
  const timeRef = useRef();
  const [selectedDate, setSelectedDate] = useState(selected ? new Date(selected) : undefined);

  const onChangeDate = date => {
    if (!date) return;

    const nextHour = addHours(new Date(), 1);
    const isToday = isSameDay(nextHour, date);
    const hour = new Date(selected).getHours() || isToday ? nextHour.getHours() : 9;
    const minute = new Date(selected).getMinutes() || 0;
    const finalDate = set(date, { hours: hour, minutes: minute, seconds: 0 });

    onChange(finalDate);
    setSelectedDate(finalDate);
  };

  const onChangeTime = time => {
    const hour = time.getHours();
    const minute = time.getMinutes();
    const finalDate = set(new Date(selected), { hours: hour, minutes: minute, seconds: 0 });

    setSelectedDate(finalDate);
  };

  const handleCalendarClose = () => {
    onChange(selectedDate);
  };

  return (
    <div className={`dateTimePickerUM ${disabled ? "disabled" : ""} ${className}`}>
      {!showTimeSelectOnly && (
        <div className="datePicker">
          <ReactDatePicker
            ref={dateRef}
            disabled={disabled}
            formatWeekDay={date => format(date, "eeeee")}
            selected={selectedDate}
            onChange={onChangeDate}
            minDate={minDate}
            maxDate={maxDate}
            dateFormat={dateFormat}
            placeholderText={placeholderDate}
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            dropdownMode={dropdownMode}
            excludeDates={excludeDates}
          />
          <ChevronDown onClick={() => dateRef.current.setOpen(true)} />
        </div>
      )}
      {showTimeSelect && (
        <div className="timePicker">
          <ReactDatePicker
            ref={timeRef}
            disabled={disabled}
            showTimeSelect
            showTimeSelectOnly
            selected={selectedDate}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            onChange={onChangeTime}
            minTime={minTime}
            maxTime={maxTime}
            filterTime={filterTime}
            timeIntervals={timeIntervals}
            placeholderText={placeholderTime}
            onChangeRaw={onChangeRaw}
            onCalendarClose={handleCalendarClose}
          />
          <ChevronDown onClick={() => timeRef.current.setOpen(true)} />
        </div>
      )}
    </div>
  );
}
