import { useEffect } from "react";

export default function useDisableScrollBar(activate = true) {
  useEffect(() => {
    const disableScrollBar = () => {
      const x = window.scrollX;
      const y = window.scrollY;
      window.onscroll = () => {
        window.scrollTo(x, y);
      };
    };
    if (activate) disableScrollBar();
    return () => (window.onscroll = () => {});
  }, [activate]);
}
