import React from "react";
import Tooltip from "./tooltip";

const requiredField = <Tooltip text="Required Field" />;
export default function InputButton({
  className = "",
  text,
  icon,
  onClick,
  info = requiredField,
  showInfo,
  placeholder = "",
  disabled,
  ariaLabel,
  ...props
}) {
  return (
    <div className={`inputButtonUM ${className} ${disabled ? "disabled" : ""}`} {...props}>
      {icon && <div className="icon">{icon}</div>}
      <div className="text" role="button" aria-label={ariaLabel} onClick={() => !disabled && onClick()}>
        {text}
        {!text && placeholder ? <span className="placeholder">{placeholder}</span> : null}
        {showInfo && info}
      </div>
    </div>
  );
}
