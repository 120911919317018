import LoadingSection from "components/loadingSection";
import Navbar from "components/navbar";
import ProvidersWrapper from "contexts/providersWrapper";
import SessionContextProvider, { useSessionContext } from "contexts/sessionContext";
import { Suspense } from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route } from "react-router-dom";
import ModalManager from "stores/modalManager";
import StoreManager from "stores/storeManager";
import { role } from "../utils/globalConfig";
import lazyWithRetry from "../utils/lazyWithRetry";
import PrivateLogin from "./privateLogin";

const Four0Four = lazyWithRetry(() => import("../pages/404"));

const Ubf = lazyWithRetry(() => import("components/ubf"));

const Dispatcher = lazyWithRetry(() => import("../pages/dispatcher/dispatcher"));
const DispatcherMap = lazyWithRetry(() => import("../pages/dispatcher/map"));

const CareCoordination = lazyWithRetry(() => import("../pages/care-coordination/CareCoordination"));
const CaseManagement = lazyWithRetry(() => import("./care-coordination/case-management/CaseManagement"));
const ServiceManagement = lazyWithRetry(() => import("./care-coordination/service-management/ServiceManagement"));

const Task = lazyWithRetry(() => import("../pages/task/task"));

const Patient = lazyWithRetry(() => import("../pages/patient"));
const PatientDetail = lazyWithRetry(() => import("../pages/patient/patientDetail"));
const PatientForm = lazyWithRetry(() => import("../pages/patient/patientForm"));

const ERoster = lazyWithRetry(() => import("../pages/e-roster/ERoster"));

const Admin = lazyWithRetry(() => import("../pages/admin/admin"));
const Codes = lazyWithRetry(() => import("./admin/codes"));
const CodeList = lazyWithRetry(() => import("./admin/codes/codeList"));
const CodeForm = lazyWithRetry(() => import("./admin/codes/codeForm"));
const Prices = lazyWithRetry(() => import("../pages/admin/itemPrices"));
const Places = lazyWithRetry(() => import("../pages/admin/places"));
const Providers = lazyWithRetry(() => import("../pages/admin/providers"));
const ProviderDetail = lazyWithRetry(() => import("./admin/providers/details/providerDetail"));
const ProviderForm = lazyWithRetry(() => import("./admin/providers/forms/providerForm"));
const Symptoms = lazyWithRetry(() => import("./admin/symptoms"));
const SymptomList = lazyWithRetry(() => import("./admin/symptoms/symptomList"));
const SymptomForm = lazyWithRetry(() => import("./admin/symptoms/symptomForm"));
const Settings = lazyWithRetry(() => import("../pages/admin/settings"));

const Corporate = lazyWithRetry(() => import("../pages/corporate/corporate"));
const CorporateForm = lazyWithRetry(() => import("../pages/corporate/corporateForm"));
const CorporateDetails = lazyWithRetry(() => import("../pages/corporate/corporateDetails"));

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <PrivateLogin>
          <SessionContextProvider>
            <ProvidersWrapper>
              <StoreManager />
              <ModalManager />
              <Navbar />
              <Suspense fallback={<LoadingSection />}>
                <Outlet />
              </Suspense>
            </ProvidersWrapper>
          </SessionContextProvider>
        </PrivateLogin>
      }
      errorElement={<Four0Four />}>
      <Route exact path="/" element={<Navigate to="/dispatcher/cards" />} />
      <Route exact path="/dispatcher" element={<Navigate to="/dispatcher/cards" />} />
      <Route path="dispatcher/cards" element={<Dispatcher />}>
        <Route path="service/new" element={<Ubf />} />
      </Route>
      <Route path="dispatcher/map" element={<DispatcherMap />} />

      <Route path="care-coordination" element={<CareCoordination />}>
        <Route path="case-management" element={<CaseManagement />} />
        <Route path="service-management" element={<ServiceManagement />} />
      </Route>

      <Route path="task" element={<Task />} />

      <Route path="patient" element={<Patient />}>
        <Route path="new" element={<PatientForm />} />
        <Route path=":id" element={<PatientDetail />} />
        <Route path="edit/:id" element={<PatientForm />} />
      </Route>

      <Route path="e-roster" element={<ERoster />} />

      <Route element={<ProtectedRoute />}>
        <Route path="admin" element={<Admin />}>
          <Route path="prices" element={<Prices />} />
          <Route path="codes" element={<Codes />}>
            <Route index element={<CodeList />} />
            <Route path="new" element={<CodeForm />} />
            <Route path=":codeRef/edit" element={<CodeForm />} />
          </Route>
          <Route path="providers" element={<Providers />}>
            <Route path=":providerRef" element={<ProviderDetail />} />
            <Route path="new" element={<ProviderForm />} />
            <Route path="edit/:providerRef" element={<ProviderForm />} />
          </Route>
          <Route path="symptoms" element={<Symptoms />}>
            <Route index element={<SymptomList />} />
            <Route path=":id/edit" element={<SymptomForm />} />
            <Route path="new" element={<SymptomForm />} />
          </Route>
          <Route path="places" element={<Places />} />
          <Route path="settings" element={<Settings />} />
        </Route>

        <Route path="corporate" element={<Corporate />} />
        <Route path="corporate/new" element={<CorporateForm />} />
        <Route path="corporate/:businessRef" element={<CorporateDetails />} />
        <Route path="corporate/edit/:businessRef" element={<CorporateForm />} />
      </Route>
    </Route>,
  ),
);

function ProtectedRoute() {
  const { session } = useSessionContext();

  if (session.user.roles.includes(role.superAdmin)) return <Outlet />;

  throw new Error("restricted");
}
