import { ExclamationCircle } from "components/oldDesignAssets/icons";

export default function Alert({ className = "", text, button, icon = <ExclamationCircle />, ...props }) {
  return (
    <div className={`alertUM ${className}`} {...props}>
      {icon && <div className="icon">{icon}</div>}
      <div className="text">{text}</div>
      {button && <div className="button">{button}</div>}
    </div>
  );
}
