import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { Grid, Tooltip, Typography } from "@mui/material";
import ProtectedMuiAvatar from "components/global/protectedMuiAvatar";
import { Provider2, X } from "components/oldDesignAssets/icons";
import { endOfDay, format, formatDistanceStrict, formatDistanceToNow } from "date-fns";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { InView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import escape from "regex-escape";
import { deepCloneObject, getInitials } from "utils/globalUtils";
import tryFormatUrl from "utils/tryFormatUrl";
import { getProviders, getRecommendedProviders, getRosterStatusProvider } from "../../../services/dispatcherService";
import { requestStore } from "../../../stores/modalManager";
import { store } from "../../../stores/storeManager";
import { generateFilter } from "../../../utils/generateFilter";
import { providersAvailable, rosterColors } from "../../../utils/globalConfig";
import notify from "../../../utils/notify";
import Loader from "../../global/loader";
import Footer from "../footer";
import Header from "../header";
import { ArrowLeft, RosterVideo } from "../icons";
import Input from "../input";
import SelectMultiple from "../selectMultiple";
import Tag from "../tag";
import Timer from "../timer";
import CreateRequest from "./createRequest";
import EditRequest from "./editRequest";
import Request from "./request";

const origins = {
  create: <CreateRequest />,
  request: <Request />,
  edit: <EditRequest />,
};

export default function ProviderAssignment({ origin }) {
  const {
    country: { alpha2 },
    language,
  } = store.useState(s => s);
  const request = requestStore.useState(s => ({ ...s.request, ...s.unsavedChanges }));
  const { t = () => "" } = useTranslation();

  const [providers, setProviders] = useState(undefined);
  const [recommendedProviders, setRecommendedProviders] = useState(undefined);

  const [providerFilters, setProviderFilters] = useState(
    providersAvailable.map(p => {
      p.selected = request.specialty === p.value;
      return p;
    })
  );

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const hideModal = requestStore.useState(s => s.hideModal);

  const isMedDelivery = request.type === "Delivery" && request.specialty === "Rider";

  const isVisit = request.type === "Visit";

  const serviceTitleShort = t(`${language}.serviceTitleShort`);
  const thirdPartyDelivery = t(`${language}.thirdPartyDelivery`);

  const selectedIds = request.providers?.map(p => p.user?._id);

  const filteredRecommendedProviders = useMemo(() => {
    const specialties = generateFilter(providerFilters);
    return recommendedProviders?.filter(
      p => specialties.includes(p.specialty) && RegExp(escape(search), "gi").test(p.name) && p.status?.active
    );
  }, [providerFilters, recommendedProviders, search]);

  const filteredProviders = useMemo(() => {
    const specialties = generateFilter(providerFilters);
    return providers?.filter(p => specialties.includes(p.specialty));
  }, [providers, providerFilters]);

  const setSelectedProviders = selectedProviders =>
    requestStore.update(s => {
      s.unsavedChanges.providers = selectedProviders;
      s.unsavedChanges.isDirty.providers = true;
    });

  const timeout = useRef();

  useEffect(() => {
    if (!request.schedule.start) {
      notify("Schedule is missing", "error");
      return;
    }
    const fetch = async () => {
      try {
        setIsLoading(true);
        let coordinates = undefined;

        if (request.schedule?.requestedAddress?.coordinates) {
          const { lat, lng } = request.schedule.requestedAddress.coordinates;
          coordinates = `${lat},${lng}`;
        }

        const { data } = await getRecommendedProviders({
          serviceRef: request._id,
          type: request.type,
          specialty: request.specialty,
          scheduleStart: request.schedule?.start,
          scheduleEnd: request.schedule?.end,
          alpha2,
          coordinates,
        });
        setRecommendedProviders(data.results);
      } catch (error) {
        notify(error.data, "error");
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  }, [
    alpha2,
    request._id,
    request.schedule.end,
    request.schedule.requestedAddress.coordinates,
    request.schedule.start,
    request.specialty,
    request.type,
  ]);

  useEffect(() => {
    const getAllProviders = async () => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(async () => {
        try {
          setIsLoading(true);
          const { data } = await getProviders({
            alpha2,
            search,
            page: 1,
            perPage: 50,
            "excludeRefs[]": recommendedProviders.map(p => p._id),
            serviceRef: request._id,
            scheduleStart: request.schedule.start,
            scheduleEnd: request.schedule.end,
            serviceType: request.type,
          });

          setProviders(data.providers);
          setHasNext(data.nextPageAvailable);
        } catch (error) {
          notify(error.data, "error");
        } finally {
          setIsLoading(false);
        }
      }, 500);
    };
    if (recommendedProviders) getAllProviders();
  }, [request._id, request.schedule.start, request.schedule.end, request.type, search, recommendedProviders, alpha2]);

  const onClickBack = () => {
    requestStore.update(s => {
      s.componentsToRender = origins[origin];
    });
  };

  const onClickRemoveProvider = provider => {
    const updatedProviders = deepCloneObject(request.providers.filter(p => p.user._id !== provider.user._id));
    if (provider.owner) {
      const newDefaultOwner = updatedProviders.find(p => p.user.primary[request.type.toLowerCase()]);
      if (newDefaultOwner) newDefaultOwner.owner = true;
    }
    setSelectedProviders(updatedProviders);
  };

  const checkAdhocStatus = useCallback(
    async (provider, providers) => {
      const currProviders = [...providers];
      try {
        const rosterStatus = await getRosterStatusProvider(provider._id, {
          start: request.schedule?.start || new Date(),
          end: request.schedule?.end || endOfDay(new Date()),
        });
        const selectedProviderIndex = currProviders.findIndex(p => p.user._id.toString() === provider._id.toString());
        if (selectedProviderIndex > -1) {
          currProviders[selectedProviderIndex] = {
            ...currProviders[selectedProviderIndex],
            adhoc: Boolean(rosterStatus.data?.isAdHoc),
          };
          setSelectedProviders(currProviders);
        }
      } catch (err) {
        notify(err.data, "error");
      }
    },
    [request.schedule?.end, request.schedule?.start]
  );

  const onClickAddProvider = useCallback(
    async provider => {
      const owner = provider.primary[request.type.toLowerCase()] && !request.providers?.find(p => p.owner);
      const currentProviders = request?.providers || [];
      const updatedProviders = [
        ...currentProviders,
        { user: provider, owner, adhoc: false, primary: provider.primary, userRef: provider._id },
      ];
      setSelectedProviders(updatedProviders);
      checkAdhocStatus(provider, updatedProviders);
    },
    [checkAdhocStatus, request.providers, request.type]
  );

  const onClickAssign = () => {
    if (request.providers?.length) {
      onClickBack();
    }
  };

  const onClickSetAsMain = provider => {
    const updatedProviders = request.providers.map(p => ({ ...p, owner: p.user._id === provider.user._id }));
    setSelectedProviders(updatedProviders);
  };

  const onChangeSearch = search => {
    setSearch(search);
    setPage(1);
  };

  const renderSelectedProviders = () => {
    return request.providers && request.providers.length
      ? request.providers.map(p => {
          const isOwner = p.owner;
          const PrimaryProvider = () => (
            <>
              <div className="fullName">{p.user.name}</div>
              {isOwner ? <div className="primaryInfo">Primary</div> : ""}
            </>
          );
          const providerName = (
            <>
              <PrimaryProvider />
              {!isOwner && p.user.primary[request.type.toLowerCase()] && (
                <Link to="#" onClick={() => onClickSetAsMain(p)} className="setMain">
                  Set as Primary
                </Link>
              )}
            </>
          );
          return (
            <div className="selectedProviderList" key={p.user._id}>
              <div className="provider">
                <ProtectedMuiAvatar
                  alt={p.user.name}
                  children={getInitials(`${p.user.firstName}`, 2)}
                  sx={{
                    width: 34,
                    height: 34,
                    fontSize: 14,
                    color: "white",
                    backgroundColor: "#82BBFE",
                  }}
                  src={tryFormatUrl(p.user.avatar?.url, process.env.REACT_APP_API_REST_ENDPOINT)}
                  available={!!p.user.timeBlock?.type || !!p.user.availability}
                />
                <div style={{ width: "360px" }} className="providerDetail">
                  <div className="name">{providerName}</div>
                  <div className="type">{p.user.company ? thirdPartyDelivery : p.user.specialty}</div>
                </div>
              </div>
              <div className="actionButton">
                {p.adhoc && <div className="adhoc">Adhoc</div>}
                <X onClick={() => onClickRemoveProvider(p)} />
              </div>
            </div>
          );
        })
      : null;
  };

  const renderFilteredRecommendedProviders = () => {
    if (!recommendedProviders && isLoading) {
      return <div style={{ textAlign: "center" }}>Loading recommended providers...</div>;
    }
    if (filteredRecommendedProviders?.length === 0) {
      return <div style={{ textAlign: "center" }}>No recommended provider</div>;
    }

    return filteredRecommendedProviders?.map(p => (
      <ProviderItem provider={p} key={p._id} selectedIds={selectedIds} onClick={onClickAddProvider} isVisit={isVisit} />
    ));
  };

  const renderProviders = () => {
    if (!recommendedProviders) return;
    if (!providers || isLoading) {
      return <div style={{ textAlign: "center" }}>Loading the providers...</div>;
    }
    if (filteredProviders?.length === 0) {
      return <div style={{ textAlign: "center" }}>No provider found</div>;
    }

    if (isMedDelivery) filteredProviders.sort(p => (p.company ? -1 : 1));

    return filteredProviders.map(provider => (
      <ProviderItem provider={provider} key={provider._id} selectedIds={selectedIds} onClick={onClickAddProvider} />
    ));
  };

  const onLoaderInView = async isInView => {
    if (isInView) {
      const nextPage = page + 1;

      setPage(nextPage);
      try {
        const { data } = await getProviders({
          alpha2,
          search,
          "excludeRefs[]": recommendedProviders.map(p => p._id),
          serviceRef: request._id,
          scheduleStart: request.schedule.start,
          scheduleEnd: request.schedule.end,
          serviceType: request.type,
          page: nextPage,
          perPage: 50,
        });
        setProviders([...providers, ...data.providers]);
        setHasNext(data.nextPageAvailable);
      } catch (error) {
        notify(error.data, "error");
      }
    }
  };

  return (
    <div className="modalContent providerAssignment">
      <Header>
        <div className="topHeader">
          <ArrowLeft onClick={onClickBack} role="button" aria-label="backButton" />
          <div className="title">Assign Provider</div>
          <X className="close" onClick={hideModal} />
        </div>

        <div className="middleHeader">
          <div className="serviceType">
            {serviceTitleShort[request.type]} · {request.specialty}
          </div>
          <div>
            {request.schedule.start ? (
              format(new Date(request.schedule.start), "EEEE d MMM · HH:mm")
            ) : request.type === "Video" && request.queuedAt ? (
              <Timer startTime={request.queuedAt} />
            ) : (
              `${format(new Date(request.createdAt), "EEEE d MMM")} · ASAP`
            )}
          </div>
        </div>

        <div>
          <Input
            placeholder="Add provider"
            value={search}
            onChange={onChangeSearch}
            icon={<Provider2 />}
            appendButton={
              <SelectMultiple
                label="Type(s)"
                optionsName="Providers"
                options={providerFilters}
                onChange={setProviderFilters}
                disabled={isMedDelivery}
                hasSelectAll
              />
            }
          />
          <div className="selectedProviders">{renderSelectedProviders()}</div>
          <hr />
        </div>
      </Header>

      <div className="content">
        <div className="providers">
          {!isMedDelivery && (
            <>
              <Grid container className="titlesContainer">
                <Grid item width={!isVisit ? 280 : 190} className="providersTitle">
                  <Tooltip
                    title={
                      <span>
                        Jarvis automatically recommends the best providers for this service based on:
                        <ul>
                          <br />

                          <li>Provider is rostered at this time for this service type.</li>
                          <li>Provider is not already assigned to a service at this time.</li>
                          <li>Provider is not on break at this time.</li>

                          <br />
                        </ul>
                        For service type Home Visits, when assigning within 2 hours of the service time, we calculate
                        the distance to the service address based on the provider's last known location (when
                        available). Closest providers will be displayed first in the list.
                      </span>
                    }
                    placement="top">
                    <span>
                      Recommended Provider
                      <span className="bold">
                        {recommendedProviders ? ` (${filteredRecommendedProviders.length})` : null}
                      </span>
                    </span>
                  </Tooltip>
                </Grid>
                {isVisit && (
                  <Grid item width={90}>
                    Distance & <br /> Travel Time:
                  </Grid>
                )}
                <Grid item width={90}>
                  Rostered <br /> Shift:
                </Grid>
                <Grid item>
                  Rostered <br /> Service:
                </Grid>
              </Grid>

              <div className="providersList">{renderFilteredRecommendedProviders()}</div>

              <br />
            </>
          )}

          <Grid container className="titlesContainer">
            <Grid item width={370} className="providersTitle">
              All Providers <span className="bold">{filteredProviders ? `(${filteredProviders.length})` : ""}</span>:
            </Grid>
            <Grid item className="serviceTitle">
              Provider <br /> Services:
            </Grid>
          </Grid>
          <div className="providersList">
            {renderProviders()}
            {hasNext && !isLoading && (
              <InView as="div" onChange={onLoaderInView}>
                <Loader />
              </InView>
            )}
          </div>
        </div>
      </div>

      <Footer>
        <hr />
        <div className="footerContainer">
          <Link to="#" type="button" className="linkGreen" data-testid="assignProviderBtn" onClick={onClickAssign}>
            Assign Providers
          </Link>
        </div>
      </Footer>
    </div>
  );
}

function ProviderItem({ provider, selectedIds, onClick, isVisit }) {
  const selected = selectedIds?.includes(provider._id);
  const notAvailable = provider.hasOverlappingVideoConsult;
  const { language } = store.useState(s => s);
  const { t = () => "" } = useTranslation();
  const thirdPartyDelivery = t(`${language}.thirdPartyDelivery`);

  const handleAddProvider = useCallback(() => {
    if (!selected && !notAvailable) onClick(provider);
  }, [notAvailable, onClick, provider, selected]);

  return (
    <Fragment key={provider._id}>
      <Tooltip
        placement="bottom"
        disableHoverListener={!notAvailable}
        title="This provider already booked for the same time slot.">
        <Grid
          container
          role="link"
          aria-label={provider.name}
          alignItems="center"
          className={`providerData ${selected ? "selected" : ""} ${notAvailable ? "notAvailable" : ""}`}
          onClick={handleAddProvider}>
          <Grid item container justifyContent="center" width={15}>
            {notAvailable && <RosterVideo width={16} height={10} />}
          </Grid>

          <Grid item xs container alignItems="center" className="providerDetail">
            <ProtectedMuiAvatar
              alt={provider.name}
              children={getInitials(`${provider.firstName}`, 2)}
              sx={{
                width: 34,
                height: 34,
                fontSize: 14,
                color: "white",
                backgroundColor: "#82BBFE",
              }}
              src={tryFormatUrl(provider.avatar?.url, process.env.REACT_APP_API_REST_ENDPOINT)}
              available={!!provider.timeBlock?.type || !!provider.availability}
            />
            <Grid item width={148} className="name" ml={1}>
              <div className="fullName">{provider.name}</div>
              <div className="type">{provider.company ? thirdPartyDelivery : provider.specialty}</div>

              {provider.breaks?.length > 0 &&
                provider.breaks.map(b => (
                  <Fragment key={b._id}>
                    <Tooltip placement="bottom" title={b.note} arrow followCursor>
                      <Typography className="break">
                        Break @ {`${format(new Date(b.start), "HH:mm")} - ${format(new Date(b.end), "HH:mm")}`}
                      </Typography>
                    </Tooltip>
                  </Fragment>
                ))}
            </Grid>
            <Grid item width={90} className="distance">
              {isVisit && provider.roadDistance?.distanceText && (
                <>
                  <div>{provider.roadDistance.distanceText}</div>
                  {provider.roadDistance.duration && (
                    <>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <DirectionsCarIcon sx={{ fontSize: 14, color: "#A5AFB7", marginRight: "4px" }} />
                        {provider.roadDistance.duration
                          .replace("hours", "h")
                          .replace("hour", "h")
                          .replace("mins", "min")}
                      </div>
                      <div style={{ color: "#A5AFB7", marginTop: "4px" }}>
                        <Tooltip
                          title={
                            <div>
                              {`This provider is ${provider.roadDistance.distanceText} away from this service address and ${provider.roadDistance.duration} by car.`}
                              {`This calculation is based on the provider's last known location (updated ${formatDistanceToNow(
                                new Date(provider.roadDistance.coordinatesLastUpdated)
                              )} ago).`}
                            </div>
                          }
                          placement="top">
                          <span>
                            (
                            {formatDistanceStrict(new Date(provider.roadDistance.coordinatesLastUpdated), new Date(), {
                              unit: "minute",
                            })
                              .replace("hours", "h")
                              .replace("minutes", "min")
                              .replace("hour", "h")
                              .replace("minute", "min")}{" "}
                            ago)
                          </span>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </>
              )}
            </Grid>

            <Grid item width={90} color={rosterColors.timeblocks[provider.timeBlock?.tags[0]]?.color}>
              {provider.timeBlock?.start && provider.timeBlock?.end
                ? `${format(new Date(provider.timeBlock.start), "HH:mm")} - ${format(
                    new Date(provider.timeBlock.end),
                    "HH:mm"
                  )}`
                : ""}
            </Grid>

            <Grid item className="serviceDetail">
              {provider.timeBlock?.services?.map(s => <Tag key={s}>{s}</Tag>) ||
                Object.keys(provider.primary).map(k => provider.primary[k] === true && <Tag key={k}>{k}</Tag>)}
            </Grid>
          </Grid>
        </Grid>
      </Tooltip>
    </Fragment>
  );
}
