import React from "react";

export default function GenderToggle({ onChange, disabled = false, text, className = "", value = "M", ...props }) {
  const isMale = value === "M";
  return (
    <div className={`textToggleUM ${className} ${disabled ? "disabled" : ""}`} {...props}>
      {text}
      <div
        className={`switch ${isMale ? "on" : "off"} ${disabled ? "disabled" : ""}`}
        onClick={() => {
          if (!disabled) onChange(isMale ? "F" : "M");
        }}>
        {!isMale && <span className="optionLeft">Male</span>}
        <div className={`toggle-button ${isMale ? "position-left" : "position-right"} ${disabled ? "disabled" : ""}`}>
          <span className="toggleText">{isMale ? "Male" : "Female"}</span>
        </div>
        {isMale && <span className="optionRight">Female</span>}
      </div>
    </div>
  );
}
