import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MenuItem, Select, Typography } from "@mui/material";
import { getEmojiFlag } from "countries-list";
import { store } from "../../stores/storeManager";

const CountrySelector = () => {
  const {
    country: { alpha2 },
    availableCountries
  } = store.useState(s => s);

  const onChangeCountry = event => {
    const country = availableCountries.find(c => c.alpha2 === event.target.value);

    localStorage.setItem("country", country.alpha2);
    store.update(s => {
      s.language = "en";
      s.country = country;
    });
  };

  if (!availableCountries) return null;

  return (
    <Select
      variant="standard"
      value={alpha2}
      onChange={onChangeCountry}
      data-testid="countrySelector"
      renderValue={value => (
        <Typography fontSize={24} lineHeight="unset">
          {getEmojiFlag(value)}
        </Typography>
      )}
      IconComponent={KeyboardArrowDownIcon}
      MenuProps={{
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
        transformOrigin: { vertical: "top", horizontal: "right" }
      }}
      disableUnderline>
      {availableCountries?.map(c => (
        <MenuItem key={c.alpha2} value={c.alpha2} sx={{ fontSize: 14, gap: 1 }}>
          <span>{getEmojiFlag(c.alpha2)}</span>
          <span>{c.name}</span>
        </MenuItem>
      ))}
    </Select>
  );
};

export default CountrySelector;
