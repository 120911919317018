import { forwardRef } from "react";

export default forwardRef(function Button({ text, className = "", type, icon, ...props }, ref) {
  return (
    <button ref={ref} type={type} className={`buttonUM ${className}`} {...props}>
      {text}
      {icon && <span>&nbsp;</span>}
      {icon}
    </button>
  );
});
