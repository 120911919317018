import React from "react";
import useDisableScrollBar from "../../hooks/useDisableScrollBar";

const Modal = ({ show, hide, children, className = "", modalClassName = "", disableScroll = false }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  useDisableScrollBar(disableScroll);

  return (
    <div className={`${showHideClassName} ${modalClassName}`} onClick={e => e.target === e.currentTarget && hide()}>
      <section className={`modal-main ${className}`}>{children}</section>
    </div>
  );
};

export default Modal;
