import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip, Typography } from "@mui/material";

export function PaymentStatusLabel({ status, fallback }) {
  const colors = {
    PAID: { background: "successGreen.500", text: "white" },
    OVERPAID: { background: "warning.main", text: "white" },
    PARTIAL: { background: "warning.main", text: "white" },
    UNPAID: { background: "errorRed.50", text: "error.main" },
  };

  if (!colors[status]) return fallback;

  return (
    <Typography
      component="div"
      variant="caption"
      fontWeight={700}
      sx={{
        py: 0.25,
        px: 0.5,
        backgroundColor: colors[status].background,
        color: colors[status].text,
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        direction: "row",
        justifyContent: "center",
      }}>
      {status === "PARTIAL" && (
        <Tooltip title="You can charge user again to collect any balance payment." placement="top">
          <ErrorIcon
            sx={{
              fontSize: 16,
            }}
          />
        </Tooltip>
      )}
      {status}
    </Typography>
  );
}
