export default function SpeedocLogo(props) {
  return (
    <svg
      width="149"
      height="20"
      viewBox="0 0 149 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="logo"
      {...props}>
      <path
        d="M31.189 14.9537C31.149 14.9337 31.149 14.9137 31.149 14.8938L30.9092 12.435C30.9092 12.375 30.9491 12.3151 30.9891 12.3151C31.0091 12.3151 31.0291 12.3351 31.0291 12.3351C31.6088 12.6749 33.8677 13.7943 37.6058 13.7943C40.0245 13.7943 41.5238 13.1946 41.5238 12.3151C41.5238 11.6954 40.2044 11.1157 36.9461 11.1157C30.8292 11.1157 30.5693 8.55698 30.5693 7.63745C30.5693 4.599 34.2675 3.85938 37.4858 3.85938C40.3644 3.85938 42.3833 4.71894 42.7232 4.87886C42.7631 4.87886 42.7631 4.91884 42.7631 4.93883L42.9231 7.31761C42.943 7.35759 42.8831 7.41756 42.8431 7.41756C42.8231 7.41756 42.8031 7.39758 42.8031 7.39758C42.0435 6.93781 39.9246 6.05826 37.4858 6.05826C33.4679 6.05826 32.7882 6.99778 32.7882 7.63745C32.7882 8.53699 34.3874 8.9168 36.9661 8.9168C39.2849 8.9168 43.8626 9.17667 43.8626 12.395C43.8626 13.1347 43.3428 15.9932 37.6058 15.9932C33.8477 16.0132 31.5488 15.1136 31.189 14.9537Z"
        fill="#404041"
      />
      <path
        d="M101.293 15.7552C101.133 15.7552 101.013 15.6352 101.013 15.4753V4.38096C101.013 4.22104 101.133 4.12109 101.293 4.12109H107.17C111.668 4.12109 114.326 6.41992 114.326 9.93813C114.326 12.7967 112.567 15.7552 107.17 15.7552H101.293ZM103.212 13.2964C103.212 13.4363 103.332 13.5763 103.472 13.5763H107.17C110.748 13.5763 112.128 12.5568 112.128 9.95812C112.128 6.81972 109.009 6.33996 107.17 6.33996H103.492C103.352 6.33996 103.232 6.4599 103.232 6.61982L103.212 13.2964Z"
        fill="#404041"
      />
      <path
        d="M118.245 9.93583C118.245 5.61802 122.582 3.87891 125.501 3.87891C128.639 3.87891 132.777 5.81792 132.777 9.93583C132.777 13.554 129.659 16.0127 125.501 16.0127C122.522 16.0127 118.245 13.8139 118.245 9.93583ZM120.444 9.93583C120.444 12.0747 122.702 13.8139 125.501 13.8139C128.3 13.8139 130.558 12.0747 130.558 9.93583C130.558 7.8169 128.28 6.07779 125.481 6.07779C122.702 6.07779 120.444 7.8169 120.444 9.93583Z"
        fill="#404041"
      />
      <path
        d="M57.8157 4.12109H48.8802C48.8202 4.12109 48.7603 4.18106 48.7603 4.26102V6.14007V6.18005C48.7603 6.24002 48.8202 6.29999 48.9002 6.29999H49.0601H51.0991H51.4589H57.7957C58.5553 6.29999 59.2349 7.07959 59.2349 7.91916C59.2549 8.71875 58.5553 9.37842 57.7757 9.37842H51.4389H51.0791H49.7198H48.8802C48.8202 9.37842 48.7603 9.43839 48.7603 9.51835V10.0981V15.6152C48.7603 15.6752 48.8202 15.7352 48.8802 15.7352H50.8192C50.8792 15.7352 50.9392 15.6752 50.9392 15.6152V11.6972C50.9392 11.6173 50.9791 11.5773 51.0591 11.5773H57.7357C59.7547 11.5773 61.4138 9.97811 61.4338 7.97913C61.4738 5.90019 59.7946 4.12109 57.8157 4.12109Z"
        fill="#404041"
      />
      <path
        d="M78.4452 13.5563H69.2698C69.1699 13.5563 69.0899 13.4563 69.0899 13.3564V11.1575C69.0899 11.0975 69.1299 11.0376 69.2099 11.0376H75.4467C75.5067 11.0376 75.5666 10.9976 75.5666 10.9376L76.1863 8.95863C76.2063 8.89866 76.1463 8.8187 76.0664 8.8187H69.1899C69.1299 8.8187 69.0699 8.75874 69.0699 8.69877V6.49988C69.0699 6.39993 69.1499 6.31997 69.2499 6.31997H76.806H77.8055C77.8455 6.31997 77.9055 6.28 77.9254 6.24002L78.5451 4.26102C78.5851 4.20105 78.5051 4.12109 78.4252 4.12109H76.1863H74.2473H67.051C66.931 4.12109 66.8511 4.20105 66.8511 4.301V15.5953C66.8511 15.6952 66.931 15.7752 67.051 15.7752H77.8055C77.8455 15.7752 77.9055 15.7352 77.9254 15.6952L78.5451 13.7162C78.5851 13.6363 78.5251 13.5563 78.4452 13.5563Z"
        fill="#404041"
      />
      <path
        d="M95.6361 13.5563H86.4608C86.3608 13.5563 86.2809 13.4563 86.2809 13.3564V11.1575C86.2809 11.0975 86.3208 11.0376 86.4008 11.0376H92.6376C92.6976 11.0376 92.7576 10.9976 92.7576 10.9376L93.3772 8.95863C93.3972 8.89866 93.3373 8.8187 93.2573 8.8187H86.3808C86.3208 8.8187 86.2609 8.75874 86.2609 8.69877V6.49988C86.2609 6.39993 86.3408 6.31997 86.4408 6.31997H93.9969H94.9964C95.0364 6.31997 95.0964 6.28 95.1164 6.24002L95.736 4.26102C95.776 4.20105 95.6961 4.12109 95.6161 4.12109H93.3772H91.4382H84.2419C84.122 4.12109 84.042 4.20105 84.042 4.301V15.5953C84.042 15.6952 84.122 15.7752 84.2419 15.7752H94.9964C95.0364 15.7752 95.0964 15.7352 95.1164 15.6952L95.736 13.7162C95.776 13.6363 95.716 13.5563 95.6361 13.5563Z"
        fill="#404041"
      />
      <path
        d="M148.869 13.6137H147.87H147.31H146.53H143.932C140.313 13.6137 138.914 12.5743 138.914 9.9556C138.914 6.77721 142.073 6.29746 143.932 6.29746H146.53H147.31H147.87H148.249C148.289 6.29746 148.349 6.27747 148.369 6.2175L148.989 4.19852C149.029 4.13855 148.949 4.05859 148.869 4.05859H147.59H146.63H145.991H143.932C139.374 4.05859 136.695 6.37742 136.695 9.9556C136.695 12.8541 138.474 15.8526 143.932 15.8526H145.991H147.59H148.909C148.969 15.8526 148.989 15.8326 148.989 15.7726V13.7337C148.989 13.6537 148.949 13.6137 148.869 13.6137Z"
        fill="#404041"
      />
      <path
        d="M24.6129 7.0764H17.5965L20.1752 0.519735C20.2751 0.219888 20.1552 0.0199895 19.8753 0.0199895L14.0183 0C13.7584 0 13.4386 0.239878 13.3186 0.499746L10.74 7.01643L10.72 7.05641H10.62H2.34422C2.14433 7.05641 2.12433 7.33627 2.30424 7.39624L9.72047 8.75554C9.90038 8.79552 9.90038 9.07538 9.70048 9.09537L1.42469 9.59512C1.22479 9.59512 1.2048 9.87497 1.38471 9.93494L8.62103 10.9144L8.8809 10.9544C9.0808 10.9744 9.08079 11.2543 8.90089 11.2943L8.62103 11.3542L0.125352 12.7535C-0.0545563 12.8135 -0.0345668 13.0534 0.145342 13.0733H0.125352H0.165331C0.165331 13.0733 0.165332 13.0733 0.185322 13.0733H8.44112L5.96238 19.3901C5.86243 19.69 5.98237 19.8899 6.26223 19.8899H12.0793C12.3391 19.8899 12.659 19.65 12.7789 19.3901L15.2577 13.0733H22.2741C22.5939 13.0733 22.9138 12.8535 23.0137 12.6136L25.0327 7.49618C25.0927 7.25631 24.9327 7.0764 24.6129 7.0764Z"
        fill="url(#paint0_linear_8983_20264)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8983_20264"
          x1="6.07135"
          y1="1.78173"
          x2="18.1617"
          y2="16.1904"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.2848" stopColor="#00C3B5" />
          <stop offset="1" stopColor="#0097D5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
