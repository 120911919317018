export default function DeliveryServiceIcon(props) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="34" height="34" rx="4" fill="#A8D626" />
      <path
        d="M24.641 11.6293C24.641 10.4479 23.7129 9.4812 22.5785 9.4812H19.4849V11.6293H22.5785V14.4756L18.9899 19.1479H15.3601V13.7775H11.2352C8.95622 13.7775 7.11035 15.7001 7.11035 18.0738V21.296H9.17278C9.17278 23.079 10.5546 24.5182 12.2664 24.5182C13.9782 24.5182 15.3601 23.079 15.3601 21.296H19.9799L24.641 15.2275V11.6293ZM12.2664 22.3701C11.6992 22.3701 11.2352 21.8867 11.2352 21.296H13.2976C13.2976 21.8867 12.8336 22.3701 12.2664 22.3701Z"
        fill="white"
      />
      <path d="M15.3631 10.556H10.207V12.7041H15.3631V10.556Z" fill="white" />
      <path
        d="M24.6425 18.0739C22.9307 18.0739 21.5488 19.5131 21.5488 21.2961C21.5488 23.079 22.9307 24.5183 24.6425 24.5183C26.3543 24.5183 27.7361 23.079 27.7361 21.2961C27.7361 19.5131 26.3543 18.0739 24.6425 18.0739ZM24.6425 22.3702C24.0753 22.3702 23.6113 21.8868 23.6113 21.2961C23.6113 20.7053 24.0753 20.222 24.6425 20.222C25.2096 20.222 25.6737 20.7053 25.6737 21.2961C25.6737 21.8868 25.2096 22.3702 24.6425 22.3702Z"
        fill="white"
      />
    </svg>
  );
}
