import http from "./httpService";
import URI from "urijs";

const baseURL = process.env.REACT_APP_API_REST_ENDPOINT;

export function getTasks(data) {
  const uri = new URI(`${baseURL}/tasks`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function getTask(taskRef) {
  return http.get(`${baseURL}/task/${taskRef}`);
}

export function updateTask(taskRef, data) {
  return http.put(`${baseURL}/task/${taskRef}`, data);
}

export function updateTaskStatus(taskRef, data) {
  return http.put(`${baseURL}/task/${taskRef}/status`, data);
}

export function getActiveBookings(userRef, data) {
  const uri = new URI(`${baseURL}/tasks/activeBookings/${userRef}`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function createTask(data) {
  return http.post(`${baseURL}/task`, data);
}
