import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, FormControl, FormHelperText, Grid, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import {
  CheckGreen,
  CheckGrey,
  Description,
  Folder,
  Location,
  Patient,
  Person,
  Provider2,
  X,
} from "components/oldDesignAssets/icons";
import useUsmsUser from "hooks/useUsmsUser";
import { cloneDeep } from "lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { assignRequest, editBusiness, editJarvisNotes, editPayment, editSchedule } from "services/requestService";
import { requestStore } from "stores/modalManager";
import { store } from "stores/storeManager";
import { getFormattedAddress, getPaymentIcon } from "utils/globalUtils";
import notify from "utils/notify";
import Avatar from "../avatar";
import Button from "../button";
import Footer from "../footer";
import GoogleMapAutocomplete from "../googleMapAutocomplete";
import Header from "../header";
import { ArrowLeft, BluedotDash, Building } from "../icons";
import Input from "../input";
import InputButton from "../inputButton";
import Schedule from "../schedule";
import StatusTag from "../statusTag";
import Tag from "../tag";
import AddressList from "./addressList";
import Cancellation from "./cancellation";
import DeliveryRequest from "./deliveryRequest";
import PaymentMethod from "./paymentMethod";
import ProviderAssignment from "./providerAssignment";

export default function EditDeliveryRequest() {
  const { request, hideModal } = requestStore.useState(s => ({
    request: { ...s.request, ...s.unsavedChanges },
    hideModal: s.hideModal,
  }));

  const { language, country } = store.useState(s => ({
    language: s.language,
    country: s.country,
  }));

  const [isLoading, setIsLoading] = useState(false);

  const { t = () => "" } = useTranslation();
  const serviceStatus = t(`${language}.serviceStatus`);

  const payer = useUsmsUser(request.owner || request.user, country.alpha2);
  const patient = useUsmsUser(request.user, country.alpha2);
  const paymentMethod = request.availablePaymentMethods.find(pm => pm._id === request.billing.preferredMethod);
  const { free } = request.billing;
  const jarvisNote = request.records.find(r => r.type === "jarvisNotes")?.data.jarvisNotes[0].message;

  const caseId = request.case.id ? ` C-${request.case.id}` : " (New)";

  const showRequestScreen = useCallback(() => {
    requestStore.update(s => {
      s.unsavedChanges = {
        isDirty: {},
      };
      s.componentsToRender = <DeliveryRequest />;
    });
  }, []);

  const showProviderAssignment = useCallback(() => {
    requestStore.update(s => {
      s.componentsToRender = <ProviderAssignment origin="edit" />;
    });
  }, []);

  const showCancelPage = useCallback(() => {
    requestStore.update(s => {
      s.componentsToRender = <Cancellation />;
    });
  }, []);

  const updateRequest = updatedFields => {
    requestStore.update(s => {
      for (const field in updatedFields) {
        s.unsavedChanges[field] = updatedFields[field];
      }
    });
  };

  const onChangeSchedule = useCallback(
    updatedFields => {
      updateRequest({
        schedule: { ...request.schedule, ...updatedFields },
        isDirty: { ...request.isDirty, schedule: true },
      });
    },
    [request.isDirty, request.schedule],
  );

  const onChangeAddress = useCallback(
    newAddress => {
      updateRequest({
        schedule: { ...request.schedule, ...{ requestedAddress: newAddress } },
        isDirty: { ...request.isDirty, requestedAddress: true },
      });
    },
    [request.isDirty, request.schedule],
  );

  const onChangeJarvisNote = message => {
    requestStore.update(s => {
      const newRecords = cloneDeep(request.records);
      let record = newRecords.find(r => r.type === "jarvisNotes");
      if (!record) {
        record = { type: "jarvisNotes", data: {} };
        newRecords.push(record);
      }
      record.data.jarvisNotes = [{ message }];
      s.unsavedChanges.records = newRecords;
      s.unsavedChanges.isDirty.jarvisNotes = true;
    });
  };

  const isDirty = () => {
    for (const field in request.isDirty) {
      if (request.isDirty[field]) return true;
    }
    return false;
  };

  const onClickSave = useCallback(async () => {
    try {
      setIsLoading(true);
      let updateResult;
      const messages = [];

      if (request.isDirty.schedule && ["accepted", "assigned", "visitEnd", "completed"].includes(request.status)) {
        updateResult = await editSchedule(request._id, {
          scheduleStart: request.schedule.start,
          scheduleEnd: request.schedule.end,
          services: { status: request.status, plato: request.plato },
        });
        messages.push(updateResult.data.message);
      }

      if (
        request.isDirty.requestedAddress &&
        ["accepted", "assigned", "visitEnd", "completed"].includes(request.status)
      ) {
        updateResult = await editSchedule(request._id, {
          services: { status: request.status, plato: request.plato },
          requestedAddress: request.schedule.requestedAddress,
        });
        messages.push("Requested Address updated");
      }

      if (request.isDirty.address && ["accepted", "assigned", "visitEnd", "completed"].includes(request.status)) {
        updateResult = await editSchedule(request._id, {
          services: { status: request.status, plato: request.plato },
          destinationAddress: !request.isFollowUp ? request.schedule.destinationAddress : undefined,
        });
        messages.push("Destination Address updated");
      }

      if (request.isDirty.providers && ["accepted", "assigned"].includes(request.status)) {
        updateResult = await assignRequest(request._id, {
          providers: request.providers.map(p => ({ userRef: p.user._id, owner: p.owner, adhoc: p.adhoc })),
          scheduleStart: request.schedule.start,
          scheduleEnd: request.schedule.end,
          requestedAddress: request.schedule.requestedAddress,
        });
        messages.push(updateResult.data.message);
      }

      if (request.isDirty.billing) {
        updateResult = await editPayment(request._id, {
          free,
          paymentMethodRef: request.billing.preferredMethod,
          businessRef: paymentMethod?.businessRef,
        });
        messages.push(updateResult.data.message);
      }

      if (
        request.isDirty.business &&
        ["accepted", "assigned", "inTransit", "inProgress", "visitEnd"].includes(request.status)
      ) {
        updateResult = await editBusiness(request._id, {
          businessRef: request.business?._id,
        });
        messages.push(updateResult.data.message);
      }

      if (request.isDirty.jarvisNotes && ["accepted", "assigned", "inTransit"].includes(request.status)) {
        updateResult = await editJarvisNotes(request._id, { jarvisNote });
        messages.push(updateResult.data.message);
      }

      if (updateResult) {
        const service = updateResult.data.service;
        requestStore.update(s => {
          s.request = service;
          s.unsavedChanges = {
            billing: service.billing,
            schedule: service.schedule,
            providers: service.providers,
            isDirty: {},
          };
        });
        if (messages.length) notify(messages.join(", "));
        showRequestScreen();
      }
    } catch (error) {
      notify(error.data, "error");
    } finally {
      setIsLoading(false);
    }
  }, [
    free,
    jarvisNote,
    paymentMethod?.businessRef,
    request._id,
    request.billing.preferredMethod,
    request.business?._id,
    request.isDirty.address,
    request.isDirty.billing,
    request.isDirty.business,
    request.isDirty.jarvisNotes,
    request.isDirty.providers,
    request.isDirty.requestedAddress,
    request.isDirty.schedule,
    request.isFollowUp,
    request.plato,
    request.providers,
    request.schedule.destinationAddress,
    request.schedule.end,
    request.schedule.requestedAddress,
    request.schedule.start,
    request.status,
    showRequestScreen,
  ]);

  const showSubscreen = useCallback(subscreen => {
    requestStore.update(s => {
      s.componentsToRender = subscreen;
    });
  }, []);

  const handleClickAddress = () => {
    if (request.isFollowUp || request.status !== "accepted") return;
    showSubscreen(<AddressList />);
  };

  const onChangeBusiness = e => {
    const selectedBusiness = patient.businesses.find(b => b.ref?.id === e.target.value)?.ref;

    requestStore.update(s => {
      s.unsavedChanges.business = selectedBusiness;
      s.unsavedChanges.isDirty.business = true;
    });
  };

  return (
    <div className="modalContent requestUM">
      <Header>
        <div className="title edit">
          <span className="back">
            <ArrowLeft onClick={showRequestScreen} />
          </span>
          Edit Request
          <span className="subtitle">{`S${request.id}`}</span>
          <Typography variant="caption" color="purple.500" ml={1} mt={0.25}>
            {request.origin}
          </Typography>
        </div>
        <StatusTag className={`${request.status}`}>{serviceStatus[request.status]}</StatusTag>
        <X className="close" onClick={hideModal} />
      </Header>
      <div className="content">
        <div className="provider">
          <div className="providerGroup edit">
            <Provider2 />
            {request.status === "accepted" && !request.providers.length ? (
              <Button text="Assign provider" type="button" className="primary" onClick={showProviderAssignment} />
            ) : (
              <ProviderForm request={request} onClick={showProviderAssignment} />
            )}
          </div>
        </div>
        <div className="scheduleStart">
          <Schedule
            onChange={onChangeSchedule}
            disabled={["inTransit", "inProgress", "discharged", "cancelled"].includes(request.status)}
          />
        </div>
        <div className="scheduleStart">
          <Grid container className="scheduleUM" alignItems="center" gap={0.5}>
            <Grid item xs={0.5} ml={1} mr={0.5}>
              <BluedotDash />
            </Grid>

            <Grid item xs>
              {["accepted", "assigned"].includes(request.status) ? (
                <GoogleMapAutocomplete
                  address={request.schedule.requestedAddress}
                  placeholder="Search by Address"
                  data-testid="searchByAddress"
                  onChange={onChangeAddress}
                  showOneLine
                />
              ) : (
                <Typography variant="caption" mt={0.25}>
                  {getFormattedAddress(request.schedule.requestedAddress)}
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
        <div className="scheduleStart">
          <Grid container className="scheduleUM" alignItems="center" gap={0.25}>
            <Grid item xs={0.5} mr={2}>
              <Location />
            </Grid>

            <Grid
              item
              xs
              onClick={handleClickAddress}
              sx={
                !request.isFollowUp
                  ? {
                      cursor: "pointer",
                      padding: 1,
                      ml: -0.25,
                      "&:hover": { backgroundColor: "jarvisDivider.primary", borderRadius: "6px" },
                    }
                  : undefined
              }>
              <Typography variant="caption" mt={0.25}>
                {getFormattedAddress(request.schedule.destinationAddress)}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className="userSection">
          <Input disabled icon={<Person />} value={payer.name} className="userEdit" />
        </div>
        <InputButton
          className="userBilling edit"
          icon={getPaymentIcon(paymentMethod, request)}
          text={<PaymentMethodText free={free} paymentMethod={paymentMethod} />}
          onClick={() => showSubscreen(<PaymentMethod />)}
          disabled={request.status === "completed"}
        />

        <Grid container px={2} gap={2}>
          <Box display="flex" height={30} alignItems="center">
            <Building />
          </Box>

          <Grid item xs alignSelf="center">
            <FormControl fullWidth>
              <Select
                value={request.business?._id || ""}
                onChange={onChangeBusiness}
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                  elevation: 2,
                  sx: {
                    ".MuiMenuItem-root.Mui-selected": {
                      backgroundColor: "transparent",
                    },
                  },
                  PaperProps: {
                    sx: { mt: 0.5, maxHeight: 280 },
                  },
                }}
                input={
                  <OutlinedInput
                    size="small"
                    color="secondary"
                    sx={{
                      height: "30px",
                      minHeight: "unset",
                      fontSize: "12px",
                      ".MuiOutlinedInput-input": { paddingLeft: "10px" },
                    }}
                    fullWidth
                  />
                }
                disabled={
                  !patient.businesses?.length ||
                  !["accepted", "assigned", "inTransit", "inProgress", "visitEnd"].includes(request.status)
                }
                displayEmpty>
                <MenuItem key="" value="" sx={{ fontSize: "12px" }}>
                  <em>- No Corporate -</em>
                </MenuItem>

                {patient.businesses?.map(b => (
                  <MenuItem key={b.ref?._id} value={b.ref?._id} sx={{ fontSize: "12px" }}>
                    {b.name} [{b.ref?.tag}]
                  </MenuItem>
                ))}
              </Select>

              <FormHelperText sx={{ ml: "10px" }}>Select if appointment is to be tagged to a corporate</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <div className="userSection">
          <Input disabled icon={<Patient />} value={patient.name} className="userEdit" />
        </div>
        <div className="caseType">
          <InputButton
            icon={<Folder />}
            disabled
            text={request.case.type ? `${request.case.program?.title || request.case.type}${caseId}` : ""}
          />
        </div>
        <div>
          <Input
            className="jarvisNotes edit"
            icon={<Description />}
            noBorder
            value={jarvisNote}
            placeholder="Add notes"
            isMultiLine
            counter
            maxLength={1000}
            onChange={onChangeJarvisNote}
            disabled={!["accepted", "assigned", "inTransit"].includes(request.status)}
          />
        </div>
      </div>
      <Footer>
        <hr />
        <Grid p={2}>
          <div className="actions">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs container wrap="nowrap">
                {["accepted", "assigned", "inTransit"].includes(request.status) && (
                  <Link to="#" type="button" className="linkRed" onClick={showCancelPage}>
                    Cancel
                  </Link>
                )}
              </Grid>
              <Grid
                item
                xs={8}
                container
                className="rightActions"
                alignItems="center"
                wrap="nowrap"
                justifyContent="flex-end">
                {request.status !== "cancelled" && (
                  <Button
                    text="Save Changes"
                    type="button"
                    className="primary"
                    disabled={isLoading || !isDirty()}
                    onClick={onClickSave}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Footer>
    </div>
  );
}

function ProviderForm({ request, onClick }) {
  const providerList = request.providers.map(p => {
    const providerCompany = p.user.company && "Third Party Delivery";
    return (
      <div className="providerInfoItem" key={p.user._id}>
        <Avatar userData={p.user} />
        <div className="providerDetail">
          <div className="name">
            <div className="fullName">{p.user.name}</div>
            {p.owner ? <div className="primaryInfo">Primary</div> : ""}
          </div>
          <div className="type">{providerCompany || p.user.specialty}</div>
        </div>
        <div className="seenStatus">{p.seen?.status ? <CheckGreen /> : <CheckGrey />}</div>
      </div>
    );
  });

  return request.status === "accepted" ? (
    <InputButton className="editProvider" text={providerList} onClick={onClick} />
  ) : (
    <div className="providerInfo">{providerList}</div>
  );
}

const PaymentMethodText = ({ free, paymentMethod }) => {
  if (free) return "Free of Charge";
  if (paymentMethod?.origin === "business") {
    return (
      <div className="corporatePaymentMethod">
        <Tag>{paymentMethod.name}</Tag> {paymentMethod.data.name}
      </div>
    );
  }
  return "Select payment method";
};
