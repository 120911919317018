import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MopedIcon from "@mui/icons-material/Moped";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackBar } from "../../../contexts/snackBarContext";
import { getTasks } from "../../../services/requestService";
import { requestStore } from "../../../stores/modalManager";
import { store } from "../../../stores/storeManager";
import Request from "./request";
import { InView } from "react-intersection-observer";
import Loader from "../../global/loader";

export default function TaskList() {
  const request = requestStore.useState(s => s.request);
  const hideModal = requestStore.useState(s => s.hideModal);
  const { language } = store.useState(s => ({
    language: s.language
  }));
  const { t = () => "" } = useTranslation();
  const serviceTitle = t(`${language}.serviceTitleShort`);

  const [tasks, setTasks] = useState([]);
  const [tasksCount, setTasksCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const perPage = 5;

  const snackBar = useSnackBar();

  useEffect(() => {
    let isActive = true;

    (async () => {
      setIsLoading(true);

      try {
        const { data } = await getTasks(request._id, { page: 1, perPage });

        if (isActive) {
          setTasks(data.tasks);
          setTasksCount(data.tasksCount);
          setPage(data.page);
        }
      } catch (error) {
        snackBar.pop({ content: error.toString(), alertProps: { severity: "error", color: "error" } });
      }

      setIsLoading(false);
    })();

    return () => {
      isActive = false;
    };
  }, [request._id, snackBar]);

  const renderTasks = () => {
    if (isLoading)
      return (
        <Grid container justifyContent="center">
          <Loader />
        </Grid>
      );

    return tasks.length > 0 ? (
      tasks.map(task => (
        <Grid item container px={1} pt={1}>
          <MopedIcon htmlColor="#979797" />

          <Grid item xs pl={2}>
            <Grid container alignItems="baseline">
              <Grid item xs>
                <Typography variant="body1" component="span" mr={1} fontWeight={600}>
                  {task.title}
                </Typography>

                <Typography variant="body2" color="#6C6C6C" component="span">
                  T{task.id}
                </Typography>
              </Grid>

              <Typography
                variant="body1"
                color="#778CAD"
                component="span"
                fontWeight={700}
                textTransform="uppercase"
                mr={2}
                sx={{
                  "&.completed": { color: "#2AAAB4" },
                  "&.cancelled": { color: "#E4104F" }
                }}
                className={task.status}>
                {task.status}
              </Typography>
            </Grid>

            <Typography variant="body2" fontWeight={600} pt={1}>
              {task.schedule?.start ? format(new Date(task.schedule?.start), "EEEE d MMM · HH:mm") : "N/A"}
            </Typography>

            <Typography variant="body2" pt={1}>
              {task.records.find(r => r.data.clinicalNotes)?.data?.clinicalNotes?.[0]?.message}
            </Typography>

            <Divider sx={{ marginTop: "16px" }} />
          </Grid>
        </Grid>
      ))
    ) : (
      <Typography px={6} variant="caption">
        No Task Found
      </Typography>
    );
  };

  const onLoaderInView = async isInView => {
    if (isInView) {
      try {
        const { data } = await getTasks(request._id, { page: page + 1, perPage });
        setTasks([...tasks, ...data.tasks]);
        setPage(data.page);
      } catch (error) {
        snackBar.pop({ content: error.toString(), alertProps: { severity: "error", color: "error" } });
      }
    }
  };

  const showRequest = () => {
    requestStore.update(s => {
      s.componentsToRender = <Request />;
    });
  };

  return (
    <div className="modalContent">
      <Grid item container direction="column" p={2} height="100%" overflow="hidden">
        <Grid container alignItems="center" gap={2} pb={1}>
          <IconButton onClick={showRequest}>
            <ArrowBackRoundedIcon htmlColor="#00c3b5" />
          </IconButton>

          <Grid item xs>
            <Typography variant="h6">Task Manager</Typography>
          </Grid>

          <IconButton onClick={hideModal}>
            <CloseRoundedIcon htmlColor="#979797" />
          </IconButton>
        </Grid>

        <Grid container px={1} alignItems="baseline">
          <Grid item xs>
            <Typography variant="h6" color="#3EBEBC" component="span" mr={1}>
              {serviceTitle[request.type]}
            </Typography>
            <Typography variant="body2" color="#6C6C6C" component="span">
              S{request.id}
            </Typography>
          </Grid>

          <Typography variant="body2">
            {request.schedule?.start ? format(new Date(request.schedule?.start), "EEEE d MMM · HH:mm") : "N/A"}
          </Typography>
        </Grid>

        <Grid px={1} pb={2}>
          <Typography variant="body2">{request.specialty}</Typography>
        </Grid>

        <Grid container p={1} alignItems="center">
          <AccountCircleIcon htmlColor="#979797" />

          <Typography ml={2} variant="body2" fontWeight={600}>
            {request.user.name}
          </Typography>
        </Grid>

        <Divider sx={{ margin: "8px -16px" }} />

        <Grid container px={6} py={1}>
          <Typography variant="caption">Tasks List ({tasksCount})</Typography>
        </Grid>

        <Grid item container xs direction="column" gap={2} overflow="auto" wrap="nowrap">
          {renderTasks()}
          {page * perPage < tasksCount && !isLoading && (
            <InView as="div" onChange={onLoaderInView}>
              <Grid container justifyContent="center">
                <Loader />
              </Grid>
            </InView>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
