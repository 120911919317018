import { Paper } from "@mui/material";

export default function CustomPaper({ children }) {
  return (
    <Paper
      elevation={8}
      sx={{
        marginTop: 0.5,
        py: 0.5,
        ".MuiAutocomplete-listbox": {
          ".MuiAutocomplete-option": {
            "&.Mui-focused": { backgroundColor: "jarvisDivider.primary" },
            "&:hover": { backgroundColor: "jarvisDivider.primary" },
          },
          // eslint-disable-next-line quotes
          '.MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: "white",
          },
          // eslint-disable-next-line quotes
          '.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: "white",
            "&:hover": { backgroundColor: "jarvisDivider.primary" },
          },
        },
      }}>
      {children}
    </Paper>
  );
}
