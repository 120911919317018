import { Store } from "pullstate";
import { getUserCases } from "services/userService";

export const caseStore = new Store({
  cases: [],
  loading: false,
  error: null,
});

export const setCases = cases => {
  caseStore.update(s => {
    s.cases = cases;
  });
};

const setLoading = loading => {
  caseStore.update(s => {
    s.loading = loading;
  });
};

const setError = error => {
  caseStore.update(s => {
    s.error = error;
  });
};

export const fetchCases = async (id, alpha2) => {
  setLoading(true);

  try {
    const { data } = await getUserCases(id, { alpha2 });

    setCases(data.cases);
  } catch (error) {
    setCases([]);
    setError(error.data);
  } finally {
    setLoading(false);
  }
};

const sortServicesBasedOnScheduleStart = (a, b) =>
  !a.ref.schedule.start ? -1 : new Date(b.ref.schedule.start).getTime() - new Date(a.ref.schedule.start).getTime();

const removeFromCollection = (patientCases, incomingService, dataType) => {
  const existingCaseIndex = patientCases.findIndex(c => c._id === incomingService.case._id);
  if (existingCaseIndex === -1) return;

  if (dataType === "draft") {
    patientCases[existingCaseIndex].drafts = patientCases[existingCaseIndex].drafts.filter(
      d => d.ref._id !== incomingService._id
    );
  } else {
    patientCases[existingCaseIndex].services = patientCases[existingCaseIndex].services.filter(
      s => s.ref._id !== incomingService._id
    );
  }
};

const removeEmptyCollections = patientCases => {
  for (const idx in patientCases) {
    if (!patientCases[idx].services.length && !patientCases[idx].drafts.length) patientCases.splice(idx, 1);
  }
};

const upsertCollection = (patientCases, incomingService, dataType) => {
  const {
    case: { _id: caseId, type: caseType, createdAt: caseCreatedAt },
    _id: serviceId,
    type,
    providers,
    status,
    id,
    schedule,
    updatedAt,
  } = incomingService;

  const existingCaseIndex = patientCases.findIndex(p => p._id === caseId);

  const constructServiceObject = {
    ref: {
      id,
      type,
      status,
      providers,
      _id: serviceId,
      schedule: schedule,
      updatedAt,
    },
    type: caseType,
    addedAt: caseCreatedAt,
  };

  if (existingCaseIndex >= 0) {
    if (dataType === "draft") {
      patientCases[existingCaseIndex].drafts.push(constructServiceObject);
      patientCases[existingCaseIndex].drafts.sort(sortServicesBasedOnScheduleStart);
    } else {
      patientCases[existingCaseIndex].services.push(constructServiceObject);
      patientCases[existingCaseIndex].services.sort(sortServicesBasedOnScheduleStart);
    }
  } else {
    const newCase = { ...incomingService.case };
    if (dataType === "draft") {
      newCase.drafts = [constructServiceObject];
    } else {
      newCase.services = [constructServiceObject];
    }
    patientCases.unshift(newCase);
  }
};

const isNewUpdate = (patientCases, incomingService, dataType) => {
  const existingCase = patientCases.find(c => c._id === incomingService.case._id);
  if (!existingCase) return true;

  let existingItem;

  if (dataType === "draft") {
    existingItem = existingCase.drafts.find(d => d.ref._id === incomingService._id);
  } else {
    existingItem = existingCase.services.find(s => s.ref._id === incomingService._id);
  }
  if (!existingItem?.ref?.updatedAt) return true;

  const currentUpdatedAt = existingItem.ref.updatedAt;

  return new Date(currentUpdatedAt) < new Date(incomingService.updatedAt);
};

export const updatePatientCases = (data, alpha2, dataType = "service") => {
  const { service: incomingService } = data;

  if (!incomingService) return;
  if (incomingService.country !== alpha2) return;

  caseStore.update(s => {
    if (!isNewUpdate(s.cases, incomingService, dataType)) return;

    removeFromCollection(s.cases, incomingService, dataType);
    upsertCollection(s.cases, incomingService, dataType);
    removeEmptyCollections(s.cases);
  });
};
