import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import httpService from "../../services/httpService";
import PulseBadge from "./pulseBadge";

export default function ProtectedMuiAvatar({ src = "", available, ...props }) {
  const [protectedImage, setProtectedImage] = useState();

  const width = props.sx?.width || 32;
  const height = props.sx?.height || 32;

  useEffect(() => {
    if (!src) return;

    let active = true;

    const fetchImage = async () => {
      try {
        const imageResponse = await httpService.get(src, { responseType: "blob" });
        if (active && imageResponse?.status === 200) setProtectedImage(URL.createObjectURL(imageResponse.data));
      } catch (err) {
        console.error(err);
      }
    };
    fetchImage();

    return () => {
      active = false;
    };
  }, [src]);

  return (
    <PulseBadge available={available} width={width} height={height}>
      <Avatar src={protectedImage} {...props} />
    </PulseBadge>
  );
}
