import { useEffect, useState } from "react";
import http from "../services/httpService";
export default function useUsmsUser(user, alpha2) {
  const [usmsUser, setUsmsUser] = useState(user);
  useEffect(() => {
    if (!user?._id) return;
    const fetch = async () => {
      const { data } = await http.get(`${process.env.REACT_APP_API_USMS_ENDPOINT}/user/${user._id}?alpha2=${alpha2}`);

      setUsmsUser(data.user);
    };
    fetch();
  }, [user, alpha2]);

  return usmsUser;
}
