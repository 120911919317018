export const getAvailablePaymentMethods = ({ country, user, owner }) => {
  const paymentMethods = [];
  if (country.paymentMethods) {
    country.paymentMethods.forEach(pm => {
      if (pm.isActive) paymentMethods.push({ ...pm, origin: "country" });
    });
  }

  const payer = owner || user;
  if (payer?._id) {
    payer.paymentMethods
      .filter(pm => pm.isActive)
      .forEach(pm => {
        paymentMethods.push({ ...pm, origin: "user" });
      });
  }

  const combinedBusinesses = owner?.businesses?.map(b => ({ ...b.ref, ownerName: owner.firstName })) || [];

  if (owner?._id !== user?._id) {
    const userBusiness = user?.businesses?.map(b => ({ ...b.ref, ownerName: user.firstName })) || [];
    combinedBusinesses.push(...userBusiness);
  }

  combinedBusinesses
    .filter(b => b.country.alpha2 === country.alpha2)
    .forEach(b => {
      const activePaymentMethods = b.billing.paymentMethods.filter(pm => pm.isActive);
      paymentMethods.push(
        ...activePaymentMethods.map(pm => ({
          ...pm,
          businessRef: b._id,
          origin: "business",
          name: b.tag,
          ownerName: b.ownerName,
        })),
      );
    });

  return paymentMethods;
};
