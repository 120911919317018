import { Badge } from "@mui/material";

export default function PulseBadge({ available, width = 34, height = 34, children, ...props }) {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant={available ? "dot" : undefined}
      sx={{
        width,
        height,
        "& .MuiBadge-badge": {
          backgroundColor: "newEmerald.500",
          color: "newEmerald.500",
          boxShadow: "0 0 0 2px white",
          height: "6px",
          minWidth: "6px",
          width: "6px",
          "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            // eslint-disable-next-line quotes
            content: '""',
          },
        },
        "@keyframes ripple": {
          "0%": {
            transform: "scale(.8)",
            opacity: 1,
          },
          "100%": {
            transform: "scale(2.4)",
            opacity: 0,
          },
        },
      }}
      {...props}>
      {children}
    </Badge>
  );
}
