import React from "react";
import Loader from "../components/global/loader";

export default function LoadingSection({ className = "", children }) {
  return (
    <div id="loadingPage" className={className}>
      <Loader size={30} />
      <div id="loadingPageContent">{children || "Loading..."}</div>
    </div>
  );
}
