import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { requestStore } from "../../../stores/modalManager";
import NotificationModal from "../notificationModal";
import CreateRequest from "./createRequest";

const intervalUnit = ["minutes", "hours", "days", "weeks", "months", "years"];

export default function RecurringAppointment() {
  const { recurring } = requestStore.useState(s => ({ ...s.request, ...s.unsavedChanges }));

  const showCreateRequest = () => {
    requestStore.update(s => {
      s.componentsToRender = <CreateRequest />;
    });
  };

  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      numberOfAppointments: recurring?.numberOfAppointments || "",
      interval: recurring?.interval || "",
      unit: recurring?.unit || "",
    },
  });

  const onClickBack = () => {
    if (isDirty) {
      NotificationModal({
        callback: () => showCreateRequest(),
        action: "unSavedBack",
      });
    } else showCreateRequest();
  };

  const onClickClear = () => {
    setValue("numberOfAppointments", "", { shouldDirty: true });
    setValue("interval", "", { shouldDirty: true });
    setValue("unit", "", { shouldDirty: true });
  };

  const onClickApply = data => {
    requestStore.update(s => {
      s.unsavedChanges.recurring = data.numberOfAppointments ? data : undefined;
    });
    showCreateRequest();
  };

  return (
    <div className="modalContent">
      <Grid item container direction="column" p={2} gap={2} height="100%" overflow="hidden">
        <Grid item container alignItems="center" gap={2} pb={1}>
          <IconButton onClick={onClickBack} edge="start">
            <ArrowBackRoundedIcon htmlColor="#00c3b5" />
          </IconButton>

          <Grid item xs>
            <Typography fontSize={18} fontFamily="PlutoSans">
              Recurring Appointment
            </Typography>
          </Grid>
        </Grid>
        <TextField
          {...register("numberOfAppointments", {
            min: 2,
            max: 20,
            validate: v => !(!v && (getValues("interval") || getValues("unit"))),
          })}
          type="number"
          error={!!errors.numberOfAppointments}
          placeholder="Number of appointments"
          helperText="Number of appointment to be made (max 20 appointments)"
          size="small"
          inputProps={{ autoComplete: "off" }}
        />
        <Grid item container justifyContent="space-between" wrap="nowrap" gap={2}>
          <TextField
            {...register("interval", {
              min: 1,
              max: 99,
              validate: v => !(!v && (getValues("numberOfAppointments") || getValues("unit"))),
            })}
            type="number"
            error={!!errors.interval}
            placeholder="Enter interval"
            helperText="Interval (max 2 digits)"
            size="small"
            inputProps={{ autoComplete: "off" }}
            fullWidth
          />
          <Controller
            name="unit"
            control={control}
            rules={{
              validate: v => !(!v && (getValues("numberOfAppointments") || getValues("interval"))),
            }}
            render={({ field: { onChange, value } }) => (
              <FormControl size="small" fullWidth>
                <Select
                  value={value}
                  MenuProps={{
                    sx: {
                      ".MuiList-root": {
                        ".MuiMenuItem-root": {
                          "&.Mui-focused": { backgroundColor: "#F7F7F8" },
                          "&:hover": { backgroundColor: "#F7F7F8" },
                        },
                        ".Mui-selected": {
                          backgroundColor: "jarvisDivider.primary",
                        },
                      },
                    },
                  }}
                  onChange={onChange}
                  IconComponent={ExpandMoreIcon}
                  error={!!errors.unit}
                  displayEmpty>
                  <MenuItem key="" value="">
                    <Typography variant="body2" color="text.secondary">
                      Select Unit
                    </Typography>
                  </MenuItem>

                  {intervalUnit.map(unit => (
                    <MenuItem key={unit} value={unit}>
                      <Typography variant="body2" textTransform="capitalize">
                        {unit}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Unit selection</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item container justifyContent="space-between" mt="auto">
          <Button variant="text" color="secondary" onClick={onClickClear}>
            Clear
          </Button>
          <Button variant="contained" color="secondary" onClick={handleSubmit(onClickApply)}>
            Apply
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
