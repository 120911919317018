import { datadogRum } from "@datadog/browser-rum";
import { createRoot } from "react-dom/client";
import app from "../package.json";
import App from "./App";
import "./utils/i18n";

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APP_ID || "",
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
  site: "datadoghq.com",
  service: "jarvis",
  env: process.env.REACT_APP_BUILD_ENV,
  version: app.version,
  trackUserInteractions: true,
  allowedTracingUrls: [process.env.REACT_APP_API_REST_ENDPOINT || ""],
});

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
