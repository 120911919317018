import React, { useEffect, useState } from "react";
import httpService from "../../services/httpService";

export default function ProtectedImage({ src, alt = "", defaultImage, ...props }) {
  const [image, setImage] = useState(defaultImage);

  useEffect(() => {
    if (src) {
      const fetchImage = async () => {
        try {
          const imageResponse = await httpService.get(src, { responseType: "blob" });
          setImage(URL.createObjectURL(imageResponse.data));
        } catch (error) {
          setImage(defaultImage);
        }
      };
      fetchImage();
    } else {
      setImage(defaultImage);
    }
  }, [src, defaultImage]);

  return image ? <img src={image} alt={alt} {...props} onError={() => setImage(defaultImage)} /> : null;
}
