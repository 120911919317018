import Header from "components/universalModal/header";
import { requestStore } from "../../../stores/modalManager";
import Request from "components/universalModal/pages/request";
import { ArrowLeft } from "components/universalModal/icons";
import GoogleMapReact from "google-map-react";

const Tracking = () => {
  const { request } = requestStore.useState(s => ({ request: { ...s.request, ...s.unsavedChanges } }));
  const mainProvider = request.providers.find(p => p.owner);
  const { destinationAddress } = request.schedule;

  const showRequest = () => {
    requestStore.update(s => {
      s.componentsToRender = <Request />;
    });
  };
  if (!mainProvider.itinerary.lastPosition || !destinationAddress.coordinates) return null;
  return (
    <div className="modalContent">
      <Header>
        <ArrowLeft onClick={showRequest} />
        <div className="title">Medicine Delivery Tracking</div>
      </Header>
      <div className="content trackingMap">
        <GoogleMapReact
          options={{
            panControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            rotateControl: false,
          }}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_KEY, id: "google-map-react" }}
          center={{ lat: 1.31863, lng: 103.861873 }}
          zoom={12}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            const directionsService = new maps.DirectionsService();

            let directionsDisplay = new maps.DirectionsRenderer({
              suppressMarkers: true,
              suppressBicyclingLayer: true,
            });

            directionsDisplay.setOptions({
              polylineOptions: {
                strokeColor: "#006600",
                strokeWeight: "5",
                strokeOpacity: "1",
              },
            });

            directionsDisplay.setMap(map);
            const request = {
              origin: `${mainProvider.itinerary.lastPosition.lat},${mainProvider.itinerary.lastPosition.lng}`,
              destination: `${destinationAddress.coordinates.lat},${destinationAddress.coordinates.lng}`,
              optimizeWaypoints: true,
              travelMode: "DRIVING",
            };
            directionsService.route(request, (result, status) => {
              if (status === "OK") {
                directionsDisplay.setDirections(result);
              }
            });
            new maps.Marker({
              position: destinationAddress.coordinates,
              map,
            });
          }}
        />
      </div>
    </div>
  );
};

export default Tracking;
