export default function tryFormatUrl(urlToCheck, baseUrl) {
  if (!urlToCheck) return undefined;

  try {
    const validUrl = new URL(urlToCheck, baseUrl);

    replaceHostnameByEnvironment(validUrl, baseUrl);

    return validUrl.toString();
  } catch (error) {
    return undefined;
  }
}

const replaceHostnameByEnvironment = (url, baseUrl) => {
  url.host = new URL(baseUrl).host;
  if (url.hostname === "localhost") url.protocol = "http";
};
