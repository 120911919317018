import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { ClickAwayListener, Grid, Grow, Paper, Popper, Stack } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import Button from "./button";
import { ChevronDown } from "./icons";

export default function OptionButton({ children, disabled, label, isUnstyled, margin }) {
  const anchorRef = useRef();
  const [showCloseDropDown, setShowCloseDropDown] = useState(false);

  const handleClose = useCallback(event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setShowCloseDropDown(false);
  }, []);

  const toggleCloseDropDown = useCallback(() => {
    setShowCloseDropDown(x => !x);
  }, []);

  return (
    <>
      {isUnstyled && (
        <Stack direction="row" alignItems="center" sx={{ cursor: "pointer" }} onClick={toggleCloseDropDown}>
          <h2 ref={anchorRef}>{label}</h2>
          <ArrowDropDownOutlinedIcon htmlColor="rgba(0, 0, 0, 0.54)" />
        </Stack>
      )}
      {!isUnstyled && (
        <Button
          ref={anchorRef}
          text={
            <Grid container gap={2}>
              <Grid item>{label ?? "Actions"}</Grid>
              <Grid item>
                <ChevronDown />
              </Grid>
            </Grid>
          }
          type="button"
          className="primary"
          disabled={disabled}
          onClick={toggleCloseDropDown}
        />
      )}
      <Popper
        open={showCloseDropDown}
        anchorEl={anchorRef.current}
        role="document"
        placement="bottom-end"
        transition
        disablePortal>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              margin: margin ?? "5px 0",
              borderRadius: 5,
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>{children}</ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
