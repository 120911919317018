import Button from "components/oldDesignAssets/button";
import Input from "components/oldDesignAssets/input";
import { useEffect, useState } from "react";

export default function PrivateLogin(props) {
  const isProduction = process.env.REACT_APP_BUILD_ENV === "production";
  const isLocalDev = process.env.NODE_ENV === "development";
  const [isOpen, setIsOpen] = useState(isProduction || isLocalDev);
  const [passKey, setPassKey] = useState(localStorage.getItem("speedoc-private-login-pwd"));
  const [feedbackMessage, setFeedbackMessage] = useState("");

  useEffect(() => {
    if (isProduction || isLocalDev) return;
    localStorage.setItem("speedoc-private-login-pwd", passKey);
    setFeedbackMessage("");
    // This should be compared by a secure API.
    if (passKey === process.env.REACT_APP_LOGIN_KEY) return setIsOpen(true);
    else if (passKey) setFeedbackMessage("Invalid password...");
    setIsOpen(false);
  }, [passKey, isProduction, isLocalDev]);

  const handlePasswordSubmit = e => {
    setPassKey(e.target.password.value);
    e.preventDefault();
  };

  if (isLocalDev || isProduction || isOpen) return props.children;
  return (
    <div id="privateLogin">
      <form onSubmit={handlePasswordSubmit}>
        <Input type="password" name="password" placeholder="Speedoc password" autoComplete="off" />
        <Button type="submit" aria-label="private login submit button" className="primary">
          <span>Enter</span>
        </Button>
      </form>
      {feedbackMessage ? <p>{feedbackMessage}</p> : null}
    </div>
  );
}
