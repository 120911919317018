import { TextareaAutosize } from "@mui/material";
import React from "react";
import { ChevronDown } from "./icons";

export default function Input({
  onChange = () => {},
  id,
  className = "",
  placeholder,
  type = "text",
  icon,
  text,
  info,
  noBorder,
  list,
  select,
  onClick = () => {},
  showInfo,
  isMultiLine,
  autoSize,
  minRows = 1,
  maxRows = 5,
  maxLength,
  counter,
  value = "",
  autoFocus,
  editInfo,
  appendButton,
  ...props
}) {
  return (
    <div className={`inputUM ${className} ${noBorder ? "noBorder" : ""}`} id={id}>
      {icon && <div className="icon">{icon}</div>}
      <div className="inputGroup">
        {text && <div className="text">{text}</div>}
        <div className="inputContainer">
          {isMultiLine ? (
            autoSize ? (
              <TextareaAutosize
                minRows={minRows}
                maxRows={maxRows}
                onClick={onClick}
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={e => onChange(e.target.value)}
                maxLength={maxLength}
                {...props}
              />
            ) : (
              <textarea
                onClick={onClick}
                type={type}
                value={value}
                rows="5"
                placeholder={placeholder}
                onChange={e => onChange(e.target.value)}
                maxLength={maxLength}
                {...props}
              />
            )
          ) : (
            <input
              onClick={onClick}
              autoFocus={autoFocus}
              type={type}
              value={value}
              placeholder={placeholder}
              onChange={e => onChange(e.target.value)}
              maxLength={maxLength}
              data-testid="inputField"
              {...props}
            />
          )}
          {counter && (
            <span className="inputCounter">
              {value.length}/{maxLength}
            </span>
          )}
          {showInfo && <span className={`inputInfo ${select ? "withSelect" : ""}`}>{info}</span>}
          {editInfo}
          {select && (
            <span className="selectIcon">
              <ChevronDown />
            </span>
          )}
          {list}
          {appendButton}
        </div>
      </div>
    </div>
  );
}
