export default function CorporateIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.19247 7.68924C4.19247 7.35786 4.4611 7.08924 4.79247 7.08924H8.51649C8.84787 7.08924 9.11649 7.35786 9.11649 7.68924C9.11649 8.02061 8.84787 8.28924 8.51649 8.28924H4.79247C4.4611 8.28924 4.19247 8.02061 4.19247 7.68924Z"
        fill="#9714E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.19247 4.77469C4.19247 4.44331 4.4611 4.17469 4.79247 4.17469H8.51649C8.84787 4.17469 9.11649 4.44331 9.11649 4.77469C9.11649 5.10606 8.84787 5.37469 8.51649 5.37469H4.79247C4.4611 5.37469 4.19247 5.10606 4.19247 4.77469Z"
        fill="#9714E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.93167 1.99761C1.93167 1.66624 2.2003 1.39761 2.53167 1.39761H10.7775C11.1088 1.39761 11.3775 1.66624 11.3775 1.99761V16.2182C11.3775 16.5495 11.1088 16.8182 10.7775 16.8182H2.53167C2.2003 16.8182 1.93167 16.5495 1.93167 16.2182V1.99761ZM3.13167 2.59761V15.6182H10.1775V2.59761H3.13167Z"
        fill="#9714E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.21786 10.4936C4.21786 10.1622 4.48649 9.8936 4.81787 9.8936H8.49094C8.82232 9.8936 9.09095 10.1622 9.09095 10.4936V16.2183C9.09095 16.5497 8.82232 16.8183 8.49094 16.8183H4.81787C4.48649 16.8183 4.21786 16.5497 4.21786 16.2183V10.4936ZM5.41787 11.0936V15.6183H7.89094V11.0936H5.41787Z"
        fill="#9714E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3533 5.34526C10.4658 5.23274 10.6184 5.16953 10.7775 5.16953C12.2762 5.16958 13.7135 5.76498 14.7733 6.82474C15.833 7.88449 16.4283 9.32181 16.4283 10.8205V16.218C16.4283 16.5494 16.1597 16.818 15.8283 16.818H10.7775C10.4461 16.818 10.1775 16.5494 10.1775 16.218V5.76953C10.1775 5.6104 10.2407 5.45778 10.3533 5.34526ZM11.3775 6.41016V15.618H15.2283V10.8205C15.2283 9.64007 14.7594 8.50797 13.9247 7.67325C13.2327 6.98126 12.3364 6.54063 11.3775 6.41016Z"
        fill="#9714E7"
      />
    </svg>
  );
}
