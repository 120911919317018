export default function HospitalIcon(props) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 12V4.7C0 4.4 0.2 4.2 0.5 4.2H3V0.5C3 0.2 3.2 0 3.5 0H8.5C8.8 0 9 0.2 9 0.5V4.2H11.5C11.8 4.2 12 4.4 12 4.7V12H7.2V9H4.8V12H0ZM5.4 2.4C5 2.4 4.6 2.4 4.2 2.4C4.2 2.8 4.2 3.2 4.2 3.6C4.6 3.6 5 3.6 5.4 3.6C5.4 4 5.4 4.4 5.4 4.8C5.8 4.8 6.2 4.8 6.6 4.8C6.6 4.4 6.6 4 6.6 3.6C7 3.6 7.4 3.6 7.8 3.6C7.8 3.2 7.8 2.8 7.8 2.4C7.4 2.4 7 2.4 6.6 2.4C6.6 2 6.6 1.6 6.6 1.2C6.2 1.2 5.8 1.2 5.4 1.2C5.4 1.6 5.4 2 5.4 2.4ZM1.2 6.6C1.6 6.6 2 6.6 2.4 6.6C2.4 6.2 2.4 5.8 2.4 5.4C2 5.4 1.6 5.4 1.2 5.4C1.2 5.8 1.2 6.2 1.2 6.6ZM3.6 6.6C4 6.6 4.4 6.6 4.8 6.6C4.8 6.2 4.8 5.8 4.8 5.4C4.4 5.4 4 5.4 3.6 5.4C3.6 5.8 3.6 6.2 3.6 6.6ZM8.4 6.6C8.4 6.2 8.4 5.8 8.4 5.4C8 5.4 7.6 5.4 7.2 5.4C7.2 5.8 7.2 6.2 7.2 6.6C7.6 6.6 8 6.6 8.4 6.6ZM9.6 6.6C10 6.6 10.4 6.6 10.8 6.6C10.8 6.2 10.8 5.8 10.8 5.4C10.4 5.4 10 5.4 9.6 5.4C9.6 5.8 9.6 6.2 9.6 6.6ZM1.2 7.8C1.2 8.2 1.2 8.6 1.2 9C1.6 9 2 9 2.4 9C2.4 8.6 2.4 8.2 2.4 7.8C2 7.8 1.6 7.8 1.2 7.8ZM10.8 7.8C10.4 7.8 10 7.8 9.6 7.8C9.6 8.2 9.6 8.6 9.6 9C10 9 10.4 9 10.8 9C10.8 8.6 10.8 8.2 10.8 7.8Z"
        fill="#B4C0DE"
      />
    </svg>
  );
}
