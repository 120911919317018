import { default as MUITooltip } from "@mui/material/Tooltip";
import { ExclamationCircle } from "components/oldDesignAssets/icons";

export default function Tooltip({ icon, text, dataPlace, tooltipProps = {} }) {
  return (
    <MUITooltip arrow title={text} role="tooltip" followCursor placement={dataPlace} {...tooltipProps}>
      <div>{icon || <ExclamationCircle />}</div>
    </MUITooltip>
  );
}
