import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { uploadMedia } from "../../services/requestService";
import { requestStore } from "../../stores/modalManager";
import notify from "../../utils/notify";
import Loader from "../global/loader";
import { Attachment } from "./icons";
import MediaAttachment from "./mediaAttachment";
import PdfAttachment from "./pdfAttachment";

export default function MediaUploader() {
  const request = requestStore.useState(s => ({ ...s.request, ...s.unsavedChanges }));
  const mediaRecord = request.records.find(r => r.type === "media");
  const paymentMedias = request.records.filter(r => r.type === "paymentMedia");

  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState("");

  const uploadFile = async e => {
    const file = e.target.files[0];
    if (!file) return;
    else if (file.size > 5000000) setUploadError("Maximum file size allowed is 5mb.");
    else if (mediaRecord && mediaRecord.data.media.length >= 6) setUploadError("Maximum 6 files allowed.");
    else {
      const formData = new FormData();
      formData.append("file", file);
      try {
        setIsLoading(true);
        const { data } = await uploadMedia(request._id, formData);
        requestStore.update(s => {
          s.request = data.service;
        });
      } catch (error) {
        notify(error.data, "error");
      } finally {
        setIsLoading(false);
        setUploadError("");
      }
    }
  };

  return (
    <div className="mediaUploader">
      <Attachment className="icon" />
      <Grid>
        {paymentMedias.length > 0 && (
          <Grid mb={3}>
            <Typography fontSize={12} fontWeight={500} color="text.secondary">
              Proof of Payment
            </Typography>
            <div className="attachments">
              {paymentMedias
                .map(r => r.data.media)
                .flat()
                .map(media => (
                  <MediaAttachment key={media?.data?.fileName} media={media} isPayment />
                ))}
            </div>
          </Grid>
        )}
        {mediaRecord && (
          <Grid mb={3}>
            <Typography fontSize={12} fontWeight={500} color="text.secondary">
              Attachment(s)
            </Typography>
            <div className="attachments">
              <>
                {mediaRecord?.data?.media?.map(media => {
                  const Media = media.mimetype === "application/pdf" ? PdfAttachment : MediaAttachment;
                  return <Media key={media?.data?.fileName} media={media} />;
                })}
                {isLoading ? <Loader /> : null}
              </>
            </div>
          </Grid>
        )}
        <div className="inputGroup">
          <label htmlFor="file-upload" className="placeholder">
            Add attachments
          </label>
          <span className="error" hidden={!uploadError.length}>
            {uploadError}
          </span>
        </div>
        <input hidden disabled={isLoading} id="file-upload" type="file" onChange={uploadFile} />
      </Grid>
    </div>
  );
}
