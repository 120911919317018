import React from "react";

const Button = props => {
  return (
    <button className={props.className || "primary"} type={props.type || "button"} {...props}>
      {props.children}
    </button>
  );
};

export default Button;
