import React, { useState, useRef } from "react";

const Input = ({ id, validationClassName = "", className = "", ...props }) => {
  const [validationClass, setValidationClass] = useState(validationClassName);
  const refWrapper = useRef();

  //Remove warning or error border color on focus
  const onFocus = () => {
    if (validationClassName === "error" || validationClassName === "warning") setValidationClass("");
  };

  return (
    <div
      className={validationClass ? `input ${validationClass} ${className}` : `input ${className}`}
      ref={refWrapper}
      id={`wrapper-${id}`}>
      <input id={id} type={props.type || "text"} {...props} onFocus={onFocus} />
    </div>
  );
};

export default Input;
