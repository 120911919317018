import { format, formatDistanceToNow } from "date-fns";
import { requestStore } from "../../../stores/modalManager";
import Header from "../header";
import { ArrowLeft } from "../icons";
import Request from "./request";

export default function ActivityLog() {
  const logs = requestStore.useState(s => s.request.logs);
  const sortedLogs = [...logs];
  sortedLogs.sort((a, b) => (new Date(a.date) > new Date(b.date) ? -1 : 1));

  const showRequest = () => {
    requestStore.update(s => {
      s.componentsToRender = <Request />;
    });
  };

  return (
    <div className="modalContent activityLogUM">
      <Header>
        <ArrowLeft onClick={showRequest} />
        <div className="title">Activity Log</div>
      </Header>
      <div className="content">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>User</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {sortedLogs.map((l, i) => {
              return (
                <tr key={i}>
                  <td>
                    {l.date ? formatDistanceToNow(new Date(l.date), { addSuffix: true }) : "N/A"}
                    <br />
                    <span className="caption">{l.date ? format(new Date(l.date), "dd/MM/yy HH:mm") : "N/A"}</span>
                  </td>
                  <td>
                    {l.by.name}
                    <br />
                    <span className="caption">{l.by.type}</span>
                  </td>
                  <td>
                    <div className="caption">{l.content}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
