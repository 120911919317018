import clsx from "clsx";
import { cloneElement } from "react";

export function RadioButton({ id, selected = false, onClick, children, position, disabled }) {
  const onClickItem = () => {
    if (disabled) return;

    onClick(id);
  };

  return (
    <div
      className={clsx("radioButtonUM", position, { selected, disabled })}
      onClick={onClickItem}
      data-testid="radioButtonUM">
      <div className={`icon ${selected ? "selected" : ""}`} />
      <div className="content">{children}</div>
    </div>
  );
}

export function RadioButtonContainer({ children, onChange, className = "", value, position = "right" }) {
  const onChildClick = selectedId => {
    if (selectedId !== value) onChange(selectedId);
  };

  return (
    <div data-testid="paymentMethodList" className={`radioButtonContainerUM ${className}`}>
      {children?.map((child, key) =>
        cloneElement(child, { key, onClick: onChildClick, selected: value === child.props.id, position })
      )}
    </div>
  );
}
