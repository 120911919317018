import React from "react";

export default function TagInfo({ icon, title, subtitle, className = "", action, ...props }) {
  return (
    <div className={`tagInfoUM ${className}`} {...props}>
      {icon && <div className="icon">{icon}</div>}
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
      <div className="action">{action}</div>
    </div>
  );
}
