import { useEffect } from "react";
export default function useOnClickOutside(ref, onClickOutside) {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    };
    onClickOutside && document.addEventListener("mousedown", handleClickOutside);
    return () => {
      onClickOutside && document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}
