import { useEffect } from "react";

export default function useWatchLocalStorage(key, callback) {
  useEffect(() => {
    const storageCallback = ev => {
      if (ev.key === key && ev.newValue) callback();
    };

    window.addEventListener("storage", storageCallback);

    return () => {
      window.removeEventListener("storage", storageCallback);
    };
  }, [key, callback]);
}
