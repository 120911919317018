import ReactAvatar from "react-avatar";
import { rosterColors } from "utils/globalConfig";

export default function Avatar({
  colorList = ["#a0d7ef", "#8edadf", "#82bbfe"],
  className = "",
  round = "50%",
  size = "24",
  src,
  color,
  maxInitials = 1,
  userData = {},
  ...props
}) {
  const statusType = userData?.timeBlock?.type || (userData?.availability ? "available" : "hide");

  const getStyle = status => {
    const style = {
      roster: { backgroundColor: rosterColors.timeblocks.roster.color },
      adhoc: { backgroundColor: rosterColors.timeblocks.adhoc.color },
      available: { backgroundColor: "#3ca9a6" },
    };

    return (
      style[status] || {
        display: "none",
      }
    );
  };

  return (
    <div className={`avatarUM ${className}`} {...props}>
      <ReactAvatar
        src={src}
        maxInitials={maxInitials}
        name={`${userData?.firstName} ${userData?.lastName}`}
        round={round}
        size={size}
        color={color || ReactAvatar.getRandomColor("sitebase", colorList)}
      />

      <span className="availabilityStatus" style={getStyle(statusType)} />
    </div>
  );
}
