import { AxiosPromise } from "axios";
import URI from "urijs";
import http from "./httpService";

const baseURL = process.env.REACT_APP_API_REST_ENDPOINT;

interface GetCommonSymptoms {
  alpha2: string;
  serviceType: string;
  serviceSpecialty: string;
}

interface GetSymptoms extends GetCommonSymptoms {
  search: string;
}

interface GetProcedures {
  alpha2: string;
  search: string;
}

interface GetMedications extends GetProcedures {}

interface GetCases {
  alpha2: string;
  patientRef: string;
}

interface ValidateCode {
  alpha2: string;
  serviceType: string;
  serviceSpecialty: string;
  patientRef: string;
}

interface GetRecommendedProviders {
  alpha2: string;
  type: string;
  specialty: string;
  scheduleStart: Date;
  scheduleEnd: Date;
}

export function getCommonSymptoms(data: GetCommonSymptoms): AxiosPromise {
  const uri = new URI(`${baseURL}/ubf/symptoms/common`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function getSymptoms(data: GetSymptoms): AxiosPromise {
  const uri = new URI(`${baseURL}/ubf/symptoms`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function getProcedures(data: GetProcedures): AxiosPromise {
  const uri = new URI(`${baseURL}/ubf/procedures`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function getMedications(data: GetMedications): AxiosPromise {
  const uri = new URI(`${baseURL}/ubf/procedure/items`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function getCases(data: GetCases): AxiosPromise {
  const uri = new URI(`${baseURL}/ubf/cases`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function validateCode(id: string, data: ValidateCode): AxiosPromise {
  const uri = new URI(`${baseURL}/ubf/code/${id}`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function getRecommendedProviders(data: GetRecommendedProviders): AxiosPromise {
  const uri = new URI(`${baseURL}/ubf/providers/recommended/count`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function getBusinesses(data: { alpha2: string }): AxiosPromise {
  const uri = new URI(`${baseURL}/ubf/businesses`);
  uri.setSearch(data);
  return http.get(uri.toString());
}
