import React, { useEffect, useRef, useState } from "react";
import Input from "./input";
import useOnClickOutside from "../../hooks/useClickOutside";

export default function Select({
  options = [],
  defaultValue,
  value = "",
  onChange,
  className,
  minLength = 3,
  disabled = false,
  info,
  ...props
}) {
  const [isOpened, setIsOpened] = useState(false);
  const isDisabled = !options.length || disabled;
  const [inputValue, setInputValue] = useState(options.find(i => i.id === defaultValue));

  const list = defaultValue === "" ? [""].concat(options) : options;
  const wrapperRef = useRef();
  useOnClickOutside(wrapperRef, () => {
    setIsOpened(false);
  });

  const renderList = () => {
    if (isOpened && !isDisabled) {
      return (
        <div className="list">
          {list.map((l, i) => {
            return (
              <div className="listItem" onClick={() => onSelectValue(i)} key={i}>
                <div className="content">
                  {l.title}
                  {l.subtitle ? <div className="subtitle">{l.subtitle}</div> : null}
                </div>
                {l.info ? (
                  <span className="info">
                    {l.info.icon} {l.info.text}
                  </span>
                ) : null}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const handleClick = () => {
    setIsOpened(!isOpened);
  };

  const onSelectValue = i => {
    setIsOpened(false);
    onChange(list[i], i);
  };

  useEffect(() => {
    setInputValue(options.find(i => i.id === defaultValue));
  }, [defaultValue, options]);

  return (
    <div className={`selectUM ${className}`} ref={wrapperRef}>
      <Input
        {...props}
        readOnly="readonly"
        value={inputValue && inputValue.title}
        select
        info={(inputValue && inputValue.info && inputValue.info.icon) || info}
        className={`sm ${value && value.length >= minLength && isOpened && list.length ? "open" : ""}`}
        onClick={handleClick}
        list={renderList()}
      />
    </div>
  );
}
