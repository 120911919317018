import AllInboxIcon from "@mui/icons-material/AllInbox";
import SyncIcon from "@mui/icons-material/Sync";
import { IconButton } from "@mui/material";
import Loader from "../../global/loader";
import Input from "../input";
import Tooltip from "../tooltip";
import { useSessionContext } from "contexts/sessionContext";
import { useCallback } from "react";

export default function EMRField({
  isLoading,
  isDisabled,
  isAttachedInvoice,
  value,
  onChangeInput,
  errorMsg,
  onClickButton = () => {},
}) {
  const { session } = useSessionContext();

  const userRoles = session?.user?.roles ?? [];
  const isSuperAdmin = userRoles.includes("superAdmin");
  const isManager = userRoles.includes("manager");

  const disabled = (!isManager && !isSuperAdmin) || (isAttachedInvoice && !isSuperAdmin) || isDisabled;

  const handleClickButton = useCallback(
    e => {
      if (disabled) return;

      onClickButton(e);
    },
    [disabled, onClickButton],
  );

  return (
    <div className="emr">
      <AllInboxIcon htmlColor="#979797" />
      <Input
        aria-label="EMR Invoice ID"
        placeholder="EMR Invoice ID"
        data-testid="inputInvoiceID"
        value={value}
        onChange={onChangeInput}
        info={<Tooltip id="tooltip" text="Required field" />}
        showInfo={errorMsg}
        disabled={disabled}
      />

      {isLoading && <Loader />}

      {!isLoading && (
        <IconButton
          role="button"
          aria-label="refresh invoice details"
          onClick={handleClickButton}
          color="secondary"
          size="small"
          disabled={disabled}>
          <SyncIcon />
        </IconButton>
      )}
    </div>
  );
}
