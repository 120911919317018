import { Chip, Grid, Typography } from "@mui/material";
import { CheckGreen } from "components/oldDesignAssets/icons";
import { Plus } from "components/universalModal/icons";

const chipColor = {
  option: { borderColor: "jarvisDivider.primary", backgroundColor: "#FAFAFA" },
  selected: { borderColor: "newEmerald.500", backgroundColor: "successGreen.50" },
  existing: { borderColor: "purple.500", backgroundColor: "purple.50" },
};

const getChipStyle = (state: string) => ({
  margin: "2px",
  borderRadius: "4px",
  borderWidth: "1px",
  textTransform: "capitalize",
  borderStyle: "solid",
  cursor: "pointer",
  ...chipColor[state],
});

export default function CustomChip({ label, isSelected = false, isExisting = false, onClick }) {
  let styleMode = isExisting ? "existing" : "option";
  if (isSelected) styleMode = "selected";

  return (
    <Chip
      label={
        <Grid container justifyContent="center" alignItems="center" gap={0.5}>
          <Typography fontSize={12}>{label}</Typography>

          {isSelected ? <CheckGreen height="12px" width="12px" /> : <Plus height="12px" width="12px" />}
        </Grid>
      }
      size="small"
      onClick={onClick}
      sx={getChipStyle(styleMode)}
      data-testid={`chip-${label}`}
    />
  );
}
