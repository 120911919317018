import clsx from "clsx";
import { useMemo, useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useClickOutside";
import Button from "./button";
import Checkbox from "./checkbox";
import { ChevronDown } from "./icons";

export default function SelectMultiple({
  options,
  optionsName,
  label,
  onChange = () => {},
  hasSelectAll,
  disabled = false,
}) {
  const wrapperRef = useRef();
  useOnClickOutside(wrapperRef, () => {
    setShowOptions(false);
  });
  const [showOptions, setShowOptions] = useState(false);

  const [selectedCount, isSelectedAll] = useMemo(() => {
    const selectedCount = options.filter(({ selected }) => selected).length;
    const isSelectedAll = options.length === selectedCount;
    return [selectedCount, isSelectedAll];
  }, [options]);

  const handleSelectAll = () => {
    const updatedOptions = options.map(o => ({ ...o, selected: !isSelectedAll }));
    onChange(updatedOptions);
  };

  const handleChange = value => () => {
    const updatedOptions = options.map(o => {
      const option = { ...o };
      if (value === option.value) option.selected = !option.selected;
      return option;
    });
    onChange(updatedOptions);
  };

  const handleClickShow = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className="selectMultiple" ref={wrapperRef}>
      <Button
        text={
          <>
            <span className="text">
              {isSelectedAll ? `All ${label}` : selectedCount > 0 ? `${selectedCount} ${label}` : `No ${label}`}
            </span>
            <div className="chevron">
              <ChevronDown />
            </div>
          </>
        }
        type="button"
        className={clsx("selectMultipleButton", { disabled })}
        onClick={handleClickShow}
        disabled={disabled}
      />
      {showOptions && (
        <ul className="selectMultipleOptions">
          {hasSelectAll && (
            <>
              <li key="all">
                <Checkbox
                  defaultChecked={isSelectedAll}
                  text={`All ${optionsName}`}
                  onChange={handleSelectAll}
                  id="all"
                  disabled={disabled}
                />
              </li>
              <hr />
            </>
          )}
          {options.map(option => (
            <li key={option.value}>
              <Checkbox
                defaultChecked={option.selected}
                text={option.label}
                onChange={handleChange(option.value)}
                id={option.value}
                disabled={disabled}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
