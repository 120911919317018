export default function WavPayIcon(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0 0 368.056 402.777"
      enableBackground="new 0 0 368.056 402.777"
      xmlSpace="preserve"
      {...props}>
      <g>
        <g>
          <g>
            <linearGradient
              id="SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1="244.3291"
              y1="294.3301"
              x2="237.2618"
              y2="121.1731">
              <stop offset="0" stopColor="#16285F" />
              <stop offset="1" stopColor="#0094D9" />
            </linearGradient>
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="url(#SVGID_1_)"
              points="213.201,89.721 342.227,328.567 
				253.711,328.567 140.688,119.336 			"
            />
            <linearGradient
              id="SVGID_2_"
              gradientUnits="userSpaceOnUse"
              x1="116.5488"
              y1="299.5449"
              x2="109.4816"
              y2="126.3902">
              <stop offset="0" stopColor="#16285F" />
              <stop offset="1" stopColor="#0094D9" />
            </linearGradient>
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="url(#SVGID_2_)"
              points="83.834,133.007 213.205,328.567 
				124.691,328.567 15.488,162.621 			"
            />
            <linearGradient
              id="SVGID_3_"
              gradientUnits="userSpaceOnUse"
              x1="169.6563"
              y1="303.7988"
              x2="168.331"
              y2="127.5503">
              <stop offset="0.226" stopColor="#16285F" />
              <stop offset="0.4991" stopColor="#0C5A98" />
              <stop offset="0.7532" stopColor="#0384C7" />
              <stop offset="0.8798" stopColor="#0094D9" />
            </linearGradient>
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="url(#SVGID_3_)"
              points="213.201,89.721 213.201,328.567 
				124.688,328.567 124.688,119.336 			"
            />
            <linearGradient
              id="SVGID_4_"
              gradientUnits="userSpaceOnUse"
              x1="302.5742"
              y1="291.9541"
              x2="295.507"
              y2="118.8007">
              <stop offset="0" stopColor="#929497" />
              <stop offset="0.0577" stopColor="#7C7D80" />
              <stop offset="0.1476" stopColor="#616062" />
              <stop offset="0.2476" stopColor="#4A484A" />
              <stop offset="0.36" stopColor="#393637" />
              <stop offset="0.4919" stopColor="#2C292A" />
              <stop offset="0.6612" stopColor="#252122" />
              <stop offset="1" stopColor="#231F20" />
            </linearGradient>
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="url(#SVGID_4_)"
              points="342.566,46.443 342.566,328.567 
				254.055,328.567 254.055,76.058 			"
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                fill="#1C76BC"
                d="M273.258,335.029l-4.428,15.655h-3.102l-1.834-7.937l-1.822,7.937h-3.082l-4.43-15.655h5.1l2.375,9.193
					l1.928-9.193h3.154l2.063,9.193l2.484-9.193H273.258z"
              />
              <path
                fill="#1C76BC"
                d="M287.898,350.125c-1.193,0.537-2.352,0.805-3.49,0.805c-1.676,0-2.709-0.603-3.107-1.813
					c-0.805,1.26-1.977,1.891-3.494,1.891c-2.848,0-4.271-1.529-4.271-4.595c0-2.33,1.309-3.964,3.904-4.91
					c2.391-0.867,3.588-1.914,3.588-3.136c0-1.603-0.686-2.404-2.059-2.404c-1.59,0-2.383,0.774-2.383,2.327
					c0,0.486,0.125,1.128,0.371,1.926l-2.416-0.545c-0.061-0.486-0.094-0.941-0.094-1.366c0-2.481,1.949-3.727,5.857-3.727
					c3.709,0,5.564,1.163,5.564,3.482v9.5c0,0.973,0.266,1.463,0.813,1.463c0.17,0,0.418-0.112,0.75-0.33L287.898,350.125z
					 M280.986,341.273c-0.301,0.401-0.656,0.739-1.055,1.008c-1.008,0.685-1.514,1.832-1.514,3.447c0,2.069,0.352,3.108,1.043,3.108
					c1.018,0,1.525-1.027,1.525-3.077V341.273z"
              />
              <path
                fill="#1C76BC"
                d="M299.748,335.029l-4.531,15.655h-3.398l-4.682-15.655h5.203l2.865,10.481l2.939-10.481H299.748z"
              />
              <path
                fill="#1C76BC"
                d="M300.75,355.976v-20.931c1.381,0,3.012-0.132,4.879-0.404v2.357c0.918-1.58,2.033-2.373,3.346-2.373
					c1.4,0,2.52,0.743,3.355,2.229c0.842,1.486,1.256,3.479,1.256,5.973c0,5.517-1.584,8.275-4.746,8.275
					c-1.299,0-2.371-0.568-3.211-1.704v6.578H300.75z M307.195,336.908c-1.08,0-1.615,1.945-1.615,5.84
					c0,4.047,0.516,6.073,1.547,6.073c0.93,0,1.389-2.026,1.389-6.073C308.516,338.854,308.08,336.908,307.195,336.908z"
              />
              <path
                fill="#1C76BC"
                d="M329.26,350.125c-1.195,0.537-2.357,0.805-3.486,0.805c-1.676,0-2.711-0.603-3.102-1.813
					c-0.814,1.26-1.98,1.891-3.506,1.891c-2.842,0-4.266-1.529-4.266-4.595c0-2.33,1.299-3.964,3.904-4.91
					c2.387-0.867,3.584-1.914,3.584-3.136c0-1.603-0.684-2.404-2.061-2.404c-1.584,0-2.379,0.774-2.379,2.327
					c0,0.486,0.129,1.128,0.379,1.926l-2.43-0.545c-0.061-0.486-0.09-0.941-0.09-1.366c0-2.481,1.953-3.727,5.857-3.727
					c3.707,0,5.559,1.163,5.559,3.482v9.5c0,0.973,0.281,1.463,0.82,1.463c0.174,0,0.42-0.112,0.748-0.33L329.26,350.125z
					 M322.346,341.273c-0.299,0.401-0.652,0.739-1.057,1.008c-1.004,0.685-1.508,1.832-1.508,3.447c0,2.069,0.352,3.108,1.045,3.108
					c1.012,0,1.52-1.027,1.52-3.077V341.273z"
              />
              <path
                fill="#1C76BC"
                d="M341.482,335.029l-6.178,17.675c-0.848,2.42-1.986,3.63-3.422,3.63c-1.391,0-2.336-0.688-2.836-2.063
					l1.688-1.416c0.133,1.19,0.508,1.786,1.139,1.786c0.697,0,1.271-0.688,1.707-2.065l0.361-1.117l-5.428-16.43h5.363l2.715,9.209
					l3.086-9.209H341.482z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
