import http from "./httpService";
import URI from "urijs";

const baseURL = process.env.REACT_APP_API_REST_ENDPOINT;

export function getRosterForADay(data) {
  const uri = new URI(`${baseURL}/time-blocks`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function createTimeBlock(data) {
  return http.post(`${baseURL}/time-block`, data);
}

export function updateTimeBlock(timeBlockId, data) {
  return http.put(`${baseURL}/time-block/${timeBlockId}`, data);
}

export function deleteTimeBlocks(data) {
  return http.delete(`${baseURL}/time-block`, { data });
}

export function reorderTimeBlock(data) {
  return http.put(`${baseURL}/time-blocks/reorder`, data);
}

export function duplicateTimeBlocks(data) {
  return http.post(`${baseURL}/time-blocks/duplicate`, data);
}

export function getProviders(data) {
  const uri = new URI(`${baseURL}/roster/providers`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function createRequest(data) {
  return http.post(`${baseURL}/roster/service`, data);
}
