import { Link } from "@mui/material";
import { format, formatDistanceToNow } from "date-fns";
import { createPasswordToken, sendAppLinkSMS } from "../../../services/userService";
import notify from "../../../utils/notify";
import Checkbox from "../checkbox";
import { Chain, SpeedocIcon } from "../icons";
import NotificationModal from "../notificationModal";
import { store } from "stores/storeManager";

export default function AppLinks({ defaultChecked, onClickCheckBox, user }) {
  const { alpha2 } = store.useState(s => ({
    alpha2: s.country.alpha2,
  }));

  const sendAppLinkHandler = async () => {
    try {
      const { data } = await sendAppLinkSMS(user._id, alpha2);
      notify(data.message);
    } catch (error) {
      notify(error.data, "error");
    }
  };
  const sendResetPasswordHandler = async () => {
    try {
      const { data } = await createPasswordToken(user._id);
      notify(data.message);
    } catch (error) {
      notify(error.data, "error");
    }
  };
  const onClickSendAppLinkSMS = () => {
    NotificationModal({
      callback: sendAppLinkHandler,
      data: user.phoneNumbers[0],
      action: "sendAppLinkSMS",
    });
  };
  const onClickSendResetPassword = () => {
    NotificationModal({
      callback: sendResetPasswordHandler,
      data: user.email.address,
      action: "sendResetPassword",
    });
  };

  const CopyLink = (
    <Link
      color="#126EF8"
      sx={{ cursor: "pointer" }}
      className="copyLink"
      underline="none"
      onClick={() => {
        navigator.clipboard.writeText("https://bit.ly/appsmslogin");
      }}>
      <Chain />
      Copy Link
    </Link>
  );

  return (
    <>
      <div className="verifiedUserInfo">
        <div className="info">
          <div className="icon">
            <SpeedocIcon />
          </div>
          <div className="text">
            {user?.isAppUser ? (
              <div className="title">Verified App User</div>
            ) : user?._id ? (
              <div className="title">Non App User</div>
            ) : (
              <div className="title">New User</div>
            )}
            {user?._id && (
              <div className="subtitle">
                Created: {format(new Date(user.createdAt), "dd MMM yyyy")} ·
                {user?.isAppUser
                  ? ` Active ${
                      user.lastActive ? formatDistanceToNow(new Date(user.lastActive), { addSuffix: true }) : "N/A"
                    }`
                  : " Not Registered"}
              </div>
            )}
          </div>
        </div>
        {!user?._id && (
          <>
            <div className="checkBoxInput">
              <Checkbox defaultChecked={defaultChecked} onChange={onClickCheckBox} text="Send Download App SMS" />
            </div>
          </>
        )}
      </div>
      {user?._id ? (
        <>
          <Link color="#126EF8" sx={{ cursor: "pointer" }} className="sendSMSLink" onClick={onClickSendAppLinkSMS}>
            Send Download App SMS
          </Link>
          {CopyLink}
          <Link
            color="#126EF8"
            sx={{ cursor: "pointer" }}
            className="sendResetPasswordLink"
            onClick={onClickSendResetPassword}>
            Send Reset Password Email
          </Link>
        </>
      ) : (
        CopyLink
      )}
    </>
  );
}
