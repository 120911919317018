import React, { useState } from "react";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import { store } from "../../stores/storeManager";
import { getFormattedAddress } from "../../utils/globalUtils";

const getAddressComponent = (addressComponents, componentName) => {
  const component = addressComponents.find(c => c.types.includes(componentName));
  if (!component) return "";
  return component.long_name || component.short_name;
};

const getAddress = addressComponents =>
  addressComponents
    .filter(c => !c.types.includes("postal_code") && !c.types.includes("country"))
    .map(c => c.long_name)
    .join(", ");

export default function GoogleMapAutocomplete({
  address = {},
  placeholder,
  required,
  disabled,
  className = "",
  showOneLine = false,
  onChange = () => {},
  ...props
}) {
  const { alpha2 } = store.useState(s => s.country);
  const [noResult, setNoResult] = useState(false);
  const onSelect = async suggestion => {
    try {
      const results = await geocodeByAddress(suggestion.description);
      if (!results || !results.length) throw new Error("Address not found");

      const addressToUpdate = {
        line1: suggestion.formattedSuggestion.mainText,
        line2: getAddress(results[0].address_components),
        postCode: getAddressComponent(results[0].address_components, "postal_code"),
        alpha2: alpha2,
        coordinates: {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        },
      };
      addressToUpdate.oneLine = getFormattedAddress(addressToUpdate);
      onChange(addressToUpdate);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const onErrorAutoComplete = (_status, clearSuggestions) => {
    setNoResult(true);
    clearSuggestions();
    onChange({ line1: "", line2: "", oneLine: "", unit: "", postCode: "" });
  };

  const onChangeAutoComplete = value => {
    setNoResult(false);
    onChange({ ...address, line1: !showOneLine ? value : "", line2: "", oneLine: showOneLine ? value : "", alpha2 });
  };

  const autoCompleteValue = showOneLine ? address.oneLine : address.line1 || "";

  return (
    <div className={`addressAutocompleteUM ${className}`} {...props}>
      <PlacesAutocomplete
        searchOptions={{ componentRestrictions: { country: alpha2 } }}
        value={noResult ? "No Result Found" : autoCompleteValue}
        onError={onErrorAutoComplete}
        onChange={onChangeAutoComplete}
        debounce={500}
        shouldFetchSuggestions={showOneLine ? address.oneLine?.length > 1 : address.line1?.length > 1}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <input
              {...getInputProps({
                placeholder,
                className: "googleMapAutocompleteInput",
                required: required,
                disabled: disabled,
              })}
            />
            <div className={`suggestionContainer ${loading || suggestions.length > 0 ? "opened" : ""}`}>
              {(loading || suggestions.length > 0) && (
                <div className="autocompleteResults">
                  {loading && <div className="suggestionItem loading">Loading...</div>}
                  {suggestions.map((suggestion, i) => {
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className: "suggestionItem",
                          onClick: () => onSelect(suggestion),
                        })}
                        key={i}>
                        <div className="mainText">{suggestion.formattedSuggestion.mainText}</div>
                        <div className="secondaryText">{suggestion.formattedSuggestion.secondaryText}</div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    </div>
  );
}
