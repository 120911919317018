import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationSG from "./locales/sg/translation.json";
import translationMY from "./locales/my/translation.json";
import { defaultCountry, availableCountries } from "./globalConfig";

const resources = {
  sg: {
    translation: translationSG
  },
  my: {
    translation: translationMY
  }
};

i18n
  .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.

  .init({
    resources,
    fallbackLng: defaultCountry, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    whitelist: availableCountries,
    returnObjects: true,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
