import { Store } from "pullstate";
import { getGeolocalizedProviders } from "../services/dispatcherService";

export const geoTrackingStore = new Store({
  providers: [],
  loading: false
});

export const setProviders = providers => {
  geoTrackingStore.update(s => {
    s.providers = providers;
  });
};

export const updateProviderCoordinates = updatedProvider => {
  geoTrackingStore.update(s => {
    if (!s.providers.length) return;
    const provider = s.providers.find(p => p._id === updatedProvider._id);
    if (!provider) return;
    provider.coordinates = updatedProvider.coordinates;
  });
};

const shouldAddProviderToMap = (provider, updatedProvider) => {
  return !provider && updatedProvider.isGeolocalized && updatedProvider.coordinates;
};

export const updateProviderIsGeolocalized = updatedProvider => {
  geoTrackingStore.update(s => {
    const provider = s.providers.find(p => p._id === updatedProvider._id);

    if (shouldAddProviderToMap(provider, updatedProvider)) {
      s.providers.push(updatedProvider);
      return;
    }

    provider.isGeolocalized = updatedProvider.isGeolocalized;
  });
};

export const setLoading = loading => {
  geoTrackingStore.update(s => {
    s.loading = loading;
  });
};

export const fetchGeolocalizedProviders = async alpha2 => {
  const query = {
    alpha2
  };
  setLoading(true);
  try {
    const {
      data: { providers }
    } = await getGeolocalizedProviders(query);

    setProviders(providers);
    setLoading(false);
  } catch (err) {
    console.error(err);
    setLoading(false);
  }
};
