import { format } from "date-fns";
import { Fragment } from "react";
import { isValidDate } from "utils/globalUtils";
import Tooltip from "./tooltip";

export default function UserVerificationID({ user }) {
  const passportExpiry = item => {
    if (item.type !== "passport") return;
    const expiryDate = isValidDate(item.expiry) ? format(new Date(item.expiry), "d/MM/yyyy") : "N/A";
    return `Passport Expiry: ${expiryDate}<br/>`;
  };

  const userVerificationDetails =
    user.verifications?.length >= 1 ? (
      <>
        {user.verifications.map(item => (
          <Fragment key={item.id}>
            ID Type: {item.type.toUpperCase()}
            <br />
            ID No: {item.id}
            <br />
            {passportExpiry(item)}
          </Fragment>
        ))}
      </>
    ) : (
      <>
        No ID Records.
        <br />
        You may need to ask
        <br />
        the user to upload ID
      </>
    );
  return <Tooltip dataPlace="bottom" text={userVerificationDetails} icon={user.name} id={user._id} />;
}
