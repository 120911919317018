import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Grid, IconButton, Typography } from "@mui/material";
import { ReactNode } from "react";

interface ConfirmationDialogProps {
  title?: string;
  width?: string;
  confirmText?: string;
  cancelText?: string;
  onClose?: () => void;
  onCancel: () => void;
  onConfirm?: () => void | Promise<void>;
  loading: boolean;
  showCancelButton?: boolean;
  children?: any;
  childComponent?: ReactNode;
}

export default function ConfirmationDialog({
  title,
  width = "401px",
  confirmText = "Confirm",
  cancelText = "Cancel",
  onClose,
  onCancel,
  onConfirm,
  loading,
  showCancelButton = false,
  children,
  childComponent,
}: ConfirmationDialogProps) {
  return (
    <Dialog open>
      <Box px={3} py={2} fontFamily="Mulish,'Open Sans',Roboto,sans-serif" width={width}>
        <Grid container justifyContent="space-between" wrap="nowrap">
          <Typography variant="body1" whiteSpace="break-spaces" fontWeight={700}>
            {title}
          </Typography>

          <IconButton onClick={onClose ?? onCancel} edge="end" sx={{ mt: -1, width: "40px", height: "40px" }}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid mt={2}>
          {childComponent || (
            <Typography variant="body2" color="text.secondary">
              {children}
            </Typography>
          )}
        </Grid>

        <Grid container justifyContent="space-between" direction="row-reverse" mt={3}>
          <LoadingButton
            variant="contained"
            color="componentNewEmerald50"
            onClick={onConfirm}
            loading={loading}
            disableElevation>
            {confirmText}
          </LoadingButton>

          {showCancelButton && (
            <Button color="error" onClick={onCancel}>
              {cancelText}
            </Button>
          )}
        </Grid>
      </Box>
    </Dialog>
  );
}
