import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  assignedToAccepted,
  inProgressToInTransit,
  inTransitToAssigned,
  visitEndToVisitStart,
} from "../../services/requestService";
import { requestStore } from "../../stores/modalManager";
import { store } from "../../stores/storeManager";
import Cancellation from "./pages/cancellation";
import LoadingSection from "../../components/loadingSection";
import NotificationModal from "../universalModal/notificationModal";
import notify from "../../utils/notify";
import Request from "../universalModal/pages/request";
import useOnClickOutside from "../../hooks/useClickOutside";

const statusSequence = [
  { title: "draft" },
  { title: "accepted", action: assignedToAccepted },
  { title: "assigned", action: inTransitToAssigned },
  { title: "inTransit", action: inProgressToInTransit },
  { title: "inProgress", action: visitEndToVisitStart },
  { title: "visitEnd" },
  { title: "completed" },
  { title: "cancelled" },
];

export default function StatusTag({ children, statusName = "", className = "", isDropDown = false, ...props }) {
  const [showDropDown, setShowDropDown] = useState(false);
  const request = requestStore.useState(s => s.request);
  const showSubscreen = subscreen => {
    requestStore.update(s => {
      s.componentsToRender = subscreen;
    });
  };
  const wrapperRef = useRef();
  useOnClickOutside(wrapperRef, () => {
    setShowDropDown(false);
  });
  const { language } = store.useState(s => ({
    language: s.language,
  }));
  const { t = () => "" } = useTranslation();
  const serviceStatus = t(`${language}.serviceStatus`);

  const isClinic = request.type === "Clinic";
  let dropDownStatus = [];
  if (["completed", "cancelled", "discharged"].includes(statusName)) isDropDown = false;
  else {
    const currentStatusIndex = statusSequence.findIndex(status => status.title === statusName);
    if (["assigned", "inTransit", "inProgress", "visitEnd"].includes(statusName)) {
      const previousStep = isClinic && statusName === "inProgress" ? 2 : 1;
      dropDownStatus = [
        statusSequence[currentStatusIndex - previousStep],
        statusSequence[currentStatusIndex],
        statusSequence[statusSequence.length - 1],
      ];
    } else {
      dropDownStatus = [statusSequence[currentStatusIndex], statusSequence[statusSequence.length - 1]];
    }
  }

  const handleStatusDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const handleStatusClick = status => {
    if (status.title === "cancelled") {
      showSubscreen(<Cancellation />);
    } else {
      if (!status.action) return;

      NotificationModal({
        callback: () => onClickConfirm(status.action),
        action: status.title,
      });
    }
  };

  const onClickConfirm = async action => {
    try {
      requestStore.update(s => {
        s.componentsToRender = <LoadingSection className="modalContent">Loading request data</LoadingSection>;
      });
      const result = await action(request._id, request.logistic);
      notify(result.data?.message);
      requestStore.update(s => {
        s.request = result.data.service;
        s.unsavedChanges = {
          billing: { ...s.request.billing },
          schedule: { ...s.request.schedule },
          providers: [...s.request.providers],
          isDirty: {},
        };
      });
    } catch (error) {
      notify(error.data, "error");
    } finally {
      requestStore.update(s => {
        s.componentsToRender = <Request />;
      });
    }
  };

  return (
    <div className="statusContainerUM" ref={wrapperRef}>
      <div
        className={`statusTagUM ${className} ${isDropDown && "dropDown"} ${showDropDown && "open"}`}
        {...props}
        onClick={isDropDown ? handleStatusDropDown : undefined}>
        {children} {isDropDown && <span className="caret" />}
      </div>
      {isDropDown && (
        <ul className={`dropdownStatusTagUM ${showDropDown && "show"}`}>
          {dropDownStatus &&
            dropDownStatus.map(
              (status, index) =>
                status && (
                  <li key={index} className={`${status.title === statusName && "active"}`}>
                    <div
                      className={`statusTagUM ${status.title}`}
                      onClick={status.title !== statusName ? () => handleStatusClick(status) : undefined}>
                      {serviceStatus[status.title]}
                    </div>
                  </li>
                )
            )}
        </ul>
      )}
    </div>
  );
}
