import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  ThemeOptions,
  Toolbar,
  Typography,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { MouseEventHandler, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getInitials } from "utils/globalUtils";
import app from "../../package.json";
import { useSessionContext } from "../contexts/sessionContext";
import useWatchLocalStorage from "../hooks/useWatchLocalStorage";
import { store } from "../stores/storeManager";
import { resetNewTaskCount, taskStore } from "../stores/taskStore";
import { role } from "../utils/globalConfig";
import CountrySelector from "./global/countrySelector";
import SpeedocLogo from "./icons/speedocLogo";

const extendedTheme = (theme: ThemeOptions) =>
  createTheme({
    ...theme,
    typography: {
      fontFamily: "Mulish, sans-serif",
      button: { fontWeight: 700, textTransform: "capitalize" },
    },
  });

const wsStateColor = {
  active: "newEmerald.500",
  event: "newEmerald.700",
  inactive: "error.main",
};

export default function Navbar() {
  useWatchLocalStorage("resetTaskCount", resetNewTaskCount);
  const location = useLocation();
  const path = location.pathname.split("/")?.[1] || "dispatcher";

  const { lastMessage, ready } = store.useState(s => s.websocket);
  const newTaskCount = taskStore.useState(s => s.newTaskCount);

  const { session, logout } = useSessionContext();
  const [wsEvent, setWsEvent] = useState("");
  const [tabValue, setTabValue] = useState(path);

  const wsActive = wsEvent ? "event" : "active";
  const wsState = !ready ? "inactive" : wsActive;
  const userInitials = getInitials(session.user.name, 2);

  useEffect(() => {
    if (location.pathname === "/task") resetNewTaskCount(true);

    setTabValue(path);
  }, [location.pathname, path]);

  useEffect(() => {
    if (lastMessage) {
      setWsEvent(lastMessage);
      setTimeout(() => setWsEvent(""), 1000);
    }
  }, [lastMessage]);

  const handleLogout: MouseEventHandler<HTMLButtonElement> = async e => {
    e.preventDefault();
    try {
      await logout();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={extendedTheme}>
      <AppBar position="sticky" color="inherit" sx={{ fontFamily: "Mulish" }} elevation={1}>
        <Toolbar variant="dense">
          <SpeedocLogo />

          <Typography color="text.secondary" fontSize={12} fontWeight={500} ml={1.5}>{`v${app.version}`}</Typography>

          <Grid
            borderRadius="50%"
            height={8}
            width={8}
            ml={1}
            sx={{
              position: "relative",
              backgroundColor: wsStateColor[wsState],
              color: wsStateColor[wsState],
            }}>
            <Box
              sx={{
                height: "100%",
                border: "1px solid currentColor",
                borderRadius: "50%",
                animation: "ripple 1.2s infinite ease-in-out",

                "@keyframes ripple": {
                  "0%": {
                    transform: "scale(.8)",
                    opacity: 1,
                  },
                  "100%": {
                    transform: "scale(2.4)",
                    opacity: 0,
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs container>
            <Tabs
              value={tabValue}
              textColor="secondary"
              indicatorColor="secondary"
              data-testid="navbar"
              sx={{
                ".MuiTab-root": { ml: 4, px: 0, minWidth: "unset", fontWeight: 400 },
                ".Mui-selected": { fontWeight: 700 },
              }}>
              <Tab
                label="Dispatcher"
                value="dispatcher"
                to="/dispatcher"
                component={Link}
                data-testid="dispatcherButtonAdmin"
              />

              <Tab
                label="Care Coordination"
                value="care-coordination"
                to="/care-coordination/case-management"
                component={Link}
                data-testid="navBarCareCoordinationButton"
              />

              <Tab
                label={
                  <Badge
                    badgeContent={newTaskCount}
                    max={99}
                    variant="standard"
                    color="error"
                    sx={{ ".MuiBadge-badge": { right: "-6px" } }}>
                    Task
                  </Badge>
                }
                value="task"
                to="/task"
                component={Link}
                style={{ paddingRight: newTaskCount ? 16 : 0 }}
                data-testid="taskButtonAdmin"
              />

              <Tab label="Patient" value="patient" to="/patient" component={Link} data-testid="patientButtonAdmin" />

              <Tab label="Roster" value="e-roster" to="/e-roster" component={Link} data-testid="rosterButtonAdmin" />

              {session.user.roles.includes(role.superAdmin) && [
                <Tab
                  key="/admin"
                  label="Admin"
                  value="admin"
                  to="/admin/prices"
                  component={Link}
                  data-testid="adminButtonAdmin"
                />,
                <Tab
                  key="/corporate"
                  label="Corporate"
                  value="corporate"
                  to="/corporate"
                  component={Link}
                  data-testid="corporateButtonAdmin"
                />,
              ]}
            </Tabs>
          </Grid>

          <Grid item xs="auto" container alignItems="center">
            <CountrySelector />

            <Avatar
              sx={{
                ml: 2,
                width: 32,
                height: 32,
                backgroundColor: "#DDDDDD",
                color: "text.primary",
                fontSize: 18,
                fontWeight: 600,
              }}>
              {userInitials}
            </Avatar>

            <IconButton role="logoutButton" onClick={handleLogout} size="small" color="icon" sx={{ ml: 2 }}>
              <ExitToAppIcon />
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
