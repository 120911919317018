import React from "react";

export default function Toggle({ onChange, disabled = false, text, className = "", isOn = false, ...props }) {
  return (
    <div className={`toggleUM ${className} ${disabled ? "disabled" : ""}`} {...props}>
      {text}
      <div
        className={`switch ${isOn ? "on" : "off"} ${disabled ? "disabled" : ""}`}
        onClick={() => {
          if (!disabled) onChange(!isOn);
        }}>
        <div
          className={`toggle-button ${isOn ? "position-right" : "position-left"} ${disabled ? "disabled" : ""}`} data-testid={`${text}ToggleButton`}/>
      </div>
    </div>
  );
}
