import { useState } from "react";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import notify from "../../utils/notify";
import Button from "components/oldDesignAssets/button";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE).catch(console.log);

const AddCard = ({ onCardAdded, buttonText = "Add Card" }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardComplete, setCardComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      // Make sure to disable form submission until Stripe.js has loaded.
      if (!stripe || !elements || !cardComplete) return;
      setIsLoading(true);
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      if (result.error) return notify(result.error?.message ?? "Something went wrong", "error");
      await onCardAdded(result.paymentMethod);
    } catch (e) {
      notify(e.data, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="stripeCardElement">
        <CardElement onChange={e => setCardComplete(e.complete)} hidePostalCode />
      </div>
      <div className="submitButton">
        <Button className="primary sm" type="submit" disabled={isLoading || !cardComplete}>
          {isLoading ? "Please wait..." : buttonText}
        </Button>
      </div>
    </form>
  );
};

export default function CreateStripeCard({ onCardAdded, buttonText }) {
  return (
    <div className="createStripeCard">
      <Elements stripe={stripePromise}>
        <AddCard onCardAdded={onCardAdded} buttonText={buttonText} />
      </Elements>
    </div>
  );
}
