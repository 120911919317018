import { useEffect, useRef, useState } from "react";
import { requestStore } from "../../../stores/modalManager";
import CreateRequest from "./createRequest";
import Header from "../header";
import { ArrowLeft } from "../icons";
import { getPlaces } from "../../../services/placeService";
import { store } from "../../../stores/storeManager";
import Input from "../input";
import { InView } from "react-intersection-observer";
import Loader from "../../global/loader";
import notify from "../../../utils/notify";
import Alert from "../alert";
import { Location } from "components/oldDesignAssets/icons";

export default function DestinationList() {
  const { address, items } = requestStore.useState(s => {
    return {
      address: s.unsavedChanges.schedule.destinationAddress,
      items: s.unsavedChanges.items,
    };
  });
  const alpha2 = store.useState(s => s.country.alpha2);
  const [places, setPlaces] = useState([]);
  const destinationItem = items.find(item => item.item?.tags.includes("destination"))?.item;
  const [filter, setFilter] = useState({
    search: address.line1,
    alpha2,
    type: destinationItem?.data?.placeType,
    perPage: 100,
  });
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const setAddress = address => {
    requestStore.update(s => {
      s.unsavedChanges.schedule.destinationAddress = address;
      s.unsavedChanges.isDirty.schedule = true;
    });
  };

  const showCreateRequest = () => {
    requestStore.update(s => {
      s.componentsToRender = <CreateRequest />;
    });
  };

  const timeout = useRef();

  useEffect(() => {
    const fetch = async () => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(async () => {
        try {
          setIsLoading(true);
          const { data } = await getPlaces(filter);
          setPlaces(data.places);
          setPagination(data.pagination);
        } catch (error) {
          notify(error.data, "error");
        } finally {
          setIsLoading(false);
        }
      }, 500);
    };
    fetch();
  }, [filter]);

  const onClickPlaceItem = place => {
    const line1 = place.name;
    const line2 = place.address.oneLine ?? "";
    const oneLine = `${line1} ${line2}`;
    const coordinates = place.address.coordinates;
    setAddress({ line1, line2, alpha2, oneLine, coordinates });
    showCreateRequest();
  };

  const onLoaderInView = async isInView => {
    if (isInView) {
      try {
        const { data } = await getPlaces({ ...filter, page: pagination.page + 1 });
        setPlaces([...places, ...data.places]);
        setPagination(data.pagination);
      } catch (error) {
        notify(error.data, "error");
      }
    }
  };

  const renderSuggestedPlaces = () => {
    if (isLoading) return <Loader />;
    if (!isLoading && places.length === 0) return "No places found";
    return places.map((p, i) => {
      return (
        <div className="placeItem" onClick={() => onClickPlaceItem(p)} key={i}>
          <div className="placeTitle">
            ({p.shortName}) {p.name}
          </div>
          <div className="placeOneLine">{p.address.oneLine}</div>
        </div>
      );
    });
  };

  const checkpointDisclamer = () => {
    if (destinationItem?.data?.placeType === "checkpoint") {
      return (
        <Alert
          className="warning"
          text={
            <>
              <span>Disclaimer:</span> The booking will be confirmed upon approval with Government regulatory body.
            </>
          }
        />
      );
    }
  };

  return (
    <div className="modalContent destinationListUM">
      <Header>
        <ArrowLeft onClick={showCreateRequest} />
        <div className="title">Destination List</div>
      </Header>
      <div className="content">
        <Input
          className="search"
          value={filter.search}
          onChange={search => setFilter({ ...filter, search, page: 1 })}
          icon={<Location />}
          placeholder="Search Destination List"
          data-testid="searchDestination"
        />
        <hr />
        {checkpointDisclamer()}
        <div className={`placeList ${isLoading ? "disableScroll" : ""}`}>
          {renderSuggestedPlaces()}
          {pagination.page < pagination.pages && !isLoading ? (
            <InView as="div" onChange={onLoaderInView}>
              <Loader />
            </InView>
          ) : null}
        </div>
      </div>
    </div>
  );
}
