import { Store } from "pullstate";
import { getTask, getTasks } from "../services/taskService";
import { sort } from "../utils/globalUtils";
import { taskDialogStore } from "./taskDialogStore";

const sortMapping = {
  dueDate: "schedule.start",
  patientName: "user.name"
};

export const taskStore = new Store({
  tasks: [],
  newTaskCount: 0,
  loading: false,
  hasNext: false,
  sortModel: []
});

const setTasks = tasks => {
  taskStore.update(s => {
    s.tasks = tasks;
  });
};

const setTask = task => {
  taskDialogStore.update(s => {
    s.task = task;
  });
};

const setHasNext = hasNext => {
  taskStore.update(s => {
    s.hasNext = hasNext;
  });
};

const setLoading = loading => {
  taskStore.update(s => {
    s.loading = loading;
  });
};

export const setSortModel = model => {
  taskStore.update(s => {
    s.sortModel = model;
  });
};

export const fetchTasks = async ({ alpha2, keywords, perPage, page, sortModel }) => {
  try {
    setLoading(true);

    let sortBy;
    let sortOrder;
    if (sortModel.length) {
      sortBy = sortMapping[sortModel[0].field] || sortModel[0].field;
      sortOrder = sortModel[0].sort === "asc" ? 1 : -1;
    }

    const result = await getTasks({ alpha2, keywords, perPage, page, sortBy, sortOrder });
    setTasks(result.data.tasks);
    setHasNext(result.data.nextPageAvailable);
    setLoading(false);
  } catch (err) {
    console.error(err);
    setLoading(false);
  }
};

export const fetchTask = async taskId => {
  try {
    setLoading(true);

    const result = await getTask(taskId);
    setTask(result.data.task);
  } catch (error) {
    throw error;
  } finally {
    setLoading(false);
  }
};

export const updateTask = data => {
  const { task, operation } = data;

  taskStore.update(s => {
    let currTasks = [].concat(s.tasks);
    const currTaskIndex = currTasks.findIndex(t => t._id === task._id);

    if (!currTasks[currTaskIndex]) {
      currTasks.push(task);

      let sortBy = "schedule.start";
      let sortOrder = 1;

      if (s.sortModel.length) {
        sortBy = sortMapping[s.sortModel[0].field] || s.sortModel[0].field;
        sortOrder = s.sortModel[0].sort === "asc" ? 1 : -1;
      }

      currTasks = sort(currTasks, sortBy, sortOrder);
    } else {
      currTasks[currTaskIndex] = { ...currTasks[currTaskIndex], ...task };
    }

    s.tasks = currTasks;

    if (operation === "insert" && window.location.pathname !== "/task") s.newTaskCount++;
  });
};

export const resetNewTaskCount = (forAllTabs = false) => {
  taskStore.update(s => {
    s.newTaskCount = 0;
  });

  if (forAllTabs) {
    localStorage.setItem("resetTaskCount", true);
    localStorage.removeItem("resetTaskCount");
  }
};
