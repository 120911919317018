import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import { Receipt } from "components/oldDesignAssets/icons";
import { Building, FreeDollar } from "components/universalModal/icons";
import {
  addDays,
  addHours,
  addMinutes,
  differenceInMinutes,
  format,
  formatDuration,
  intervalToDuration,
  isBefore,
  isEqual,
  isSameMinute,
  isValid,
  parse,
  parseJSON,
  roundToNearestMinutes,
  set,
  startOfDay,
  subMinutes,
} from "date-fns";
import { minimumTaskInterval, paymentMethodIcons, serviceScheduleOffset } from "./globalConfig";

export function debounce(func, delay) {
  let d, e;
  return function () {
    function h() {
      d = null;
      return (e = func.apply(f, g));
    }
    let f = this,
      g = arguments;
    clearTimeout(d);
    d = setTimeout(h, delay);
    if (!d) {
      e = func.apply(f, g);
    }
    return e;
  };
}

export function getFormattedAddress(address = {}) {
  const addressComponent = [];
  if (address.line1) addressComponent.push(address.line1);
  if (address.line2) addressComponent.push(address.line2);
  if (address.unit) addressComponent.push(`Unit ${address.unit}`);
  if (address.city) addressComponent.push(address.city);
  if (address.postCode) addressComponent.push(address.postCode);
  return addressComponent.join(", ") || address.oneLine;
}

export function getFormattedSchedule(schedule) {
  const scheduleEndText = () => (schedule.end ? ` - ${format(new Date(schedule.end), "HH:mm")}` : "");

  return schedule?.start ? `${format(new Date(schedule.start), "PP HH:mm")}` + scheduleEndText() : "Asap";
}

export function isEmailValid(email) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}

export function isValidDate(date) {
  return isValid(parseJSON(date && new Date(date)));
}

export function formattedAttachmentName(name, maxLength = 12) {
  if (name.length > 12) {
    const extension = name.match(/\.[^.]*$/);
    const ellipsis = "... ";
    const max = maxLength - ellipsis.length - extension.length;
    return name.substring(0, Math.max(max, 5)) + ellipsis + extension;
  } else return name;
}

export function formattedAttachmentSize(size) {
  if (size >= 1000000) return (size / 1000000).toFixed(1) + " mb";
  else return (size / 1000).toFixed(1) + " kb";
}

export const truncateString = ({ string, limit }) => {
  return string.length > limit ? `${string.substring(0, limit)} ...` : string;
};

export const containsAll = (array, values) => values.every(i => array.includes(i));

export const durationToPixel = ({ duration, width }) => (width / 24) * duration;

export const getDurationAsHour = ({ start, end }) => differenceInMinutes(end, start) / 60;

export const getServiceDuration = service => {
  const offset = serviceScheduleOffset[service.type];
  const scheduleStart = service.schedule.start;
  const scheduleEnd = service.schedule.end;

  const start = subMinutes(new Date(scheduleStart), offset.startOffset);
  const duration = new Date(scheduleEnd);
  const end = addMinutes(duration, offset.endOffset);

  return {
    scheduled: new Date(scheduleStart),
    start,
    duration,
    end,
  };
};

const setOverlappingSchedules = (service, nextService) => {
  const currStack = service.overlap;
  const isSameGroup = isSameMinute(service.serviceDuration.start, nextService.serviceDuration.start);

  if (isSameGroup) {
    nextService.overlap = currStack;
    return;
  }

  const diff = differenceInMinutes(service.serviceDuration.end, nextService.serviceDuration.start);
  const isOverlap = diff > 15;
  const nextStack = currStack + 1;
  const shouldStack = isOverlap && nextStack < 4;

  if (shouldStack) {
    nextService.overlap = nextStack;
    return;
  }

  nextService.overlap = 0;
};

const groupSameSchedules = services => {
  const groupByScheduleStart = services.reduce((group, service) => {
    const scheduleStart = format(service.serviceDuration.start, "dd:HH:mm");
    group[scheduleStart] = group[scheduleStart] || [];
    group[scheduleStart].push(service);
    group[scheduleStart] = sort(group[scheduleStart], "serviceDuration.end", -1);
    group[scheduleStart] = group[scheduleStart].map((s, i) => ({ ...s, overlapHover: i }));
    return group;
  }, {});

  return Object.values(groupByScheduleStart);
};

export const flagOverlappingSchedules = services => {
  const sortedServices = sort(
    services.map(s => ({ ...s, overlap: 0, overlapHover: 0, serviceDuration: getServiceDuration(s) })),
    "serviceDuration.start",
    1
  );

  sortedServices.forEach((service, i) => {
    if (sortedServices[i + 1]) setOverlappingSchedules(service, sortedServices[i + 1]);
  });

  return groupSameSchedules(sortedServices);
};

export const getDurationText = ({ start, end }) => {
  const duration = formatDuration(intervalToDuration({ start: new Date(start), end: new Date(end) }));
  const compactDuration = duration.replace("hour", "hr").replace("minute", "min");

  return compactDuration;
};

export const handleTimeChangeRaw = event => {
  const value = event.target.value;
  const restricted = /[a-zA-Z`~,.<>;'"/\\[\]|{}()=_!@#$%^&*+ -]/;
  event.target.value = value.replace(new RegExp(restricted, "gi"), "");
};

export const checkIfMidnightThenGoToNextDay = date => (isEqual(date, startOfDay(date)) ? addDays(date, 1) : date);

export const sort = (arr, sortBy, sortOrder = 1) => {
  sortBy = sortBy.split(".");
  const len = sortBy.length;

  return arr.sort((a, b) => {
    let i = 0;
    while (i < len) {
      a = a[sortBy[i]];
      b = b[sortBy[i]];
      i++;
    }
    if (a < b) {
      return -sortOrder;
    } else if (a > b) {
      return sortOrder;
    } else {
      return 0;
    }
  });
};

export const getPointer = event => {
  const rect = event.target.getBoundingClientRect();
  return event.clientX - rect.left;
};

export const calculateStartTime = ({ pointer, width, startTime, endTime }) => {
  const duration = getDurationAsHour({ start: startTime, end: endTime });
  const addedDuration = (pointer / width) * duration;

  const newStartTime = addHours(startTime, isNaN(addedDuration) ? 1 : addedDuration);

  return roundToNearestMinutes(newStartTime, { nearestTo: minimumTaskInterval });
};

export const getInterval = type => {
  return type === "Video" ? 10 : 5;
};

export const getDefaultEndTime = (specialty, type, start) => {
  if (!start) return null;

  const defaultEndTimeAddition = {
    Visit: specialty === "Nurse" ? 15 : 30,
    Delivery: specialty === "Rider" ? 240 : 30,
    Clinic: 15,
    Video: 10,
  };

  return addMinutes(new Date(start), defaultEndTimeAddition[type]);
};

export const capitalize = str =>
  str
    .toLowerCase()
    .split(" ")
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

export const sortPrices = (a, b) => {
  if (!a.from || !b.from) return 1;
  return isBefore(parse(a.from, "HH:mm", new Date()), parse(b.from, "HH:mm", new Date())) ? -1 : 1;
};

export const generateHours = (minutes = 0) => {
  return [...Array(24).keys()].map(v => format(set(new Date(), { hours: v, minutes }), "HH:mm"));
};

export const deepCloneObject = obj => JSON.parse(JSON.stringify(obj));

export const isCardExpired = card =>
  new Date() >= parse(`${card?.data?.cardExpMonth}-${card?.data?.cardExpYear}`, "MM-yyyy", new Date());

export const getPaymentIcon = (paymentMethod, request) => {
  if (request) {
    if (request.billing.free) return <FreeDollar />;
    if (request.billing.selectedPaymentMethod?.origin === "business") return <Building />;
  }

  if (paymentMethod?.type === "card" && isCardExpired(paymentMethod)) return <CreditCardRoundedIcon color="error" />;
  return paymentMethodIcons[paymentMethod?.type || paymentMethod?.origin] || <Receipt />;
};

export const getInitials = (fullName, maxInitials) => {
  const initials = fullName
    .split(" ")
    .map(n => n[0])
    .join("")
    .toUpperCase();

  return maxInitials ? initials.slice(0, maxInitials) : initials;
};

export const isPositiveNumber = input => /^\d+$/.test(input);

export const roundDateTime = ({ request, onChange }) => {
  const tryRoundToNearestMinutes = type => {
    return (
      isValidDate(request.schedule[type]) &&
      roundToNearestMinutes(new Date(request.schedule[type]), { nearestTo: getInterval(request.type) })
    );
  };

  onChange({
    start: tryRoundToNearestMinutes("start"),
    end: tryRoundToNearestMinutes("end"),
  });
};

export const getPostalCode = address => {
  if (address?.POSTAL) return address.POSTAL && address.POSTAL !== "NIL" ? address.POSTAL : "";
  const component = address?.address_components?.find(c => c.types.includes("postal_code"));
  if (!component) return "";
  return component.long_name || component.short_name;
};
