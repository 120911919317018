import { useEffect, useRef, useState } from "react";
import { InView } from "react-intersection-observer";
import escape from "regex-escape";
import Loader from "../../components/global/loader";
import useOnClickOutside from "../../hooks/useClickOutside";
import notify from "../../utils/notify";
import ProtectedImage from "../global/protectedImage";
import Input from "./input";
import Tooltip from "./tooltip";

const defaultAvatar = "//public.speedoc.com/strapi/company_b6429bebea.svg";

const requiredField = <Tooltip text="Required Field" />;

export default function AutoComplete({
  list = [],
  defaultValue = "",
  className,
  minLength = 3,
  onClick,
  subscreenText,
  debounce = 400,
  fetch,
  openOnClick,
  onSelect,
  onDelete,
  showLoaderInView = false,
  onLoaderInView,
  info = requiredField,
  showInfo,
  autoFocus,
  disabled,
  onDisabledClick,
  pattern = /[\s\S]+/g,
  ...props
}) {
  const [isOpened, setIsOpened] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);
  const wrapperRef = useRef();

  useOnClickOutside(wrapperRef, () => {
    setIsOpened(false);
    if (value) setValue(defaultValue);
    else if (onDelete) onDelete();
  });

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const timeout = useRef();

  const debounceFetch = lastValue => {
    if (fetch && lastValue?.length >= minLength) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(async () => {
        try {
          setIsLoading(true);
          await fetch(lastValue);
        } catch (error) {
          notify(error.data, "error");
        } finally {
          setIsLoading(false);
        }
      }, debounce);
    }
  };

  const suggestions =
    fetch || value === defaultValue
      ? list
      : list.filter(l => new RegExp(escape(`${value}`), "gi").test(`${l.title} ${l.subtitle || ""}`));

  const renderSuggestionList = () => {
    return suggestions.length > 0 ? (
      <>
        {suggestions.map((s, i) => (
          <div
            className="listItem"
            onClick={() => onSelectValue(list.findIndex(l => l === s))}
            key={i}
            data-testid="userList"
            role="listitem"
            aria-label={s.title}>
            {s.avatar && (
              <ProtectedImage className="avatar" src={s.avatar} alt="Business avatar" defaultImage={defaultAvatar} />
            )}
            <div className="content">
              {s.title}
              {s.subtitle && <div className="subtitle">{s.subtitle}</div>}
            </div>
            {s.icon}
            {s.info && (
              <span className="itemInfo">
                {s.info.icon} {s.info.text}
              </span>
            )}
          </div>
        ))}
        {showLoaderInView && (
          <InView as="div" onChange={() => onLoaderInView(value)}>
            <Loader />
          </InView>
        )}
      </>
    ) : (
      <div className="listItem">
        <div className="content">No result found...</div>
      </div>
    );
  };

  const renderSuggestions = () => {
    if (!disabled && (openOnClick || (value && value.length >= minLength)) && isOpened) {
      return (
        <div className="list">
          {subscreenText && (
            <div className="listItem subscreenLink" onClick={onClick}>
              {subscreenText}
            </div>
          )}
          {isLoading ? (
            <div className="listItem">
              <div className="content">Loading...</div>
            </div>
          ) : (
            renderSuggestionList()
          )}
        </div>
      );
    }
  };

  const onChangeValue = value => {
    setIsOpened(true);
    if (value === "" || pattern.test(value)) {
      setValue(value);
      debounceFetch(value);
    }
  };

  const onSelectValue = i => {
    setIsOpened(false);
    onSelect(i);
    setValue(defaultValue);
  };

  const handleOnClick = () => {
    if (openOnClick) setIsOpened(true);
  };

  return (
    <div
      className={`autoCompleteUM ${className}`}
      ref={wrapperRef}
      onClick={() => disabled && onDisabledClick && onDisabledClick()}>
      <Input
        {...props}
        disabled={disabled}
        autoFocus={autoFocus}
        value={(value && value.title) || value}
        info={value && value.info && value.info.icon}
        onClick={handleOnClick}
        className={`sm ${value && value.length >= minLength && isOpened && list.length ? "open" : ""}`}
        onChange={onChangeValue}
        list={renderSuggestions()}
      />
      {showInfo && info ? <span className="info">{info}</span> : null}
    </div>
  );
}
