import URI from "urijs";
import http from "./httpService";

const baseUrl = `${process.env.REACT_APP_API_REST_ENDPOINT}/ubf-old`;

export function getCasesBusinesses(data) {
  const uri = new URI(`${baseUrl}/cases/businesses`);
  uri.setSearch(data);
  return http.get(uri.toString());
}

export function getPackages(data) {
  const uri = new URI(`${baseUrl}/packages`);
  uri.setSearch(data);
  return http.get(uri.toString());
}
