import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import { Grid, TextField } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { getServiceItems } from "../../services/dispatcherService";
import { requestStore } from "../../stores/modalManager";
import { store } from "../../stores/storeManager";
import { getDefaultEndTime, isValidDate, roundDateTime } from "../../utils/globalUtils";
import notify from "../../utils/notify";
import { Clock } from "./icons";
import Tooltip from "./tooltip";

export default function Schedule({ icon = <Clock />, onChange, showInfo, disabled = false }) {
  const country = store.useState(s => s.country);
  const { request, serviceItems } = requestStore.useState(s => ({
    request: { ...s.request, ...s.unsavedChanges },
    serviceItems: s.serviceItems,
  }));

  const [isLoading, setIsLoading] = useState(false);

  const style = { minHeight: "unset", height: "30px" };

  const businessRef = request.business?._id;

  useEffect(() => {
    if (disabled) return;

    const fetchServiceItems = async () => {
      try {
        setIsLoading(true);

        const defaultSpecialty = request.type === "Delivery" ? "Ambulance" : "General Practitioner";
        const { data } = await getServiceItems({
          country: country.alpha2,
          businessRef,
          type: request.type,
          specialty: request.specialty || defaultSpecialty,
          "tags[]": request.isIncludeVideo ? ["video", "general practitioner"] : undefined,
        });

        requestStore.update(s => {
          s.serviceItems = [data.item];
        });
      } catch (error) {
        notify(error.data, "error");
      } finally {
        setIsLoading(false);
      }
    };
    fetchServiceItems();
  }, [country.alpha2, businessRef, request.specialty, request.type, disabled, request.isIncludeVideo]);

  const onChangeDateTimePicker = type => event => {
    const newSchedule = {
      [type]: isValidDate(event.target.value) && new Date(event.target.value),
    };

    if (type === "start") newSchedule.end = getDefaultEndTime(request.specialty, request.type, newSchedule.start);

    onChange(newSchedule);
  };

  const restrictPastTime =
    !(request.type === "Delivery" && request.specialty === "Rider") &&
    !["visitEnd", "completed"].includes(request.status);

  if (!serviceItems) return null;

  if (isLoading) return "Loading schedule...";

  return (
    <Grid container className="scheduleUM" alignItems="center" gap="4px">
      <span className="icon">{icon}</span>

      <Grid item xs>
        <TextField
          type="datetime-local"
          value={
            isValidDate(request.schedule.start) ? format(new Date(request.schedule.start), "yyyy-MM-dd'T'HH:mm") : ""
          }
          onChange={onChangeDateTimePicker("start")}
          inputProps={{
            min: restrictPastTime ? format(new Date(), "yyyy-MM-dd'T'HH:mm") : undefined,
            onBlur: () => roundDateTime({ request, onChange }),
            sx: { padding: "0px 10px" },
          }}
          size="small"
          className="scheduleDateTimePickerTextField"
          fullWidth
          disabled={disabled}
          error={
            restrictPastTime
              ? isValidDate(request.schedule.start) && new Date(request.schedule.start) < new Date()
              : undefined
          }
          InputProps={{ sx: style }}
          data-testid="selectStartDateTime"
        />
      </Grid>

      <ArrowRightAltRoundedIcon htmlColor="#979797" />

      <Grid item xs>
        <TextField
          type="datetime-local"
          value={isValidDate(request.schedule.end) ? format(new Date(request.schedule.end), "yyyy-MM-dd'T'HH:mm") : ""}
          onChange={onChangeDateTimePicker("end")}
          inputProps={{
            min: isValidDate(request.schedule.start)
              ? format(new Date(request.schedule.start), "yyyy-MM-dd'T'HH:mm")
              : undefined,
            onBlur: () => roundDateTime({ request, onChange }),
            sx: { padding: "0px 10px" },
          }}
          size="small"
          className="scheduleDateTimePickerTextField"
          fullWidth
          disabled={disabled || !request.schedule.start}
          error={isValidDate(request.schedule.end) && new Date(request.schedule.end) < new Date(request.schedule.start)}
          InputProps={{ sx: style }}
          data-testid="selectEndTimeDateTime"
        />
      </Grid>

      {showInfo && <Tooltip text="Required Field" />}
    </Grid>
  );
}
